import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import {
  loadingToggleAction,
  signupAction,
} from '../../store/actions/AuthActions';
// image
import { Auth } from 'aws-amplify';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';


export function ChangeFirtAccessPassword(result) {
  mapStateToProps(result)
  return {
    type: "COGNITO_USER",
    payload: result,
  };

  /*
  Auth.completeNewPassword(
    result,               // the Cognito User Object
    'Stella_123',       // the new password
    // OPTIONAL, the required attributes
    {


    }
  ).then(result => {
    // at this time the user is logged in if no MFA required
    console.log(result);
  }).catch(e => {
    console.log(e);
  });

  */

}


function Register(props) {
  const [email, setEmail] = useState('');
  let errorsObj = { email: '', password: '', confermaPassword: '', matchPassword: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');
  const [confermaPassword, setConfermaPassword] = useState('');

  const dispatch = useDispatch();

  async function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (password === '') {
      errorObj.password = 'Il campo Password è obbligatorio';
      error = true;
      swal('Oops', errorObj.password, "error");
    }
    if (confermaPassword === '') {
      errorObj.confermaPassword = 'Il campo Conferma Password è obbligatorio';
      error = true;
      swal('Oops', errorObj.confermaPassword, "error");
    }
    if (password !== '' && confermaPassword !== '') {
      if (password !== confermaPassword) {
        errorObj.matchPassword = 'Le password non coincidono! Riprova.';
        error = true;
        swal('Oops', errorObj.matchPassword, "error");
      }
    }

    setErrors(errorObj);
    if (error) return;
    dispatch(loadingToggleAction(true));

    const cognitoObject = JSON.parse(localStorage.getItem('temp'))
    
    console.log("cognitoObject",cognitoObject)
    const pool = new CognitoUserPool({
      UserPoolId: cognitoObject.pool.userPoolId,
      ClientId: cognitoObject.pool.clientId,
      endpoint: cognitoObject.client.endpoint,
      Storage: window.localStorage,
      AdvancedSecurityDataCollectionFlag: cognitoObject.advancedSecurityDataCollectionFlag,
    })
    const cognitoUser = new CognitoUser({
      Username: cognitoObject.username,
      Pool: pool,
      Storage: window.localStorage,
    })

    cognitoUser.Session = cognitoObject.Session

    await Auth.completeNewPassword(cognitoUser, password, cognitoObject.challengeParams).then(result => {
      // at this time the user is logged in if no MFA required
      console.log(result);
      if(result.challengeName === undefined) {
        swal('Operazione eseguita con successo!',"Password modificata correttamente", "success");
        props.history.push('/login')
      }
    }).catch(e => {
      console.log(e);
    });

  }
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (
                      <div className=''>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className=''>
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          defaultValue={password}
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }
                          className="form-control"
                          placeholder="password"
                        //defaultValue="Password"
                        />
                      </div>
                      {errors.password && <div>{errors.password}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          defaultValue={confermaPassword}
                          onChange={(e) =>
                            setConfermaPassword(e.target.value)
                          }
                          className="form-control"
                          placeholder="password"
                        //defaultValue="Password"
                        />
                      </div>
                      {errors.confermaPassword && <div>{errors.confermaPassword}</div>}
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);

