import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

// QUA STA IL LOGO NELLA NAVBAR
import SvgComponent from "./SvgComponent";
const NavHader = () => {
	const [toggle, setToggle] = useState(false);
	const { navigationHader, openMenuToggle, background } = useContext(
		ThemeContext
	);
	return (
		<div className="nav-header">
			<Link className="brand-logo">
				{background.value === "dark" || navigationHader !== "color_1" ? (
					<Fragment>
						<SvgComponent />
					</Fragment>
				) : (
					<Fragment>
						<SvgComponent />
					</Fragment>
				)}
			</Link>

			<div
				id="myNavControl"
				className="nav-control"
				onClick={() => {
					setToggle(!toggle);
					openMenuToggle();
					//toggleHandle()
				}}
			>
				<div id="myHamburgerControlNavbar" className={`hamburger ${toggle ? "is-active" : ""}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	);
};

export default NavHader;
