import React from "react";
import { Map, GoogleApiWrapper, Geocoder, Marker } from 'google-maps-react';
import { Component } from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import Input from "antd/lib/input/Input";
import ApartmentMap from "../../ApartmentMap";


const StepFive = (props) => {
    return (
        <section>
           <ApartmentMap coordinates={props.coordinates} setCoordinates={props.setCoordinates}></ApartmentMap>
        </section>
     );
  };
  
  export default StepFive;

