import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';

import Amplify from 'aws-amplify'
import awsconfig from '../../config'
import { Auth } from 'aws-amplify';
import { lazy } from 'react';
import env from '../../envConfig';
import { ChangeFirtAccessPassword } from '../../jsx/pages/Registration';

import { ProvidedRequiredArgumentsRule } from 'graphql';
import Swal from "sweetalert2";
const SignUp = lazy(() => import('../../jsx/pages/Registration'));
//Qui si prende le configurazioni mie di AWS
Amplify.configure({
    aws_project_region: env().aws_project_region,
    aws_cognito_identity_pool_id: env().aws_cognito_identity_pool_id,
    aws_cognito_region: env().aws_cognito_region,
    aws_user_pools_id: env().aws_user_pools_id,
    aws_user_pools_web_client_id: env().aws_user_pools_web_client_id,
    oauth: {},
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
})

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';


export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(history) {

    localStorage.removeItem('userDetails');
    localStorage.removeItem('scadenza');

    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };

}

export function MyLogout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}



export function loginAction(email, password, history) {

    return (dispatch) => {
        Auth.signIn(email, password).then((result) => {
            //Success 

            if (result.challengeName == "NEW_PASSWORD_REQUIRED") {
                console.log("email prima", email)
                //dispatch(ChangeFirtAccessPassword("NEW_PASSWORD_REQUIRED"))
                // 
                //dispatch(loginFailedAction('ahahahah'))
                localStorage.setItem('temp',JSON.stringify(result))
                history.push('/page-register')
                //onsole.log("ciao")

                /*
                const { requiredAttributes } = result.challengeParam;
                Auth.completeNewPassword(
                    result,               // the Cognito User Object
                    "Ciaocacca",       // the new password
                    // OPTIONAL, the required attributes
                    {
                    
                     
                    }
                ).then(result => {
                    // at this time the user is logged in if no MFA required
                    console.log(result);
                }).catch(e => {
                  console.log(e);
                });
                */
                //adminRespondToAuthChallenge(result.pool.UserPoolId,result.pool.clientId,result.username,result.Session)
            } else {
                console.log("sono in else di login")
                let expireDate = result.signInUserSession.idToken.payload.exp;
                let nowDate = new Date().getTime() / 1000;
                //console.log("expireDate", expireDate)
                //console.log("nowDate", nowDate)

                let myTimeout = expireDate - nowDate;
                //let myTimeout = 10; //timeout di test di 10 secondi

                //console.log("myTimeout", myTimeout)
                let scadenza = myTimeout + nowDate

                localStorage.setItem('scadenza', Date.now() + myTimeout * 1000)
                console.log("SCADENZA --> ", new Date(scadenza * 1000))

                saveTokenInLocalStorage(result.signInUserSession);
                runLogoutTimer(
                    dispatch,
                    myTimeout * 1000, //qui ci va la variabile myTimeout: scade dopo circa 1 ora
                    history,
                );
                dispatch(loginConfirmedAction(result.signInUserSession));
                history.push('/foto-home');
            }


        }).catch((error) => {
            console.log(error);
            const errorMessage = formatError(error.message);
            dispatch(loginFailedAction(errorMessage));
        })
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
        payload: status,
    };
}
