import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function formatError(errorResponse) {
    swal("Oops", errorResponse, "error", { button: "Riprova con le credenziali corrette!", });
    return errorResponse;
}

export function saveTokenInLocalStorage(tokenDetails) {
    /*
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    */
    console.log("sto settando userDetails")
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

//al momento del login, viene richiamata questa funzione
export function runLogoutTimer(dispatch, timer, history) {
    console.log("timer", timer)
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {

    console.log("**** refresh:  checkAutoLogin *****")

    console.log("history", history)


    const tokenDetailsString = localStorage.getItem('userDetails');

    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    console.log("risultato del parse", tokenDetails)
    let expireDate = tokenDetails.idToken.payload.exp;
    let nowDate = new Date().getTime() / 1000;
    //console.log("expireDate", expireDate)
    //console.log("nowDate", nowDate)

    let timer = expireDate - nowDate;
    //let myTimeout = 10 * 1000; //timeout di test
    /*
    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    */
    if (nowDate > expireDate) {
        //se sto qui, fammi uscire subito (CASO 1)
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    //const timer = expireDate.getTime() - todaysDate.getTime();

    //CASO 2: aggiorna il nuovo timer
    runLogoutTimer(dispatch, timer * 1000, history);

    if (history.location.pathname === "/login") {
        history.push("/foto-home")
    }

}
