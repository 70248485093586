import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import {
	loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';

// image
//import logo from "../../images/logo-full.png";
//import loginbg from "../../images/pic1.png";

import LogoComponent from './Logo';
import env from '../../envConfig';
function Login(props) {

	const [email, setEmail] = useState(process.env.REACT_APP_ENV != 'production' ? 'mperna.96@gmail.com' : '');
	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState(process.env.REACT_APP_ENV != 'production' ? 'Manuelino' : '');

	console.log("passoword", password)

	const dispatch = useDispatch();

	function onLogin(e) {
		console.log("password 2 ", password)
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Il campo E-Mail è obbligatorio!';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Il campo Password è obbligatorio!';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(email, password, props.history));
	}

	return (
		<>

			<div class='box' style={{ zIndex: -1 }}>
				<div class='wave -one'></div>
				<div class='wave -two'></div>
				<div class='wave -three'></div>
			</div>

			<div className="authincation h-100" style={{ background: 'rgb(48 174 231 / 29%)' }}>

				<div className="container h-100">
					<div className="row justify-content-center h-100 align-items-center">
						<div className="col-md-6">
							<div className="authincation-content">
								<div className="row no-gutters">
									<div className="col-xl-12">
										<div className="auth-form">
											<div className="text-center mb-3">
												<Link to={"#"}>
													<LogoComponent />
												</Link>

											</div>

											<h4 className="text-center mb-4">Login al gestionale</h4>
											{props.errorMessage && (
												<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
													{props.errorMessage}
												</div>
											)}
											{props.successMessage && (
												<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
													{props.successMessage}
												</div>
											)}
											<form onSubmit={onLogin}>
												<div className="mb-3">
													<label className="mb-1"><strong>Email</strong></label>
													<input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}
														placeholder="Inserisci la tua E-Mail"
													/>
													{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
												</div>
												<div className="mb-3">
													<label className="mb-1"><strong>Password</strong></label>
													<input type="password" className="form-control" value={password}
														placeholder="Inserisci la password"
														onChange={(e) =>
															setPassword(e.target.value)
														}
													/>
													{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
												</div>
												<div className="form-row d-flex justify-content-between mt-4 mb-2">
													<div className="mb-3">
														<div className="form-check custom-checkbox ms-0">
															<input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
															<label className="form-check-label" htmlFor="basic_checkbox_1">Ricorda le mie credenziali</label>
														</div>
													</div>
												</div>
												<div className="text-center">
													<button type="submit" className="btn btn-primary btn-block">Accedi</button>
												</div>
											</form>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);
