import React, { useEffect } from "react";
import Countdown from 'react-countdown';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { Button } from "react-bootstrap";
const HeaderCountdown = (props) => {

	useEffect(() => {

	}, []);


	// QUI CI STA IL LOGOUT

	function onLogout() {
		console.log("CLICCATO LOGOUT")
		localStorage.removeItem('userDetails');
		localStorage.removeItem('scadenza');
		window.location.reload(false)
		// window.location.reload();
	}


	function avvia() {
		let prenotazione = []
		let giornoDiDifferenza = 86400000
		for (let i = 0; i < 300; i++) {
			prenotazione.push({
				id: uuidv4(),
				title: 'Manuel Perna',
				nome: 'Manuel',
				cognome: 'Perna',
				mail: 'ciccio',
				bgColor: 'red',
				start: 1672614000000 + (Number(i) * giornoDiDifferenza),
				end: 1672700399000 + (Number(i) * giornoDiDifferenza),
				checkin: '16:00',
				checkout: '10:00',
				//resourceId: 1671663574929, //Prova per Ale appartamento
				resourceId: 1671718774518, //Una terrazza sulla spiggia
				numeroAdulti: '10',
				numeroBambini: '2',
				telefono: '33',
				acconto: '1',
				pagamento: 'ciao',
				noteBestHouse: 'ciao',
				noteCliente: 'ciao',
				stato: 'NON_APPROVATA',
				servizi: {
					"Aria Condizionata": false,
					"Forno": false,
					"Frigorifero1": false,
					"Cucina": false
				},
				prezzo: '10',
				movable: false,
				resizable: false,
				showPopover: true,
				timestamp: moment(new Date()).utc().unix() * 1000
			})
		}
		sessionStorage.setItem('test', JSON.stringify(prenotazione))
	}

	// Random component
	const Completionist = () => <span>ADDIOS!</span>;

	// Renderer callback with condition
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {
			// Render a countdown
			return (
				<div classname="row">
					<Button
						className="me-2"
						variant="outline-warning btn-rounded btn-sm"
						onClick={onLogout}
					>
						LOGOUT
					</Button>
				</div>);
		}
	};

	var path = window.location.pathname.split("/");
	var name = path[path.length - 1].split("-");
	var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
	var finalName = filterName.includes("app")
		? filterName.filter((f) => f !== "app")
		: filterName.includes("ui")
			? filterName.filter((f) => f !== "ui")
			: filterName.includes("uc")
				? filterName.filter((f) => f !== "uc")
				: filterName.includes("basic")
					? filterName.filter((f) => f !== "basic")
					: filterName.includes("jquery")
						? filterName.filter((f) => f !== "jquery")
						: filterName.includes("table")
							? filterName.filter((f) => f !== "table")
							: filterName.includes("page")
								? filterName.filter((f) => f !== "page")
								: filterName.includes("email")
									? filterName.filter((f) => f !== "email")
									: filterName.includes("ecom")
										? filterName.filter((f) => f !== "ecom")
										: filterName.includes("chart")
											? filterName.filter((f) => f !== "chart")
											: filterName.includes("editor")
												? filterName.filter((f) => f !== "editor")
												: filterName;
	return (
		<div className="header">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div
								className="header-title"
								style={{ textTransform: "capitalize" }}
							>
								<h1 className="font-w600 mb-0">
									{finalName.join(" ").length === 0
										? "Dashboard"
										: finalName.join(" ") === "dashboard dark"
											? "Dashboard"
											: finalName.join(" ")}
								</h1>
							</div>
						</div>

						<ul className="navbar-nav header-right" style={{ height: 'auto' }}>
							<Countdown
								date={Number(localStorage.getItem('scadenza'))}
								renderer={renderer}
							/>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default HeaderCountdown;
