import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "./../../layouts/PageTitle";
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { callLambda, insertFotoDynamo } from '../../../API/api'
import swal from "sweetalert";

import InsertFoto from "./components/InsertFoto";

import UpdateFoto from "./components/UpdateFoto";

import VisualizzaFoto from "./components/VisualizzaFoto";
import Loader from "../../components/Loader";

import 'antd/dist/antd.min.css'

import '../../../css/spinner-manu.css'

import config from '../../../config';
import apiConfig from '../../../API/apiConfig'
import { setRef } from "@fullcalendar/react";
import env from '../../../envConfig'

import { Spin } from 'antd';
const FotoHome = () => {
    const [goSteps, setGoSteps] = useState(0);
    const [photo, setPhoto] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {

        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.getFoto,
            method: "GET",
            queryParams: "banner"
        }
        callLambda(paramsAxios, res => {
    
            if (res.data.esito === "OK") {
                let photo = []
                if (res.data.risultato.length !== 0) {
                    photo = res.data.risultato.colonnaBanner
                }
                buildFileList(photo)
                setPhoto(photo)
                setLoading(false)
            } else {

            }

        });
    }, []);

    const buildFileList = (photo) => {
        let fileList = []
        for (let i = 0; i < photo.length; i++) {
            fileList.push({
                uid: photo[i].id,
                name: photo[i].id,
                foto: photo[i].foto
            })
        }
        //console.log(fileList)
        setFileList(fileList)
    }


    const insertFotoInDynamo = (vettoreLinkFoto) => {
        let paramsFoto = []

        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.getFoto,
            method: "GET",
            queryParams: "banner"
        }

        callLambda(paramsAxios, res => {
            if (res.data.esito === "OK") {

                //buildFileList(photo)
                //setPhoto(photo)

                res = res.data.risultato

                for (let link of vettoreLinkFoto) {
                    //console.log("link", link)
                    if (res.colonnaBanner.length === 0) {
                        paramsFoto.push({
                            foto: link,
                            id: link.split(config.separatoreFoto)[1],
                            tag: '',
                            title: ''
                        })
                    } else {
                        for (let foto of res.colonnaBanner) {
                            //console.log("foto", foto)
                            if (link === foto.foto) {
                                paramsFoto.push({
                                    foto: link,
                                    id: link.split(config.separatoreFoto)[1],
                                    tag: foto.tag,
                                    title: foto.title
                                })
                            }
                        }
                    }
                }
                //console.log("prima del for", paramsFoto)

                for (let i = paramsFoto.length; i < vettoreLinkFoto.length; i++) {

                    paramsFoto.push({
                        foto: vettoreLinkFoto[i],
                        id: vettoreLinkFoto[i].split(config.separatoreFoto)[1],
                        tag: '',
                        title: ''
                    })
                }

                //console.log("paramsFoto DOPO FOR", paramsFoto)

                let params = {
                    componente: "banner",
                    colonna: {
                        nomeColonna: "colonnaBanner",
                        valore: paramsFoto
                    }
                }

                paramsAxios = {
                    endpoint: env().API_ENDPOINT_URL + apiConfig.insertFoto,
                    method: "POST",
                    body: params
                }

                callLambda(paramsAxios, res => {
                    if (res.data.esito === "OK") {
                        swal("Operazione eseguita con successo!", res.data.descrizione, "success")
                        //console.log("dopo lo swal")
                        setRefresh(false)
                        let paramsAxios = {
                            endpoint: env().API_ENDPOINT_URL + apiConfig.getFoto,
                            method: "GET",
                            queryParams: "banner"
                        }
                        callLambda(paramsAxios, res => {
                            if (res.data.esito === "OK") {
                                let photo = []
                                if (res.data.risultato.length !== 0) {
                                    photo = res.data.risultato.colonnaBanner
                                }
                                buildFileList(photo)
                                setPhoto(photo)
                                setRefresh(true)
                            }

                        });

                    } else {
                        swal("Attenzione!", "Qualcosa è andato storto durante il caricamento", "error")
                    }
                });

            }
        });
    }

    const setPhotoFromChild = (val) => {
        setLoading(false)
        let insertFotoInDynamoDB = true;
        if (insertFotoInDynamoDB) {
            insertFotoInDynamo(val)
        } else {
            //TESTARE QUESTO
        }
    }

    return (
        <Fragment>
            <PageTitle
                activeMenu="Inserisci qui le foto per la sezione Home"
                motherMenu="Foto"
                pageContent="Inserisci qui le foto per la sezione Home"
            />
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <Card>
                        <Card.Header>
                            <Card.Title>Gestione delle foto per la sezione 'Home'</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {loading ?
                                <Loader />
                                :
                                <>

                                    {photo.length == 0 ?

                                        <div>
                                            <h2>

                                                Non sono presenti attualmente delle foto

                                            </h2>

                                            <InsertFoto setPhoto={setPhotoFromChild} />

                                        </div>

                                        :

                                        <div>

                                            <h2>
                                                Foto attualmente presenti in HOME
                                            </h2>
                                            {refresh === true ?
                                                <div>
                                                    <UpdateFoto
                                                        fileList={fileList}
                                                        setPhoto={setPhotoFromChild}

                                                    />
                                                    <Card.Body>

                                                        < VisualizzaFoto
                                                            photo={photo}
                                                            editModal={true}
                                                        />

                                                    </Card.Body>
                                                </div>
                                                :
                                                <></>
                                            }

                                        </div>

                                    }


                                </>
                            }

                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default FotoHome;
