import { set } from "date-fns";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const FacilitiesList = ({ titleName }) => {
	return (
		<>
			<div>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="#78D69D" />
				</svg>
				{titleName}
			</div>
		</>
	)
}

function ListaServiziPagamento(props) {

	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);
	const [services, setServices] = useState([]);

	useEffect(() => {
		const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];


		let righe = []
		let colonne = []
		let serviziCountTrue = 0
		let count = 0

		console.log((data.length / 3))
		console.log("servizi", props.servizi)
		console.log("Object.entries(props.servizi)", Object.entries(props.servizi))
		const serviziPagamento = Object.entries(props.servizi)

		for (let i = 0; i < Math.ceil(serviziPagamento.length / 3); i++) {
			if (count === serviziPagamento.length) break
			righe.push(
				<div className="row">
					{colonne}
				</div>
			)
			for (let j = 0; j < 3; j++) {
				if (count === serviziPagamento.length) break
				console.log("serviziPagamento[count]", serviziPagamento[count])
				if (serviziPagamento[count][1] === true) {
					serviziCountTrue += 1
					colonne.push(
						<div className="col-sm-4 col-6">
							<ul>
								<li>
									<FacilitiesList titleName={serviziPagamento[count][0]} />
								</li>
							</ul>
						</div>
					)
				}

				count += 1
			}
			colonne = []
			console.log("colonne", colonne)
		}
		if (serviziCountTrue === 0) {
			righe = <div>Attualmente, non sono presenti servizi a pagamento per la prenotazione corrente</div>
		}
		setRows(righe)
		console.log("rows", righe)
	}, []);

	return (
		<div>
			{rows}
		</div>
	)
}
export default ListaServiziPagamento;