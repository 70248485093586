const config = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:5320d9e4-bd71-4232-ac2c-357405279671',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_f5eozFEcE',
  aws_user_pools_web_client_id: '61rsvh7dmjefjd92ftnfa3ntpm',
  oauth: {},
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  apiUrl: 'https://llfhka3bhb.execute-api.eu-central-1.amazonaws.com/latest',
  bucketName: 'besthouse-produzione',
  dirFotoBanner: 'home/banner/',
  separatoreFoto: 'maffle❤-'
}

export default config;