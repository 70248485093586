import { format } from 'date-fns';
import moment from 'moment';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [
	{
		Header: 'Appartamento',
		Footer: 'appartamento',
		accessor: 'appartamento',
		Filter: ColumnFilter,
		Cell: ({ value }) => { return <div style={{ width: "max-content" }}> {value} </div> },

		//disableFilters: true,
	},
	{
		Header: 'Cliente',
		Footer: 'Cliente',
		accessor: 'title',
		Cell: ({ value }) => { return <div style={{ width: "max-content" }}> {value} </div> },
		Filter: ColumnFilter,
	},
	{
		Header: 'Email',
		Footer: 'Email Id',
		accessor: 'mail',
		Filter: ColumnFilter,
	},
	{
		Header: 'Check-in',
		Footer: 'Date of Birth',
		accessor: 'start',
		Cell: ({ value }) => { return moment(value).format('DD-MM-YYYY') },
		Filter: ColumnFilter,
	},
	{
		Header: 'Check-out',
		accessor: 'end',
		Cell: ({ value }) => { return moment(value).format('DD-MM-YYYY') },
		Filter: ColumnFilter,
	},
	{
		Header: 'Check-in',
		Footer: 'Date of Birth',
		accessor: 'arrivo',
		Cell: ({ value }) => { return <div style={{ width: "max-content" }}> {value} </div> },

		Filter: ColumnFilter,
	},
	{
		Header: 'Check-out',
		accessor: 'partenza',
		Cell: ({ value }) => { return <div style={{ width: "max-content" }}> {value} </div> },
		Filter: ColumnFilter,
	},
	{
		Header: 'Telefono',
		Footer: 'Telefono',
		accessor: 'telefono',
		Filter: ColumnFilter,
	},
	{
		Header: 'stato',
		Footer: 'stato',
		accessor: 'stato',
		Filter: ColumnFilter,
	}, {
		accessor: 'statoFiltro',
		Filter: ColumnFilter
	},
	{
		Header: 'Prezzo',
		Footer: 'prezzo',
		accessor: 'prezzo',
		Filter: ColumnFilter,
	},
]