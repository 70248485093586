const sviluppo = {
  API_ENDPOINT_URL: 'http://localhost:8080',
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:5320d9e4-bd71-4232-ac2c-357405279671',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_f5eozFEcE',
  aws_user_pools_web_client_id: '61rsvh7dmjefjd92ftnfa3ntpm',
  oauth: {},
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  bucketName: 'besthouse',
  dirFotoBanner: 'home/banner/',
  separatoreFoto: 'maffle❤-'
};

const collaudo = {
  API_ENDPOINT_URL: 'https://llfhka3bhb.execute-api.eu-central-1.amazonaws.com/latest',
  API_ENDPOINT_URL_FRONTEND: 'https://llfhka3bhb.execute-api.eu-central-1.amazonaws.com/latest',
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:5320d9e4-bd71-4232-ac2c-357405279671',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_f5eozFEcE',
  aws_user_pools_web_client_id: '61rsvh7dmjefjd92ftnfa3ntpm',
  oauth: {},
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  bucketName: 'besthouse',
  dirFotoBanner: 'home/banner/',
  separatoreFoto: 'maffle❤-'
};

const produzione = {
  API_ENDPOINT_URL: 'https://a9ax2td8dh.execute-api.eu-central-1.amazonaws.com/prod',
  API_ENDPOINT_URL_FRONTEND: 'https://llfhka3bhb.execute-api.eu-central-1.amazonaws.com/latest',
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:53518eb9-239a-47dd-97f3-8f51a444712f',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_bQsycxFyq',
  aws_user_pools_web_client_id: '7v1up9rk793f682h5821kms3um',
  oauth: {},
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  bucketName: 'besthouse-produzione',
  dirFotoBanner: 'home/banner/',
  separatoreFoto: 'maffle❤-'
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return sviluppo
    case 'staging':
      return collaudo
    case 'production':
      return produzione
  }
};

export default getEnv;