import React from 'react';
import PageTitle from '../PageTitle';
import VisualizzaPrenotazioniTable from './lista-table-prenotazioni/FilteringTable/VisualizzaPrenotazioniTable';

export const VisualizzaPrenotazione = () => {
	return (
		<>
			<PageTitle activeMenu="Visualizza le tue prenotazioni" motherMenu="Prenotazioni" />
			<VisualizzaPrenotazioniTable />
		</>
	)

}
export default VisualizzaPrenotazione;