import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Row, Card, Col, Button, Nav } from "react-bootstrap";
import FotoAppartamento from "../inserimento-appartamento/FotoAppartamento";

const StepFiveModifica = (props) => {
const [refresh,setRefresh]= useState(true)



let appoggio = props.vettorefoto
let appoggio2 = props.setVettoreFoto




   
   return (

   


      <Col xl="6">
               <Card>
                <Card.Body>
                
                    <section>

      
        {appoggio=="" ? <div>aspetto</div> :<FotoAppartamento id={props.id} vettoreFoto={appoggio} setVettoreFoto={appoggio2}/>
}


                   </section>
   
                </Card.Body>
                
              </Card>
      </Col>
   );

}

export default StepFiveModifica;