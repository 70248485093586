import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler'
import withDragDropContext from '../withDnDContext'
import { Card, Tab, Nav } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import swal from 'sweetalert'
import Swal from 'sweetalert2'

import env from '../../../../envConfig'
import apiConfig from '../../../../API/apiConfig'
import { callLambda } from '../../../../API/api'
import moment from 'moment'
import DateRangePicker from "react-bootstrap-daterangepicker";
//import "bootstrap-daterangepicker/daterangepicker.css";
import ModalPrenotazioni from './components/ModalPrenotazioni'
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { listPeriodCalendar } from '../../../../utils/convertPeriodInSingleDays'
import 'react-big-scheduler/lib/css/style.css'
import { buildNewPrenotazione, buildPrenotazioneBody, checkErrorePrenotazione, colorWeekend } from './components/utils'
import { listModalForPrenotazioni } from '../../../../envListVar'
import { dateFromTimestampToNormalFormat, getDay } from '../utils/utils'

/// Path
var path = window.location.pathname;
path = path.split("/");
path = path[path.length - 1];

class CalendarPrenotazioni extends Component {

    constructor(props) {
        super(props);
        console.log("ismobile?", this.props.isMobile)
        let schedulerData = ""
        if (!this.props.isMobile) {
            schedulerData = new SchedulerData(new Date(), ViewTypes.Week, false, false, {
                crossResourceMove: false,
                checkConflict: true,
                views: [
                    { viewName: 'Settimana', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
                    { viewName: 'Mese', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
                    { viewName: 'Anno', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false },
                ]
            });
            schedulerData.config.monthResourceTableWidth = 250
        } else {
            schedulerData = new SchedulerData(new Date(), ViewTypes.Month, false, false, {
                crossResourceMove: false,
                checkConflict: true,
                views: [
                    { viewName: 'Mese', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
                    { viewName: 'Anno', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false },
                ]
            });
            schedulerData.config.monthResourceTableWidth = 200
        }

        schedulerData.localeMoment('it').format('MMM D, YYYY');
        schedulerData.setResources(this.props.rooms);
        schedulerData.setEvents(this.props.prenotazioni);
        //console.log(this.props.width)
        console.log(this.props.width)
        //schedulerData.documentWidth = 10

        schedulerData.config.schedulerWidth = this.props.width * 0.85
        schedulerData.config.resourceName = "Appartamenti"
        //permette di mettere l'ora nel formato giorno - mese - anno sulle colonne
        schedulerData.config.nonAgendaOtherCellHeaderFormat = "ddd D/M"

        //schedulerData = colorWeekend(schedulerData)

        schedulerData.config.nonWorkingTimeHeadBgColor = "#ffffff"
        schedulerData.config.nonWorkingTimeBodyBgColor = "#ffffff"

        this.state = {
            viewModel: schedulerData,
            editModal: false,
            checkEditModal: false,
            width: 0, height: 0,
            editFormData: {
                id: 0,
                title: '',
                numeroAdulti: 0,
                numeroBambini: 0,
                end: '',
                start: '',
                nome: '',
                cognome: '',
                mail: '',
                checkin: '',
                checkout: '',
                appartamento: '',
                telefono: 0,
                prezzo: 0
            },
            editContactId: null,
            listFormsModal: [],
            personeMax: 0,
            eliminaButton: false
        }
        this.changeStateEditModal = this.changeStateEditModal.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
    }


    updateDimensions = () => {
        //    console.log("sono qui")
        this.setState({ width: document.getElementById('testCol').offsetWidth, height: window.innerHeight }, () => {
            let appoggio = this.state.viewModel
            appoggio.config.schedulerWidth = this.state.width * 0.9
            this.setState({ viewModel: appoggio })
        });
    };

    componentDidMount() {

        this.setState({
            checkEditModal: this.props.isMobile,
            listFormsModal: listModalForPrenotazioni(this.state.editFormData)

        }, () => {
            let listaFormsModal = this.state.listFormsModal
            listaFormsModal.splice(0, 0, {
                titolo: "Seleziona un appartamento",
                nome: "appartamento",
                valore: this.props.rooms,
                placeholder: "Seleziona un appartamento"
            })

        })
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }


    changeStateEditModal() {
        this.setState({ checkEditModal: !this.state.checkEditModal })
    };

    eventhandler = () => {

        let prenotazione = this.state.editFormData
        let prenotazioneTest = []
        prenotazioneTest = prenotazione
        this.setState({ eliminaButton: false }, () => {
            console.log("this.state.editFormData", this.state.editFormData);

        })

    }

    handleDateClick = (slotInfo) => {
        // Print the start and end times for the clicked date
        console.log(slotInfo.start, slotInfo.end);
    }

    refreshCalendar(schedulerData, start, end) {
        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.getPrenotazioni,
            method: "GET",
            queryParams: "?dataInizio=" + start + "&dataFine=" + end
        }

        callLambda(paramsAxios, res => {
            console.log("RES", res)
            let prenotazioni = []
            if (res.data.esito === "OK") {
                res.data.risultato.map((room) => {
                    room.prenotazioni.map((prenotazione) => {
                        if (prenotazione['stato'] !== 'CANCELLATA') {
                            prenotazioni.push(prenotazione)
                        }
                    })
                });

                prenotazioni.map((prenotazione) => {
                    prenotazione = dateFromTimestampToNormalFormat(prenotazione)
                })

                schedulerData.setEvents(prenotazioni);
                this.setState({
                    viewModel: schedulerData
                })

            } else {
                //mettere errore
            }
        });
    }

    prevClick = (schedulerData) => {
        schedulerData.prev();
        //schedulerData = colorWeekend(schedulerData)

        let start = moment(schedulerData.startDate).utc().unix() * 1000
        let fine = moment(schedulerData.endDate).utc().unix() * 1000
        this.refreshCalendar(schedulerData, start, fine)
    }

    nextClick = (schedulerData) => {
        schedulerData.next();
        //schedulerData = colorWeekend(schedulerData)

        let start = moment(schedulerData.startDate).utc().unix() * 1000
        let fine = moment(schedulerData.endDate).utc().unix() * 1000
        this.refreshCalendar(schedulerData, start, fine)
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        // schedulerData = colorWeekend(schedulerData)

        let start = moment(schedulerData.startDate).utc().unix() * 1000
        let fine = moment(schedulerData.endDate).utc().unix() * 1000
        this.refreshCalendar(schedulerData, start, fine)
    }

    eventClicked = (schedulerData, event) => {
        this.handleEditClick(event)
    };

    handleEditFormDelete = (event) => {
        swal({
            title: "Sei sicuro di voler eliminare la prenotazione?",
            text:
                "Una volta elimanata, sarà comunque ripristinabile!",
            icon: "info",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({

                    editModal: false,
                });
                Swal.fire({
                    title: '<strong>Nota cancellazione<strong>',
                    icon: 'warning',
                    html: `<textarea
							name="noteCancellazioneCalendar"
							id="noteCancellazioneCalendar"
							rows={2} placeholder="Inserisci una nota per la cancellazione.."
						/>`,
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    cancelButtonText: 'Annulla',
                    confirmButtonText: 'Conferma',
                }).then(res => {
                    console.log("res", res)
                    if (res.isConfirmed) {
                        let noteCancellazione = document.getElementById("noteCancellazioneCalendar").value
                        console.log("noteCancellazione", noteCancellazione)
                        if (noteCancellazione === "") {
                            console.log("dentro")
                            Swal.fire({
                                icon: 'error',
                                title: 'Attenzione',
                                text: 'La nota di cancellazione è obbligatoria!',
                            })
                        } else {
                            let prenotazioneBody = buildPrenotazioneBody(this.state.editFormData, this.state.editContactId, this.props.rooms, "CANCELLATA")
                            prenotazioneBody['noteCancellazione'] = noteCancellazione

                            const schedulerData = this.state.viewModel

                            //mi preparo la chiamata
                            let body = {
                                idRoom: prenotazioneBody.resourceId,
                                prenotazione: prenotazioneBody,
                                idPrenotazione: prenotazioneBody.id,
                            }

                            let paramsAxios = {
                                endpoint: env().API_ENDPOINT_URL + apiConfig.modificaPrenotazione,
                                method: 'PUT',
                                body: body
                            }

                            console.log("finale", body)


                            callLambda(paramsAxios, res => {
                                console.log("RES", res)
                                if (res.data.esito === "OK") {
                                    schedulerData.removeEventById(body.idPrenotazione);
                                    swal("OK! La tua prenotazione è stata correttamente eliminata!", {
                                        icon: "success",
                                    });
                                    this.setState({
                                        viewModel: schedulerData,
                                        editContactId: null,
                                        editModal: false,
                                        eliminaButton: false
                                    }, () => {
                                        console.log("this.state vieModel", this.state.viewModel)
                                    });
                                }
                            });

                        }
                    }
                })
            }
        });
    }

    handleEditFormSubmit = () => {

        let trovatoErrore = checkErrorePrenotazione(this.state.editFormData, this.state.personeMax)

        if (trovatoErrore['valore'] === true) {
            this.notifyBottomLeft(trovatoErrore['message'])
        }
        //passati i controlli parte l'operazione di chiamata alla axios ed aggiorno il componente
        else {

            let prenotazioneBody = buildPrenotazioneBody(this.state.editFormData, this.state.editContactId, this.props.rooms, "")

            const schedulerData = this.state.viewModel
            const index = schedulerData.events.findIndex((evento) => evento.id === prenotazioneBody.id)

            //mi preparo la chiamata
            let body = {
                idRoom: prenotazioneBody.resourceId,
                prenotazione: prenotazioneBody,
                idPrenotazione: prenotazioneBody.id,
            }

            let paramsAxios = {
                endpoint: '',
                method: '',
                body: body
            }

            if (index !== -1) {
                paramsAxios['endpoint'] = env().API_ENDPOINT_URL + apiConfig.modificaPrenotazione
                paramsAxios['method'] = 'PUT'

            } else {
                paramsAxios['endpoint'] = env().API_ENDPOINT_URL + apiConfig.inserisciPrenotazioni
                paramsAxios['method'] = 'POST'
            }
            console.log("finale", body)

            callLambda(paramsAxios, res => {

                if (res.data.esito === "OK") {
                    if (index !== -1) {
                        schedulerData.removeEventById(prenotazioneBody.id);
                        swal("Prenotazione modificata!", "", "success")

                    } else {
                        swal("Nuova prenotazione aggiunta!", "", "success")

                    }
                    prenotazioneBody = dateFromTimestampToNormalFormat(prenotazioneBody)

                    schedulerData.addEvent(prenotazioneBody);
                    this.setState({
                        viewModel: schedulerData,
                        editContactId: null,
                        editModal: false,
                        eliminaButton: false
                    });
                } else {
                    swal("Attenzione!", res.data.descrizioneErrore, "error")
                }
            });

        }

    }



    //update data function
    handleEditFormChange = (event) => {

        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...this.state.editFormData };
        newFormData[fieldName] = fieldValue;
        let listaFormsModal = this.state.listFormsModal
        for (let i = 0; i < listaFormsModal.length; i++) {
            if (listaFormsModal[i].nome === fieldName && fieldName !== "appartamento" && fieldName !== "start") {
                listaFormsModal[i].valore = fieldValue
            } else if (listaFormsModal[i].nome === fieldName && fieldName === "appartamento") {
                console.log("sono in appartamento")
                for (let j = 0; j < this.props.rooms.length; j++) {
                    if (fieldValue === this.props.rooms[j].name) {
                        newFormData['resourceId'] = this.props.rooms[j].id
                        for (let z = 0; z < listaFormsModal.length; z++) {
                            if (listaFormsModal[z].nome === "numeroAdulti") {
                                //me lo salvo in uno stato per fare i controlli sulla regex alla conferma
                                this.setState({
                                    personeMax: this.props.rooms[j].personeMax
                                })
                            }
                        }
                    }
                }
            } else if (listaFormsModal[i].nome === fieldName && fieldName === "start") {
                listaFormsModal[i].valore = fieldValue
                for (let z = 0; z < listaFormsModal.length; z++) {
                    if (listaFormsModal[z].nome === "end") {
                        //questo if mi serve nel caso da mobile la data di end deve essere sempre successiva a quella di start
                        listaFormsModal[z].min = moment(fieldValue).add('1', 'days').format('YYYY-MM-DD')
                    }
                }
            }
        }

        console.log("newFormData dopo tutti i settaggi", newFormData)


        this.setState({
            editFormData: newFormData,
            listFormsModal: listaFormsModal
        })
    };

    handleEditClick(newEvent) {
        console.log("**** ABULIUTATO EDIT MODAL *****")
        const formValues = newEvent
        console.log("formValues", formValues)

        let listaFormsModal = this.state.listFormsModal

        //questo for lo faccio per inizializzare i valori proveniente da newEvent!!
        for (let i = 0; i < listaFormsModal.length; i++) {
            for (var key in newEvent) {
                if (listaFormsModal[i].nome === key && listaFormsModal[i].nome !== "appartamento") {
                    if (listaFormsModal[i].nome === "numeroAdulti" && newEvent['resourceId'] !== '') {
                        //questo if lo faccio per settare il campo max col valore di numeroAdulti dell'appartamento
                        //listaFormsModal[i].max = this.props.rooms.filter((x) => x.id === newEvent.resourceId).map((x) => x.numeroAdulti)[0]
                        //me lo salvo in uno stato per fare i controlli sulla regex alla conferma
                        this.setState({
                            personeMax: this.props.rooms.filter((x) => x.id === newEvent.resourceId).
                                map((x) => x.personeMax)[0]
                        })
                    }
                    if (listaFormsModal[i].nome === key && key === "start") {
                        for (let z = 0; z < listaFormsModal.length; z++) {
                            if (listaFormsModal[z].nome === "end") {
                                //questo if mi serve nel caso da mobile la data di end deve essere sempre successiva a quella di start
                                listaFormsModal[z].min = moment(newEvent['start']).add('1', 'days').format('YYYY-MM-DD')
                            }
                        }
                    }
                    listaFormsModal[i].valore = newEvent[key]
                }
            }
        }
        console.log("listaFormsModal", listaFormsModal)

        this.setState({
            editModal: false, editContactId: newEvent.id,
            editFormData: formValues, listFormsModal: listaFormsModal
        }, () => {
            this.setState({ editModal: true })
        })

        if (newEvent.nome !== '') {
            this.setState({ eliminaButton: true })

        }

    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {

        let period = listPeriodCalendar(start, end)
        if (period.length === 0) {
            let newPrenotazione = buildNewPrenotazione('', '', '');
            this.setState({ editModal: true })
            this.handleEditClick(newPrenotazione)

        }
        else if (period.length > 1) {
            let newPrenotazione = buildNewPrenotazione(start, end, slotId);
            this.setState({ editModal: true })
            this.handleEditClick(newPrenotazione)
        } else {
            swal("Attenzione!", "Devi selezionare almeno una notte per inserire una prenotazione valida!", "error")
        }


    }

    conflictOccurred = () => {
        swal("Attenzione!", "Non puoi sovrapporre due prenotazioni nello stesso periodo!", "error")
    }


    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        let start = moment(getDay("firstDay", date.toDate(), null)).startOf('day').utc().unix() * 1000
        //l'aggiunta di 1 minuto mi permette di prendere le prenotazioni che continuano durante il weekend!!
        let fine = moment(getDay("lastDay", date.toDate(), null)).endOf('day').utc().unix() * 1000
        console.log("start", start)
        console.log("fine", fine)
        this.refreshCalendar(schedulerData, start, fine)


    }

    notifyBottomLeft = (message) => {
        toast.error("❌ " + message + "!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    };

    eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
        return (

            <div style={{ width: '300px' }}>
                <Row type="flex" align="middle">
                    <Col span={2}>
                        <div className="status-dot" style={{ backgroundColor: statusColor }} />
                    </Col>
                    <Col span={22} className="overflow-text">
                        <span className="header2-text" title={title}>{title}</span>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={12}>
                        <h3>check-in:</h3>
                        <Row type="flex" align="middle">
                            <Col span={10}>

                                <div className="header1-text"> {eventItem.checkin} </div>
                            </Col>
                            <Col span={2} style={{ marginTop: '11px' }}>
                                <div className="header4-text"> {moment(eventItem.start).format('DD/MM')} </div>
                            </Col>

                        </Row>

                    </Col>
                    <Col span={12}>

                        <h3>check-out:</h3>

                        <Row type="flex" align="middle">
                            <Col span={10}>

                                <div className="header1-text"> {eventItem.checkout} </div>
                            </Col>
                            <Col span={2} style={{ marginTop: '11px' }}>
                                <div className="header4-text"> {moment(eventItem.end).add('2', 'hours').format('DD/MM')} </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={2}>
                        <div />
                    </Col>
                    <Col span={22} style={{ textAlign: 'center' }} >
                        <Link className={`${path === "prenotazione-details" ? "mm-active" : ""}`}
                            to={"/prenotazione-details/" + eventItem.resourceId + "/" + eventItem.id}
                        >
                            <button type="button"
                                className="btn btn-primary btn-sm"> <i className="flaticon-delete-1"></i> Vai ai dettagli della prenotazione</button>

                        </Link>

                    </Col>
                </Row >
            </div >
        );
    }

    
    render() {
        const { viewModel, editModal } = this.state;
        return (
            <div>
                <div className="form-check custom-checkbox mb-3 checkbox-success"
                    style={{ textAlign: 'center', margin: '0 auto' }}
                >
                    <Button
                        className="me-2"
                        variant="primary btn-rounded"
                        onClick={this.newEvent}
                    >
                        <span className="btn-icon-start text-primary">
                            <i className="fa fa-plus color-primary" />
                        </span>
                        Inserimento manuale delle prenotazioni
                    </Button>
                </div>

                {editModal &&
                    <ModalPrenotazioni
                        setEditFormDataModale={this.handleEditFormChange}
                        setConformModale={this.handleEditFormSubmit}
                        setEliminaModale={this.handleEditFormDelete}
                        listForms={this.state.listFormsModal}
                        editModal={editModal}
                        onChange={this.eventhandler}
                        isMobile={this.props.isMobile}
                        isClicked={this.state.eliminaButton}
                        prenotazione={this.state.editFormData}
                    />

                }

                <div className="row" id="testRow">
                    <div className="col-xl-12 col-xxl-12">
                        <Scheduler schedulerData={viewModel}
                            prevClick={this.prevClick}
                            nextClick={this.nextClick}
                            onSelectSlot={this.handleDateClick}
                            onViewChange={this.onViewChange}
                            eventItemClick={this.eventClicked}
                            onSelectDate={this.onSelectDate}
                            newEvent={this.newEvent}
                            conflictOccurred={this.conflictOccurred}
                            eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
                        />

                    </div>
                </div>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
            </div>
        )
    }


}

export default withDragDropContext(CalendarPrenotazioni);
