import { servizi } from "./servizi";
import { useState, useEffect } from "react";
import React from "react";
import data from "./servizi"


export default function MultiCheckBox(props) {

  const [categoria, setCategoria] = useState()
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);


  const handleOnChange = (event, position, name, isPagamento, id) => {
    //operazioni per salvarmi lo stato checked/unchecked per ogni box in modo da 
    //ricordare le scelte fatte quando si va avanti e dietro nello step form
    const updatedCheckedState = props.checkedState.map((item, index) =>
      index === position ? !item : item

    );

    
    props.setCheckedState(updatedCheckedState);

  };



  useEffect(() => {

    //Mi salvo i dati del file servizi.js
    const data2 = require("./servizi")

    //Vado a dividere gli elementi del file per categoria servendomi del campo categoria relativo al suddetto file
    const groups = data2.servizi.reduce((acc, item) => {
      const key = item.categoria
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    setGroups(groups)

  }, []);

  //Groups è un array di array(categorie) che contengono i vari servizi

  return (


    <div className="row" style={{ width: '100%' }}>
      {/* Vado a scorrere la lista di gruppi muovendomi con la chiave (la categoria)*/}
      {Object.keys(groups).map(key => (
        <div className="col-12 col-sm-4" key={key}>

          <div className="card-header" style={{ borderBottom: "0px solid rgba(0, 0, 0, 0.125)" }}>
            <h4 className="card-title">{key}</h4>
          </div>
          <div className="card-body pt-0">

            {servizi.map(({ name, spazio, isPagamento, id, categoria }, index) => {
              if (categoria == key) {
                return (
                  <li key={index}>
                    <div className="form-check mb-2">
                      <div className="left-section">
                        <input
                          type="checkbox"
                          className="form-check-input"

                          id={`custom-checkbox-${index}`}
                          name={name}
                          value={name}
                          checked={props.checkedState[index]}
                          onChange={(e) => handleOnChange(e, index, name, isPagamento, id)}
                        />
                        <label htmlFor={`custom-checkbox-${index}`} className="form-check-label"
                          style={{}}>{name}</label>
                      </div>
                    </div>
                  </li>
                );
              }

            })}


          </div>
        </div>
      ))}

    </div>


  );

}