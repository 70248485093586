import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Link } from 'react-router-dom';
/// Compoents
import PageTitle from "../../layouts/PageTitle";
import { useHistory } from "react-router-dom";

import EventCalendar from "./inserimento-appartamento/EventCalendar";
import FotoAppartamento from "./inserimento-appartamento/FotoAppartamento";
/// Bootstrap
import { Row, Card, Col, Button, Nav } from "react-bootstrap";
import StepOneModifica from './modifica-appartamento/StepOneModifica'
import StepTwoModifica from "./modifica-appartamento/StepTwoModifica";
import StepFourModifica from "./modifica-appartamento/StepFourModifica";
import { callLambda } from "../../../API/api";
import apiConfig from "../../../API/apiConfig";
import config from "../../../config";
import env from './../../../envConfig'
import moment, { calendarFormat } from "moment";
import _ from 'lodash';
import { ToastContainer, toast } from "react-toastify";
import { servizi } from './inserimento-appartamento/components/servizi'

import uuid from 'react-native-uuid';
import StepThreeModifica from "./modifica-appartamento/StepThreeModifica";
import StepFiveModifica from "./modifica-appartamento/StepFiveModifica"
import StepSixModifica from "./modifica-appartamento/StepSixModifica";
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import Loader from "../../components/Loader";
const AppartamentoDettagli = () => {

  const history = useHistory()
  const [inputs, setInputs] = useState({
    MaxPeople: "",
    Description: "",
    Nome: ""
  });
  const [checked, setChecked] = useState(false);
  //Stati per gestire i valori del secondo step
  const [price, setPrice] = useState([]);
  const [calEvents, setcalEvents] = useState(undefined)

  //Stati per gestire i valori del terzo step
  const [services, setServices] = useState([])
  const [flag, setFlag] = useState([])
  const [vettorefoto, setVettoreFoto] = useState([])
  const [checkedState, setCheckedState] = useState(
    new Array(servizi.length).fill(false)
  );
  const [coordinates, setCoordinates] = useState({})
  const [errors, setErrors] = useState({});

  const [prova, setProva] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [refresh2, setRefresh2] = useState(true)
  const [checkedStateOld, setCheckedStateOld] = useState(
    new Array(servizi.length).fill(false)
  );
  const [politicheCancellazione, setPoliticheCancellazione] = useState()

  const [data, setData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [attivaModifica, setAttivaModifica] = useState(false)
  const [inPromotion, setPromotion] = useState(false)
  const [iban, setIban] = useState()
  const [ibanInfo, setibanInfo] = useState()
  const [isVisible, setVisible] = useState(false)
  const [idAppartamento, setIdAppartamento] = useState()


  useEffect(() => {


    let path = window.location.pathname;
    path = path.split("/");
    setIdAppartamento(path[3])
    let paramsAxios = {
      endpoint: env().API_ENDPOINT_URL + apiConfig.getAppartamento,
      method: "GET",
      queryParams: "/" + path[3]
    }



    callLambda(paramsAxios, res => {
      if (res.data.esito == "OK") {
        console.log("risultati", res.data.risultato)


        sessionStorage.setItem('appartamento-old', JSON.stringify(res.data.risultato))

        const data2 = require("../Appartamenti/inserimento-appartamento/components/servizi")

        let appoggio3 = []

        for (let i = 0; i < data2.servizi.length; i++) {
          for (let j = 0; j < res.data.risultato.servizi.length; j++) {
            if (data2.servizi[i].name == res.data.risultato.servizi[j].servizio) {
              appoggio3.push(i)

            }
          }
        }


        // props.setCheckedState(appoggio)
        for (let i = 0; i < checkedState.length; i++) {

          for (let j = 0; j < appoggio3.length; j++) {
            if (i == appoggio3[j]) {
              checkedState[i] = true
              checkedStateOld[i] = true

            }
          }
        }

        // QUA MI SETTO TUTTE LE VARIABILI CHE MI SERVONO PER RENDERIZZARE QUELLO CHE C'E' ATTUALMENTE NELL'APPARTAMENTO
        //Setto coordinate attuali
        setCoordinates(res.data.risultato.locazione)

        //Setto servizi attuali
        setServices(res.data.risultato.servizi)

        //Setto Prenotazione Diretta attuale
        setChecked(res.data.risultato.prenotazioneDiretta !== undefined ? res.data.risultato.prenotazioneDiretta : false)

        //Setto Nome,Description,MaxPeole attuali
        setInputs({
          "Description": res.data.risultato.descrizione,
          "MaxPeople": res.data.risultato.personeMax,
          "Nome": res.data.risultato.nome
        })

        //Setto Foto attuali
        let appoggioFoto = res.data.risultato.foto
        setVettoreFoto(appoggioFoto)

        //Setto flag In Promozione Attuale
        setPromotion(res.data.risultato.inPromotion !== undefined ? res.data.risultato.inPromotion : false)

        //Setto Iban attuale
        setIban(res.data.risultato.iban != undefined ? res.data.risultato.iban.Iban : '')

        //Setto Iban Info attuali senza caratteri <br\ per andare da capo
        var regex = /<br\s*[\/]?>/gi;
        setibanInfo(res.data.risultato.iban != undefined ? res.data.risultato.iban.Info.replace(regex, "\n") : '')

        //Setto flag Visibile attuale
        setVisible(res.data.risultato.isVisible != undefined ? res.data.risultato.isVisible : false)

        //Setto prezzi attuali e servizi facendo delle manipolazioni sul formato
        let prezziArray = Object.entries(res.data.risultato.prezzi).map(([date, prezzo]) => ({
          prezzo,
          date,
        }));
        for (let i = 0; i < prezziArray.length; i++) {
          prezziArray[i].date = parseInt(prezziArray[i].date, 10);

        }
        for (let i = 0; i < prezziArray.length; i++) {

          prezziArray[i].date = moment(prezziArray[i].date).format('YYYY-MM-DD');
        }
        //variabile per Eventi 
        let appoggio = []
        //variabile per Prezzi
        let appoggio2 = []

        for (let i = 0; i < prezziArray.length; i++) {
          let CodID = uuid.v4()
          appoggio.push({
            title: prezziArray[i].prezzo + " Euro",
            start: prezziArray[i].date,
            end: prezziArray[i].date,
            allDay: true,
            id: CodID
          })
          appoggio2.push({ prezzo: prezziArray[i].prezzo, date: prezziArray[i].date, id: CodID })
        }
        setcalEvents(appoggio)
        sessionStorage.setItem('calEventsOld', JSON.stringify(appoggio))
        setPrice(appoggio2)

        //Setto refresh
        setRefresh(false)
        setRefresh2(false)




      } else {
        //QUa devo fare una gestione delle eccezioni 
      }


    }

    );



    if (inputs.MaxPeople != undefined) {
      setRefresh2(false)
    }


  }, []);


  //Funzione per vedere se tutti gli elementi dell'array sono falsi
  const isAllFalse = (arr) => arr.every(element => !element);

  //funzione per confrontare due oggetti in tutti i loro elementi ad eccezione dell'id
  function dataToTimestamp(data) {
    const result = {};
    data.forEach(item => {
      const timestamp = new Date(item.date).getTime();
      result[timestamp] = item.prezzo;
    });
    return result;
  }

  //funzione per ordinare gli array
  function sortArrayByStartDate(arr) {
    // Convert the strings to JSON objects
    arr = arr.map(JSON.parse);
    // Sort the array by the "start" date property
    arr.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });
    // Convert the JSON objects back to strings
    arr = arr.map(JSON.stringify);
    return arr;
  }

  //funzione per comparare gli array di json
  function compareJSONArrays(arr1, arr2) {

    if (arr1.length != arr2.length) return false
    // Convert each JSON object to a string and sort the array
    arr1 = arr1.map(JSON.stringify).sort();
    arr2 = arr2.map(JSON.stringify).sort();
    // Compare the sorted arrays
    for (let i = 0; i < arr1.length; i++) {
      // exclude the id key from the comparison
      let json1 = JSON.parse(arr1[i]);
      let json2 = JSON.parse(arr2[i]);
      delete json1.id;
      delete json2.id;
      arr1[i] = JSON.stringify(json1);
      arr2[i] = JSON.stringify(json2);
    }
    arr1 = sortArrayByStartDate(arr1)
    arr2 = sortArrayByStartDate(arr2)

    return _.isEqual(arr1, arr2)
  }


  useEffect(() => {

    console.log("in promozione use", inPromotion)



    if (inputs.MaxPeople === "" || inputs.Nome === undefined || inputs.Description == undefined || coordinates === undefined || vettorefoto.length === 0 || services === undefined || isAllFalse(checkedState) || calEvents === undefined || inPromotion === undefined || iban === undefined || isVisible == undefined) return;


    let calEventsOld = JSON.parse(sessionStorage.getItem('calEventsOld'))
    let appartamentoOld = JSON.parse(sessionStorage.getItem('appartamento-old'))
    var regex = /<br\s*[\/]?>/gi;
    let appoggioIbanInfoReplace = appartamentoOld.iban !== undefined ? appartamentoOld.iban.Info.replace(regex, "\n") : '';
    let appoggioIban = appartamentoOld.iban !== undefined ? appartamentoOld.iban.Iban : '';



    if (appartamentoOld.personeMax == inputs.MaxPeople && appartamentoOld.descrizione == inputs.Description
      && appartamentoOld.nome == inputs.Nome && _.isEqual(coordinates, appartamentoOld.locazione) && _.isEqual(appartamentoOld.foto, vettorefoto)
      && appartamentoOld.prenotazioneDiretta === checked && _.isEqual(appartamentoOld.servizi, services) && _.isEqual(checkedStateOld, checkedState)
      && compareJSONArrays(calEventsOld, calEvents) && appartamentoOld.inPromotion == inPromotion && appoggioIban == iban && appartamentoOld.isVisible == isVisible && ibanInfo == appoggioIbanInfoReplace) {

      console.log("veramente uguali")
      setAttivaModifica(false)
    } else {
      console.log("veramente non uguali")
      setAttivaModifica(true)


    }

  }, [inputs.MaxPeople, inputs.Description, inputs.Nome, coordinates, vettorefoto, checked, checkedState, calEvents, inPromotion, iban, isVisible, ibanInfo])






  const transformData = (data) => {
    const transformedData = {};
    data.forEach((item) => {
      const timestamp = moment(item.date).valueOf()
      transformedData[timestamp] = item.prezzo;


    });

    return transformedData;
  }


  const notifyBottomLeft = (message) => {
    toast.error("❌ " + message + "!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };


  function handleModifica() {

    let serviziNew = []
    const data2 = require("../Appartamenti/inserimento-appartamento/components/servizi")
    for (let i = 0; i < data2.servizi.length; i++) {
      if (checkedState[i] == true) {
        serviziNew.push({ "servizio": data2.servizi[i].name, "isPagamento": data2.servizi[i].isPagamento, "id": data2.servizi[i].id })

      }
    }


    var IntMaxPeople = parseInt(inputs.MaxPeople)
   

    let pricenew = transformData(price)

    let appartamento = JSON.parse(sessionStorage.getItem('appartamento-old'))

    let appoggio
    appoggio = ibanInfo.replace(/\n/g, "<br>");

    const IbanCode = {
      "Iban": iban,
      "Info": appoggio
    }


    let body = {
      id: appartamento.id,
      descrizione: inputs.Description,
      foto: vettorefoto,
      locazione: coordinates,
      nome: inputs.Nome == "" ? "Nome non inserito" : inputs.Nome,
      personeMax: IntMaxPeople,
      prezzi: pricenew,
      servizi: serviziNew, 
      prenotazioneDiretta: checked,
      prenotazioni: JSON.parse(sessionStorage.getItem('appartamento-old')).prenotazioni,
      inPromotion: inPromotion,
      iban: IbanCode,
      isVisible: isVisible
    }

    let paramsAxios = {
      endpoint: env().API_ENDPOINT_URL + apiConfig.modificaAppartamento,
      method: 'PUT',
      body: body
    }



    swal({
      title: "Sei sicuro di voler confermare ?",
      text:
        "Una volta confermata, troverai le modifiche nell'appartamento!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willUpdated) => {


      if (willUpdated) {
        let calEventsOld = JSON.parse(sessionStorage.getItem('calEventsOld'))
        let appartamentoOld = JSON.parse(sessionStorage.getItem('appartamento-old'))



        if (appartamentoOld.personeMax == inputs.MaxPeople && appartamentoOld.descrizione == inputs.Description
          && appartamentoOld.nome == inputs.Nome && _.isEqual(coordinates, appartamentoOld.locazione) && _.isEqual(appartamentoOld.foto, vettorefoto)
          && appartamentoOld.prenotazioneDiretta === checked && _.isEqual(appartamentoOld.servizi, services) && _.isEqual(checkedStateOld, checkedState)
          && compareJSONArrays(calEventsOld, calEvents) && appartamentoOld.iban == iban && appartamentoOld.ibanInfo == ibanInfo) {

          notifyBottomLeft("Non hai effettuato nessuna modifica!")
        } else {


          callLambda(paramsAxios, res => {

            if (res.data.esito === "OK") {
              swal("Confermata!", "La modifica è stata apportata correttamente!", "success")
              history.push("/lista-appartamenti");

            } else {
              if (res.data.esito === "KO") {
                swal("Attenzione!", res.data.descrizioneErrore, "error")
                history.push("/lista-appartamenti");
              } else {

                swal("Attenzione!", res.data.descrizioneErrore, "error").then((value) => {
                  history.push("/lista-appartamenti");

                })


              }
            }
          });

        }
      } else {
      }

    })

  }





  return (
    <Fragment>
      <Row>
        <div className="col">
          <PageTitle activeMenu="Modifica appartamento" pageContent="Card" motherMenu="Riepilogo" />
        </div>

        <div className="col">
          {attivaModifica == false
            && <Button
              className="me-2"
              variant="success btn-rounded btn-sm"
              disabled="disabled"

            >
              Conferma Modifiche
            </Button>}
          {
            attivaModifica == true && <Button
              className="me-2"
              variant="success btn-rounded btn-sm"
              onClick={handleModifica}

            >
              Conferma Modifiche
            </Button>
          }
        </div>
      </Row>

      {refresh ? <Loader /> : <Row>
        <StepOneModifica isVisible={isVisible} setVisible={setVisible} inPromotion={inPromotion} setPromotion={setPromotion} inputs={inputs} setInputs={setInputs} checked={checked} setChecked={setChecked} errors={errors}></StepOneModifica>
        <StepFiveModifica id={idAppartamento} vettorefoto={vettorefoto} setVettoreFoto={setVettoreFoto}></StepFiveModifica>
        <StepFourModifica coordinates={coordinates} setCoordinates={setCoordinates}></StepFourModifica>
        <StepThreeModifica checkedState={checkedState} setCheckedState={setCheckedState} politicheCancellazione={politicheCancellazione} setPoliticheCancellazione={setPoliticheCancellazione}></StepThreeModifica>
        <StepTwoModifica price={price} calEvents={calEvents} setcalEvents={setcalEvents} setPrice={setPrice} prova={prova}></StepTwoModifica>
        <StepSixModifica iban={iban} setIban={setIban} ibanInfo={ibanInfo} setibanInfo={setibanInfo}></StepSixModifica>
      </Row>
      }






    </Fragment>

  );
}

export default AppartamentoDettagli