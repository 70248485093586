import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

/// Path
var path = window.location.pathname;
path = path.split("/");
path = path[path.length - 1];

class ModalPrenotazioni extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editModal: this.props.editModal,
            disableForm: this.props.prenotazione.start === '' ? true : false,
            enableAppartamento: false
        }
        this.changeDisableForm = this.changeDisableForm.bind(this);
        console.log("constructor", this.props.prenotazione.start)

    }


    changeDisableForm = (event) => {
        this.setState({ disableForm: false });
        this.props.setEditFormDataModale(event)
    };

    handleChange = e => {
        this.setState({ editModal: !this.state.editModal }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state);
            }
        })
    };

    componentDidMount() {
        if (!this.state.enableAppartamento && this.props.prenotazione.start === '') {
            this.setState({ enableAppartamento: true })
        }
        console.log("componentDidMount", this.props.prenotazione.start)
    }
    render() {
        const { viewModel } = this.state;
        return (
            <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head">
                <Modal className="modal fade" show={this.state.editModal} onHide={this.handleChange}
                >
                    <div className="" role="document">
                        <div className="">
                            <form >
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">Modifica questi campi</h4>
                                    {this.props.isMobile && this.props.isClicked &&
                                        <Link className={`${path === "prenotazione-details" ? "mm-active" : ""}`}
                                            to={"/prenotazione-details/" + this.props.prenotazione.resourceId + "/" + this.props.prenotazione.id}
                                        >
                                            <button type="button"
                                                className="btn btn-primary"> <i className="flaticon-delete-1"></i> Vai ai dettagli</button>

                                        </Link>
                                    }


                                    <button type="button" className="btn-close" onClick={this.handleChange} data-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className="row">
                                                {this.props.listForms.map((elemento) =>
                                                    elemento.nome !== "appartamento" ?
                                                        <div className="col-sm-6 col-6">
                                                            <div className="form-group mb-3">
                                                                <label className="text-black font-w500">{elemento.titolo}</label>
                                                                {elemento.nome === "checkin" || elemento.nome === "checkout" ?
                                                                    <></>
                                                                    :
                                                                    <span className="text-danger">*</span>

                                                                }
                                                                <div className="contact-name">
                                                                    {elemento.tipo === "number" ?
                                                                        <input
                                                                            type={elemento.tipo} className="form-control" autoComplete="off"
                                                                            name={elemento.nome}
                                                                            value={elemento.valore}
                                                                            onChange={this.props.setEditFormDataModale}
                                                                            placeholder={elemento.placeholder}
                                                                            min={elemento.min}
                                                                            disabled={this.state.disableForm}
                                                                        />
                                                                        : elemento.tipo === "date" ?
                                                                            <input
                                                                                type={elemento.tipo} className="form-control" autoComplete="off"
                                                                                name={elemento.nome}
                                                                                value={elemento.valore}
                                                                                onChange={this.props.setEditFormDataModale}
                                                                                placeholder={elemento.placeholder}
                                                                                min={elemento.min}
                                                                                disabled={this.state.disableForm}

                                                                            />
                                                                            :
                                                                            <input
                                                                                type={elemento.tipo} className="form-control" autoComplete="off"
                                                                                name={elemento.nome}
                                                                                value={elemento.valore}
                                                                                onChange={this.props.setEditFormDataModale}
                                                                                placeholder={elemento.placeholder}
                                                                                disabled={this.state.disableForm}

                                                                            />
                                                                    }


                                                                    <span className="validation-text"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : this.state.enableAppartamento &&
                                                        <div className="col-sm-12 col-12">
                                                            <div className="form-group mb-3">
                                                                <label className="text-black font-w500">{elemento.titolo}</label>
                                                                <div className="contact-name">
                                                                    <select
                                                                        className="form-control"
                                                                        id="select-appartamento-prenotazione"
                                                                        name={elemento.nome}
                                                                        onChange={this.changeDisableForm}
                                                                        required={true}
                                                                    >
                                                                        <option disabled selected value>Seleziona un appartamento</option>

                                                                        {elemento.valore.map((nomeAppartamento) =>
                                                                            <option value={nomeAppartamento.name}>{nomeAppartamento.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <span className="validation-text"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {this.props.prenotazione.stato !== "TERMINATA" &&
                                        <>
                                            {!this.props.isClicked ?
                                        <button type="button" className="btn btn-success"
                                            onClick={this.props.setConformModale}
                                        >Conferma</button>
                                        :
                                        <button type="button" className="btn btn-success"
                                            onClick={this.props.setConformModale}
                                        >Modifica</button>}
                                    {this.props.isClicked &&
                                        <button type="button" className="btn btn-danger"
                                            onClick={this.props.setEliminaModale}
                                        >Elimina
                                        </button>
                                    }
                                </>
                                    }

                                    <button type="button" onClick={this.handleChange} className="btn btn-light"> <i className="flaticon-delete-1"></i> Annulla</button>
                                </div>
                            </form>

                        </div>
                    </div >
                </Modal >
            </div >
        )
    }

}

export default ModalPrenotazioni;
