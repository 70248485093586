import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "./../../../../vendor/swiper/css/swiper-bundle.min.css";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";

function NoteModifica(props) {
	const [noteBestHouse, setNoteBestHouse] = useState('');
	const [noteCliente, setNoteCliente] = useState('');

	useEffect(() => {
		var regex = /<br\s*[\/]?>/gi;

		let noteBestHouse = props.noteBestHouse.replace(regex, "\n")
		let noteCliente = props.noteCliente.replace(regex, "\n")
		setNoteBestHouse(noteBestHouse)
		setNoteCliente(noteCliente)
	}, [])
	return (
		<div className="card-body pt-0">

			<div className="profile-tab">
				<div className="custom-tab-1">
					<Tab.Container defaultActiveKey='Posts'>
						<Nav as='ul' className="nav nav-tabs">
							<Nav.Item as='li' className="nav-item">
								<Nav.Link to="#my-posts" eventKey='Posts'>Note cliente</Nav.Link>
							</Nav.Item>
							<Nav.Item as='li' i className="nav-item">
								<Nav.Link to="#about-me" eventKey='About'>Note BestHouse</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>
							<Tab.Pane id="my-posts" eventKey='Posts'>
								<div className="my-post-content pt-3">
									<div className="post-input">
										<textarea
											name="noteCliente"
											id="textarea"
											rows={7} className="form-control bg-transparent" placeholder="Inserisci le note da inviare al cliente..."
											defaultValue={noteCliente}
											onChange={props.onChangePrenotazione}
										/>
									</div>
								</div>
							</Tab.Pane>
							<Tab.Pane id="about-me" eventKey='About'>
								<div className="my-post-content pt-3">
									<div className="post-input">
										<textarea
											name="noteBestHouse"
											id="textarea"
											rows={7} className="form-control bg-transparent" placeholder="Inserisci le tue note personali..."
											defaultValue={noteBestHouse}
											onChange={props.onChangePrenotazione}
										/>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>
		</div>
	)
}
export default NoteModifica;