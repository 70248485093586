import React from "react";
import { Map, GoogleApiWrapper, Geocoder, Marker } from 'google-maps-react';
import { Component } from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import Input from "antd/lib/input/Input";





class ApartmentMap extends Component {
    constructor(props) {

        super(props);

    }
    searchInput = React.createRef();


    wait(ms) {
        var start = new Date().getTime();
        var end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    }

    componentDidMount() {
        const autocomplete = new window.google.maps.places.Autocomplete(this.searchInput.current.input);
        window.google.maps.places.Autocomplete(this.searchInput.current.input);
        console.log("FATTO IL MISFATTO", this.searchInput.current)

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            const lat = this.setState({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            })

            this.props.setCoordinates({ "lat": place.geometry.location.lat(), "long": place.geometry.location.lng() })

            Swal.fire(`Hai scelto le seguenti coordinate : 
      Lat: ${this.props.coordinates.lat}   Lng: ${this.props.coordinates.long}`)


        })
    }


    render() {
        return (

            <div>
                <div className="row" style={{ height: "1pc", width: "20pc", marginBottom: "2pc" }}>

                    <Input type="text" placeholder="Inserisci indirizzo" ref={this.searchInput} />






                </div>
                <div className="row" style={{ height: "25pc" }}>
                    <Map
                        containerStyle={{ position: "relative" }}
                        google={this.props.google}
                        zoom={14.5}

                        initialCenter={{ "lat": this.props.coordinates.lat ? this.props.coordinates.lat : 40.1400536, "lng": this.props.coordinates.long ? this.props.coordinates.long : 15.1857642 }}
                        center={{ "lat": this.props.coordinates.lat, "lng": this.props.coordinates.long }}
                        onClick={this.handleMapClick}

                    >
                        <Marker slug={"giacomo"} name={"mentre"} title={"vediamo"} position={{ "lat": this.props.coordinates.lat != undefined && this.props.coordinates.lat, "lng": this.props.coordinates.long != undefined && this.props.coordinates.long }} />

                    </Map>
                </div>
            </div>

        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBCB4y0SztfLo236n4JECwTvIQd-VecF2Y',
})(ApartmentMap);