import { useEffect, useState } from 'react';
import moment from 'moment';

const listPeriodCalendar = (startDate, fineDate) => {

    console.log(" ***startDate ***", startDate)
    console.log(" *** fineDate ***", fineDate)

    var i = 0;
    var dates = [];
    if (fineDate !== undefined) {
        while (i <= moment(fineDate).diff(startDate, 'days')) {

            var date = moment(startDate).add(i, 'days').format();;
            dates.push(date);
            i++;
        }
    }

    return dates

};


export {
    listPeriodCalendar
}
