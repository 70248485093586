import React, { Component } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import ModalCalendarSconti from "./Modal/ModalCalendarSconti";
import { v4 as uuidv4 } from 'uuid';
import Swal from "sweetalert2";
import moment from "moment";
import 'moment/locale/it';
import env from '../../../../envConfig'
import apiConfig from '../../../../API/apiConfig'
import { callLambda } from '../../../../API/api'
import Loader from "../../../components/Loader";
class CalendarioGiorni extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        formModal: {
            titolo: "Seleziona un periodo di scontistica",
            nome: "nomePeriodo",
            placeholder: "Seleziona un periodo di scontistica",
            valore: [],
        },
        editFormData: {
            nomePeriodo: "",
            periodoSelezionato: ""
        },
        periodoSelezionato: [],
        scontistica: [],
        modificaEliminaButton: false,
        refreshCalendario: false
    };

    enumerateDaysBetweenDates = function (startDate, endDate) {
        var dates = [];
        var currDate = startDate.startOf('day');
        var lastDate = endDate.startOf('day');
        dates.push(moment(currDate).format('YYYY-MM-DD'));
        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            let dateToPush = moment(currDate).format('YYYY-MM-DD')
            dates.push(dateToPush);
        }
        return dates;
    };


    componentDidMount() {
        let formModalAppogio = this.state.formModal
        this.props.periodi.map((periodo) => {
            formModalAppogio['valore'].push(periodo['title'])
        })
        console.log("formModalAppogio", formModalAppogio)
        console.log("this.state.scontistica", this.state.scontistica)


        let nomePeriodo = ''
        let scontisticaCalendar = []
        let scontisticaArray = this.props.scontistica
        console.log("this.props.scontistica", this.props.scontistica)
        console.log("scontisticaArray", scontisticaArray)

        for (let j = 0; j < this.props.periodi.length; j++) {
            for (let i = 0; i < scontisticaArray.length; i++) {
                if (scontisticaArray[i]['title'] === this.props.periodi[j]['title']) {
                    scontisticaCalendar.push({
                        id: "id-" + i,
                        title: scontisticaArray[i]['title'],
                        start: moment(scontisticaArray[i]['start']).format("YYYY-MM-DD")
                    })
                }
            }
        }

        this.setState({ formModal: formModalAppogio, scontistica: scontisticaCalendar })
    }

    eventClick = (eventClick) => {

        let periodoSelezionato = moment(eventClick.event.start).format("YYYY-MM-DD")
        console.log("Periodo: ", periodoSelezionato)
        console.log("scontistica: ", this.state.scontistica)
        let scontistica = this.state.scontistica

        const found = scontistica.some(x => periodoSelezionato.includes(x['start']))
        console.log("found: ", found)

        this.setState({ modificaEliminaButton: found, periodoSelezionato: [periodoSelezionato] }, () => {
            this.handleEditClick()

        })
    };

    handleSelectClick = arg => {
        let periodoSelezionato = this.enumerateDaysBetweenDates(moment(arg.start), moment(arg.end))
        console.log("Periodo: ", periodoSelezionato)
        console.log("scontistica: ", this.state.scontistica)
        let scontistica = this.state.scontistica
        const found = scontistica.some(x => periodoSelezionato.includes(x['start']))
        console.log("found: ", found)

        this.setState({ modificaEliminaButton: found, periodoSelezionato: periodoSelezionato }, () => {
            this.handleEditClick()
        })

    }

    onClickPeriodo = (event) => {
        console.log("event", event)
    }


    asyncExample = async () => {
        let data;
        try {
            data = Promise.resolve(JSON.parse(sessionStorage.getItem('periodoSelezionato')));
        } catch (err) {
            console.log(err);
        }
        return data;
    };

    handleEditFormDelete = () => {


        let scontisticaBody = {}
        let scontistica = this.state.scontistica
        let arrayPeriodo = this.state.periodoSelezionato
        let idPeriodo = ''
        let nomePeriodo = ''


        for (let i = 0; i < this.props.periodi.length; i++) {
            if (this.props.periodi[i]['title'] === this.state.editFormData['nomePeriodo']) {
                idPeriodo = this.props.periodi[i].id
            }
        }
        console.log("idPeriodo", idPeriodo)

        for (let i = 0; i < this.state.scontistica.length; i++) {
            for (let j = arrayPeriodo.length; j >= 0; j--) {
                if (scontistica[i]['start'] === arrayPeriodo[j]) {
                    scontistica[i]['title'] = idPeriodo
                    arrayPeriodo.splice(j, 1)
                }
            }
        }
        console.log("arrayPeriodo dopo splice", arrayPeriodo)

        for (let j = 0; j < this.props.periodi.length; j++) {
            for (let i = 0; i < scontistica.length; i++) {
                if (scontistica[i]['title'] === this.props.periodi[j]['title']) {
                    scontistica[i]['title'] = this.props.periodi[j]['id']
                }
            }
        }
        console.log("scontistica dopo il primo for", scontistica)

        for (let i = 0; i < scontistica.length; i++) {
            scontisticaBody[scontistica[i]['start']] = scontistica[i]['title']
        }
        console.log("scontisticaBody dopo il secondo for", scontisticaBody)

        for (let i = 0; i < arrayPeriodo.length; i++) {
            scontisticaBody[arrayPeriodo[i]] = idPeriodo
        }
        console.log("scontisticaBody dopo il terzo for ", scontisticaBody)

        for (let i = 0; i < arrayPeriodo.length; i++) {
            delete scontisticaBody[arrayPeriodo[i]];
        }

        console.log("fine scontisticaBody", scontisticaBody)

        scontisticaBody = Object.entries(scontisticaBody).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})

        console.log("fine scontisticaBody", scontisticaBody)

        scontisticaBody = {
            "scontistica": scontisticaBody
        }
        let filtro = 'scontistica'

        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.inserisciAssociazioneGiornoPeriodo,
            method: "POST",
            queryParams: "?anno=" + moment(this.state.periodoSelezionato[0]).year() + "&filtro=" + filtro,
            body: scontisticaBody
        }
        console.log("paramsAxios", paramsAxios)

        callLambda(paramsAxios, res => {

            console.log("[" + paramsAxios['endpoint'] + "]", res)
            if (res.data.esito === "OK") {

                console.log("res", res)
                let scontisticaCalendar = []
                let scontistica = res.data.risultato.scontistica;
                let scontisticaArray = Object.entries(scontistica)
                for (let j = 0; j < this.props.periodi.length; j++) {
                    for (let i = 0; i < scontisticaArray.length; i++) {
                        if (scontisticaArray[i][1] === this.props.periodi[j]['id']) {
                            nomePeriodo = this.props.periodi[j]['title']
                            scontisticaCalendar.push({
                                id: "id-" + i,
                                title: nomePeriodo,
                                start: moment(scontisticaArray[i][0]).format("YYYY-MM-DD")
                            })
                        }
                    }
                }
                this.setState({ scontistica: scontisticaCalendar, refreshCalendario: false, periodoSelezionato: [], editModal: false })

                Swal.fire({
                    icon: 'success',
                    title: 'Cancellato!',
                    text: 'Gli sconti sono stati correttamente cancellati!',
                })
            }

            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ops..',
                    text: res.data.descrizioneErrore,
                })
            }
        });
    }


    handleEditFormSubmit = () => {

        let scontisticaBody = {}
        let scontistica = this.state.scontistica
        let arrayPeriodo = this.state.periodoSelezionato
        let idPeriodo = ''
        let nomePeriodo = ''
        console.log("scontistica", scontistica)
        console.log("arrayPeriodo", arrayPeriodo)
        console.log("this.state.editFormData", this.state.editFormData)
        console.log("this.props.periodi", this.props.periodi)

        for (let i = 0; i < this.props.periodi.length; i++) {
            if (this.props.periodi[i]['title'] === this.state.editFormData['nomePeriodo']) {
                idPeriodo = this.props.periodi[i].id
            }
        }

        for (let i = 0; i < this.state.scontistica.length; i++) {
            for (let j = arrayPeriodo.length; j >= 0; j--) {
                if (scontistica[i]['start'] === arrayPeriodo[j]) {
                    scontistica[i]['title'] = idPeriodo
                    arrayPeriodo.splice(j, 1)
                }
            }
        }
        console.log("scontistica dopo il primo for", scontistica)


        for (let j = 0; j < this.props.periodi.length; j++) {
            for (let i = 0; i < scontistica.length; i++) {
                if (scontistica[i]['title'] === this.props.periodi[j]['title']) {
                    scontistica[i]['title'] = this.props.periodi[j]['id']
                }
            }
        }

        for (let i = 0; i < scontistica.length; i++) {
            scontisticaBody[scontistica[i]['start']] = scontistica[i]['title']
        }
        console.log("scontisticaBody dopo il secondo for", scontisticaBody)

        for (let i = 0; i < arrayPeriodo.length; i++) {
            scontisticaBody[arrayPeriodo[i]] = idPeriodo
        }
        console.log("scontisticaBody dopo il terzo for", scontisticaBody)

        scontisticaBody = {
            "scontistica": scontisticaBody
        }
        let filtro = 'scontistica'

        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.inserisciAssociazioneGiornoPeriodo,
            method: "POST",
            queryParams: "?anno=" + moment(this.state.periodoSelezionato[0]).year() + "&filtro=" + filtro,
            body: scontisticaBody
        }

        callLambda(paramsAxios, res => {
            console.log("[" + paramsAxios['endpoint'] + "]", res)
            if (res.data.esito === "OK") {

                console.log("res", res)
                let scontisticaCalendar = []
                let scontistica = res.data.risultato.scontistica;
                let scontisticaArray = Object.entries(scontistica)
                for (let j = 0; j < this.props.periodi.length; j++) {
                    for (let i = 0; i < scontisticaArray.length; i++) {
                        if (scontisticaArray[i][1] === this.props.periodi[j]['id']) {
                            nomePeriodo = this.props.periodi[j]['title']
                            scontisticaCalendar.push({
                                id: "id-" + i,
                                title: nomePeriodo,
                                start: moment(scontisticaArray[i][0]).format("YYYY-MM-DD")
                            })
                        }
                    }
                }
                this.setState({
                    scontistica: scontisticaCalendar, refreshCalendario: false, periodoSelezionato: [], editModal: false, editFormData: {
                        nomePeriodo: "",
                        periodoSelezionato: ""
                    }
                })

                Swal.fire({
                    icon: 'success',
                    title: 'Aggiornati!',
                    text: 'Gli sconti sono stati correttamente aggiunti!',
                })
            }

            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ops..',
                    text: res.data.descrizioneErrore,
                })
            }
        });


    }

    //update data function
    handleEditFormChange = (event) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...this.state.editFormData };
        newFormData[fieldName] = fieldValue;

        console.log("newFormData", newFormData)

        this.setState({
            editFormData: newFormData
        })
    };


    handleEditClick() {
        console.log("**** ABULIUTATO EDIT MODAL *****")

        console.log("this.state.periodoSelezionato", this.state.periodoSelezionato)
        let formModal = this.state.editFormData

        formModal['periodoSelezionato'] = this.state.periodoSelezionato
        console.log("formModal", formModal)
        sessionStorage.setItem("periodoSelezionato", JSON.stringify(this.state.periodoSelezionato))
        this.setState({
            editModal: false
        }, () => {
            this.setState({
                editModal: true,
                editFormData: formModal
            })
        })

    };


    render() {
        const { editModal, refreshCalendario } = this.state;

        return (

            <Col lg={9}>
                <Card>
                    <Card.Body>

                        {refreshCalendario ?
                            <Loader />
                            :
                            <div className="demo-app-calendar">
                                <FullCalendar
                                    defaultView="dayGridMonth"
                                    header={{
                                        left: "prev,next today",
                                        center: "title",
                                        right:
                                            "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                    }}
                                    rerenderDelay={10}
                                    eventDurationEditable={false}
                                    editable={true}
                                    droppable={false}
                                    plugins={[
                                        dayGridPlugin,
                                        timeGridPlugin,
                                        interactionPlugin,
                                    ]}
                                    ref={this.calendarComponentRef}
                                    weekends={this.state.calendarWeekends}
                                    events={this.state.scontistica}
                                    eventClick={this.eventClick}
                                    select={this.handleSelectClick}
                                    eventReceive={this.eventReceive}
                                    selectable={true}
                                    locale={moment.locale('it')}

                                />

                                {editModal &&
                                    <ModalCalendarSconti
                                        setEditFormDataModale={this.handleEditFormChange}
                                        setConformModale={this.handleEditFormSubmit}
                                        setEliminaModale={this.handleEditFormDelete}
                                        listEventsForms={this.state.listEventsForms}
                                        editModal={editModal}
                                        onChange={this.eventhandler}
                                        isClicked={this.state.modificaEliminaButton}
                                        formModal={this.state.formModal}
                                        periodoSelezionato={this.state.periodoSelezionato}
                                    />
                                }
                            </div>
                        }
                    </Card.Body>
                </Card>
            </Col>

        );
    }
}

export default CalendarioGiorni;
