
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

class ModalCalendarSconti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editModal: this.props.editModal,
        }
        this.changeDisableForm = this.changeDisableForm.bind(this);
    }


    changeDisableForm = (event) => {
        this.props.setEditFormDataModale(event)
    };

    handleChange = e => {
        this.setState({ editModal: !this.state.editModal }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state);
            }
        })
    };

    componentDidMount() {
    }
    render() {
        const { viewModel } = this.state;
        return (
            <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head">
                <Modal className="modal fade" show={this.state.editModal} onHide={this.handleChange}
                >
                    <div className="" role="document">
                        <div className="">
                            <form >
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">Modifica questi campi</h4>

                                    <button type="button" className="btn-close" onClick={this.handleChange} data-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className="row">
                                                <div className="col-sm-12 col-12">
                                                    <div className="form-group mb-3">
                                                        {this.props.periodoSelezionato.length === 0 ?
                                                            <></>
                                                            : this.props.periodoSelezionato.length === 1 ?
                                                                <label className="text-black font-w500">Data selezionata: {this.props.periodoSelezionato[0]}</label>

                                                                :
                                                                <label className="text-black font-w500">Date selezionate: da {this.props.periodoSelezionato[0]} a {this.props.periodoSelezionato[this.props.periodoSelezionato.length - 1]} </label>


                                                        }

                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="text-black font-w500">{this.props.formModal.titolo}</label>
                                                        <span className="text-danger">*</span>
                                                        <div className="contact-name">
                                                            <select
                                                                className="form-control"
                                                                id="scontistica-giorno"
                                                                name={this.props.formModal.nome}
                                                                onChange={this.changeDisableForm}
                                                                required={true}
                                                            >
                                                                <option disabled selected value>Seleziona un periodo di scontistica</option>

                                                                {this.props.formModal.valore.map((periodo) =>
                                                                    <option value={periodo}>{periodo}</option>
                                                                )}
                                                            </select>
                                                            <span className="validation-text"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!this.props.isClicked ?
                                        <button type="button" className="btn btn-success"
                                            onClick={this.props.setConformModale}
                                        >Conferma</button>
                                        :
                                        <button type="button" className="btn btn-success"
                                            onClick={this.props.setConformModale}
                                        >Modifica</button>}
                                    {this.props.isClicked &&
                                        <button type="button" className="btn btn-danger"
                                            onClick={this.props.setEliminaModale}
                                        >Elimina
                                        </button>
                                    }

                                    <button type="button" onClick={this.handleChange} className="btn btn-light"> <i className="flaticon-delete-1"></i> Annulla</button>
                                </div>
                            </form>

                        </div>
                    </div >
                </Modal >
            </div >
        )
    }

}

export default ModalCalendarSconti;
