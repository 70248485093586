import React, { Fragment } from "react";
import {Link} from 'react-router-dom';
import { Row, Card, Col, Button, Nav } from "react-bootstrap";
import MultiCheckBox from "../inserimento-appartamento/components/MultiCheckBox";



const StepThreeModifica = (props) => {



 
        return (

       
            <Fragment>
         
          
            <Col xl="12">
              <Card>
                <Card.Body>
                
                    <section>

                    <MultiCheckBox  checkedState={props.checkedState} setCheckedState={props.setCheckedState} politicheCancellazione={props.politicheCancellazione} setPoliticheCancellazione={props.setPoliticheCancellazione}></MultiCheckBox>

                   </section>
   
                </Card.Body>
                
              </Card>
            </Col>


            
           
          
        </Fragment>
          
        );

}

export default StepThreeModifica;