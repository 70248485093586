import moment from "moment"

const dateFromTimestampToNormalFormat = (prenotazione) => {
    prenotazione['start'] = moment(prenotazione['start']).format('YYYY-MM-DD')
    prenotazione['end'] = moment(prenotazione['end']).add('1', 'days').format('YYYY-MM-DD')
    return prenotazione
}

const dateFromNormalFormatToTimestamp = (prenotazione) => {
    prenotazione['start'] = moment(prenotazione['start']).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().unix() * 1000
    prenotazione['end'] = moment(prenotazione['end']).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).add(-1, 'days').utc().unix() * 1000
    return prenotazione

}

//mi restituisce il sabato (fine) della settimana corrente
const getDay = (d, selectDate, viewType) => {
    if (selectDate != null) {
        var curr = selectDate; // get current date

    } else {
        var curr = new Date; // get current date
    }

    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 7; // last day is the first day + 6
    if (viewType === "Month") {
        first = new Date(curr.getFullYear(), curr.getMonth(), 1);
        last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
    }
    console.log("first: " + first + ", last: " + last)
    if (d === "firstDay") {
        if (viewType === "Month") {
            return first.toUTCString();
        } else {
            return new Date(curr.setDate(first)).toUTCString();
        }
    } else {
        if (viewType === "Month") {
            return last.toUTCString();
        } else {
            return new Date(curr.setDate(last)).toUTCString();
        }

    }
}

export {
    dateFromTimestampToNormalFormat,
    dateFromNormalFormatToTimestamp,
    getDay
}
