import React from "react";
import { Button } from "react-bootstrap";

function FooterCard(props) {
	return (
		<>
			{props.prenotazione['stato'] !== "TERMINATA" ?
				<div className="row">
					< div className="col-12 col-sm-12">
						<div className="card h-auto" >
							<div className="card-body profile-bx">
								< div className="col-12 col-sm-12">
									{props.prenotazione['stato'] !== "CANCELLATA" ?
										props.prenotazione['stato'] === "MODIFICA" && props.statoPrenotazione === "CANCELLATA" ?
											<Button className="me-2"
												disabled={true}
												variant="danger btn-rounded btn-sm"
												onClick={() => props.deletePrenotazione()}
												style={{ width: "100%" }}

											>
												ELIMINA PRENOTAZIONE
											</Button> :
											<Button className="me-2"
												variant="danger btn-rounded btn-sm"
												onClick={() => props.deletePrenotazione()}
												style={{ width: "100%" }}
											>
												ELIMINA PRENOTAZIONE
											</Button>

										:
										<Button className="me-2"
											variant="success btn-rounded btn-sm"
											onClick={() => props.changeStatoPrenotazione("MODIFICA")}
											style={{ width: "100%" }}
										>
											RIPRISTINA PRENOTAZIONE
										</Button>
									}
								</div>
								{props.prenotazione['stato'] !== "MODIFICA" && props.prenotazione['stato'] !== "CANCELLATA" &&
									props.prenotazione['stato'] !== "TERMINATA" && props.prenotazione['stato'] !== "NON_APPROVATA" &&
									< div className="col-12 col-sm-12" style={{ marginTop: "20px" }}>

										<Button className="me-2"
											variant="warning btn-rounded btn-sm"
											onClick={() => props.inviaMail()}
											style={{ width: "100%" }}
										>
											INVIA MAIL DI RIEPILOGO
										</Button>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				:
				<> </>
			}
		</>
	)
}
export default FooterCard;