import React, { useMemo, Fragment, useEffect, useState } from 'react';
import PageTitle from "../../layouts/PageTitle";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS_2 } from './lista-table-appartamenti/Columns_2';
import { GlobalFilter } from './lista-table-appartamenti/GlobalFilter_2';
import { Link } from "react-router-dom";
//import './table.css';
import './lista-table-appartamenti/filtering.css';
import apiConfig from '../../../API/apiConfig';
import { callLambda } from '../../../API/api';
import env from '../../../envConfig'
import Loader from '../../components/Loader';

export const VisualizzaAppartamenti = () => {


	useEffect(() => {
		let filtro = 'id,nome,prenotazioneDiretta,descrizione,foto,locazione,personeMax,prezzi,servizi'
		let paramsAxios = {
			endpoint: env().API_ENDPOINT_URL + apiConfig.getRooms,
			method: "GET",
			queryParams: "?filtro=" + filtro
		}

		callLambda(paramsAxios, res => {

			if (res.data.esito === "OK") {
				let appartamenti = res.data.risultato



				let entries = Object.entries(appartamenti);

				const updatedData = appartamenti.map((item) => {

					return {
						...item, azioni: <div className="d-flex">
							<Link
								to={"/appartamento-details/" + item['nome'] + "/" + item['id']}
								className="btn btn-primary shadow btn-xs sharp me-1"
							>
								<i className="fas fa-pencil-alt"></i>
							</Link>

						</div>
					};
				});



				setMOCK_DATA(updatedData)

				setRefresh(false)



			}
		});


	}, []);

	const [MOCK_DATA, setMOCK_DATA] = useState([]);
	const [refresh, setRefresh] = useState(true);
	const columns = useMemo(() => COLUMNS_2, [])
	const data = useMemo(() => MOCK_DATA)

	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
	const { globalFilter, pageIndex } = state


	return (
		<>


			<PageTitle activeMenu="Filtering" motherMenu="Table" />
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Lista appartamenti aggiunti</h4>
				</div>
				<div className="card-body">
					{refresh ?
						<Loader />
						:
						<div className="table-responsive">
							<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
							<table {...getTableProps()} className="table dataTable display">
								<thead>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map(column => (
												<th {...column.getHeaderProps()}>
													{column.render('Header')}

												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="" >

									{page.map((row) => {
										prepareRow(row)
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {


													if (cell.column.Header == "Id") {

														return <td {...cell.getCellProps()} style={{ color: 'green', fontWeight: 'bold' }}> {cell.render('Cell')} </td>

													} else if (cell.column.Header == "Azioni") {

														return <td {...cell.getCellProps()} > {cell.render('Cell')} </td>

													} else if (cell.column.Header == "Prenotazione Diretta") {
														return <td {...cell.getCellProps()} > {cell.value == true ? "Si" : "No"} </td>

													}

													else {
														return <td {...cell.getCellProps()} > {cell.render('Cell')} </td>
													}


												})}
											</tr>
										)
									})}
								</tbody>
							</table>
							<div className="d-flex justify-content-between">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<span className="table-index">
									Go to page : {' '}
									<input type="number"
										className="ml-2"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(pageNumber)
										}}
									/>
								</span>
							</div>
							<div className="text-center mb-3">
								<div className="filter-pagination  mt-3">
									<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

									<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
										Previous
									</button>
									<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
										Next
									</button>
									<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
								</div>
							</div>

						</div>
					}

				</div>
			</div>
		</>
	)

}
export default VisualizzaAppartamenti;