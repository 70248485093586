import moment from "moment";

const filtraPrenotazioniByStato = (entries, modeOrdinamento) => {

    let listaPrenotazioniCorrentiOrdinate = []
    for (var i = entries.length - 1; i >= 0; i--) {
        var rimosso = entries.splice(i, 1);
        listaPrenotazioniCorrentiOrdinate.push(rimosso[0])
    }

    if(modeOrdinamento) {
        listaPrenotazioniCorrentiOrdinate.sort(function (a, b) {
            return a['statoFiltro'].localeCompare(b['statoFiltro']);
    
        });
    } else {
        listaPrenotazioniCorrentiOrdinate.sort(function (a, b) {
            return b['statoFiltro'].localeCompare(a['statoFiltro']);
    
        });
    }

    return listaPrenotazioniCorrentiOrdinate;
}

const filtraPrenotazioniByAppartamento = (entries, modeOrdinamento) => {

    let listaPrenotazioniCorrentiOrdinate = []
    for (var i = entries.length - 1; i >= 0; i--) {
        var rimosso = entries.splice(i, 1);
        listaPrenotazioniCorrentiOrdinate.push(rimosso[0])
    }

    if(modeOrdinamento) {
        listaPrenotazioniCorrentiOrdinate.sort(function (a, b) {
            return a['appartamento'].localeCompare(b['appartamento']);
        });
    } else {
        listaPrenotazioniCorrentiOrdinate.sort(function (a, b) {
            return b['appartamento'].localeCompare(a['appartamento']);
        });
    }

    return listaPrenotazioniCorrentiOrdinate;
}

const filtraPrenotazioniByCheckin = (entries) => {

    let dataOdierna = moment(new Date()).utc().unix() * 1000;
    let start = '';
    let stop = '';
    let listaPrenotazioniCorrentiOrdinate = []
    for (var i = entries.length - 1; i >= 0; i--) {
        start = moment(entries[i]['start']).utc().unix() * 1000;
        stop = moment(entries[i]['stop']).utc().unix() * 1000;
        if ((entries[i]['start'] <= dataOdierna && dataOdierna <= entries[i]['end'])) {
            var rimosso = entries.splice(i, 1);
            listaPrenotazioniCorrentiOrdinate.push(rimosso[0])
        }
    }

    listaPrenotazioniCorrentiOrdinate.sort(function (a, b) {
        return a['start'] - b['start'];
    });

    let listaPrenotazioniFutureOrdinateFuture = []
    for (var i = entries.length - 1; i >= 0; i--) {
        if ((dataOdierna <= entries[i]['start'] && dataOdierna <= entries[i]['end']) && (entries[i]['statoFiltro'] !== "TERMINATA")) {
            var rimosso = entries.splice(i, 1);
            listaPrenotazioniFutureOrdinateFuture.push(rimosso[0])

        }
    }

    listaPrenotazioniFutureOrdinateFuture.sort(function (a, b) {
        return a['start'] - b['start'];
    });

    listaPrenotazioniCorrentiOrdinate.push(...listaPrenotazioniFutureOrdinateFuture)

    let listaPrenotazioniTerminate = []
    for (var i = entries.length - 1; i >= 0; i--) {
        if (entries[i]['statoFiltro'] === "TERMINATA") {
            var rimosso = entries.splice(i, 1);
            listaPrenotazioniTerminate.push(rimosso[0])
        }
    }

    listaPrenotazioniTerminate.sort(function (a, b) {
        return a['start'] - b['start'];
    });

    listaPrenotazioniCorrentiOrdinate.push(...listaPrenotazioniTerminate)

    return listaPrenotazioniCorrentiOrdinate;
}

export {
    filtraPrenotazioniByAppartamento,
    filtraPrenotazioniByCheckin,
    filtraPrenotazioniByStato
};