import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from "sweetalert";
import { callLambda } from '../../../../../API/api'
import apiConfig from '../../../../../API/apiConfig'
import env from '../../../../../envConfig'

const VisualizzaFoto = (props) => {

    const [contacts, setContacts] = useState(props.photo);

    useEffect(() => {
        setContacts(props.photo)
    }, []);    // delete data  


    const [editModal, setEditModal] = useState(false);

    // Edit function editable page loop
    const [editContactId, setEditContactId] = useState(null);

    // Edit function button click to edit
    const handleEditClick = (event, contact) => {
        event.preventDefault();
        //console.log("contact", contact)
        setEditContactId(contact.id);
        const formValues = {
            tag: contact.tag,
            title: contact.title,
            foto: contact.foto,
        }
        setEditFormData(formValues);
        setEditModal(true);
    };


    // edit  data  
    //ci sono solo tag e title perché sono gli unici due campi che modifico
    const [editFormData, setEditFormData] = useState({
        tag: '',
        title: ''
    })

    //update data function
    const handleEditFormChange = (event) => {
        event.preventDefault();
        //console.log("QUI ATTIVATO")
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;
        //console.log("newFormData", newFormData)
        setEditFormData(newFormData);
    };


    // edit form data submit
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
        const editedContact = {
            id: editContactId,
            tag: editFormData.tag,
            title: editFormData.title,
            foto: editFormData.foto,
        }
        //console.log("editedContact", editedContact)
        const newContacts = [...contacts];
        //console.log("newContacts",newContacts)
        const index = contacts.findIndex((contact) => contact.id === editContactId);
        newContacts[index] = editedContact;
        setEditContactId(null);
        setEditModal(false);
    }

    return (
        <>
            <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head">
                <Modal className="modal fade" show={editModal && props.editModal} onHide={setEditModal} >
                    <div className="" role="document">
                        <div className="">
                            <form >
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">Modifica questi campi</h4>
                                    <button type="button" className="btn-close" onClick={() => setEditModal(false)} data-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">Intestazione</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control" autoComplete="off"
                                                        name="tag" required="required"
                                                        value={editFormData.tag}
                                                        onChange={handleEditFormChange}
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">Descrizione</label>
                                                <div className="contact-occupation">
                                                    <input type="text" autoComplete="off"
                                                        value={editFormData.title}
                                                        onChange={handleEditFormChange}
                                                        name="title" required="required"
                                                        className="form-control" placeholder="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary" onClick={handleEditFormSubmit}>Conferma</button>
                                    <button type="button" onClick={() => setEditModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Annulla</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </Modal>
            </div>
            <div className="row">
                {contacts.map((contact, index) => (
                    <div className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={index}>
                        <div className="card project-boxed">
                            <div className="img-bx">
                                <img src={contact.foto} alt="" className=" me-3 card-list-img w-100" width="50" />
                            </div>
                            {props.editModal &&
                                <div>

                                    < div className="card-header align-items-start">

                                        <div style={{ textAlign: 'center', margin: '0 auto' }}>
                                            <p className="fs-14 mb-2 text-primary">{contact.id}</p>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={(event) => handleEditClick(event, contact)}
                                            > Modifica le descrizioni di questa foto </button>
                                        </div>


                                    </div>

                                    <div className="card-body p-0 pb-3">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <span className="mb-0 title">Intestazione: </span>
                                                <span className="text-black ms-2">{contact.tag}</span>
                                            </li>
                                            <li className="list-group-item">
                                                <span className="mb-0 title">Descrizione: </span>
                                                <span className="text-black ms-2">{contact.title}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default VisualizzaFoto;