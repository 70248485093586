import { preventDefault } from "@fullcalendar/react";
import React from "react";
import MultiCheckBox from "../components/MultiCheckBox";
const StepThree = (props) => {

   const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;

      props.setPoliticheCancellazione(value)

   }

   

   return (
      <section>

         <div className="col-lg-12 mb-2">
            

            <div className="col-12 col-sm-12">
               <div className="card h-auto" >
                  <div className="card-header">
                     <h4 className="card-title">Seleziona i Servizi per questo appartamento</h4>
                  </div>
                  <div className="card-body pt-0">
                     <MultiCheckBox checkedState={props.checkedState} setCheckedState={props.setCheckedState}  >

                     </MultiCheckBox>
                  </div>
               </div>
            </div>
         </div>

{/*
         <div className="form-group mb-3">
               <label className="text-label" style={{ fontWeight: "bolder" , fontSize: "1.8em", color: "black" }}>Politiche cancellazione  </label>
               <input
                  type="text"
                  name="Rules"
                  className="form-control  border border-primary"
                  placeholder="Inserisci le politiche di cancellazione per quest'appartamento"
                  onChange={handleChange}
                  value={props.politicheCancellazione}
               />
            </div>

*/}
      </section >
   );
};

export default StepThree;
