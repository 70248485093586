import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';

export const COLUMNS_2 = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Nome',
		Footer : 'First Name',
		accessor: 'nome',
		Filter: ColumnFilter,
	},
	{
		Header : 'Prenotazione Diretta',
		Footer : 'prenotazioneDiretta',
		accessor: 'prenotazioneDiretta',
		Filter: ColumnFilter,
	},
	{
		Header : 'Numero massimo di persone',
		Footer : 'Numero massimo di persone',
		accessor: 'personeMax',
		Filter: ColumnFilter,
	},
	{
		Header : 'Azioni',
		Footer : 'Bottoni',
		accessor: 'azioni',
		Filter: ColumnFilter,
	},
	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]