import * as React from "react"

const IconMoneySVG = (props) => (
  <svg
    height={21}
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#ffebbe",
      }}
      d="M344.276 512H167.724c-48.754 0-88.276-39.522-88.276-88.276V317.793c0-97.506 79.044-176.552 176.552-176.552 97.506 0 176.552 79.044 176.552 176.552v105.931c0 48.754-39.523 88.276-88.276 88.276z"
    />
    <path
      style={{
        fill: "#a0c88c",
      }}
      d="M291.31 331.386v-51.654c13.614 5.231 22.069 13.071 22.069 20.405 0 7.31 5.927 13.241 13.241 13.241 7.315 0 13.241-5.931 13.241-13.241 0-21.684-19.649-39.926-48.552-48.2v-9.18c0-7.31-5.927-13.241-13.241-13.241-7.315 0-13.241 5.931-13.241 13.241v4.693a140.068 140.068 0 0 0-17.656 0v-4.693c0-7.31-5.927-13.241-13.241-13.241s-13.241 5.931-13.241 13.241v9.18c-28.903 8.274-48.552 26.517-48.552 48.2 0 21.683 19.649 39.926 48.552 48.2v51.654c-13.614-5.231-22.069-13.071-22.069-20.405 0-7.31-5.927-13.241-13.241-13.241s-13.241 5.931-13.241 13.241c0 21.684 19.649 39.926 48.552 48.2v9.18c0 7.31 5.927 13.241 13.241 13.241s13.241-5.931 13.241-13.241v-4.693a140.068 140.068 0 0 0 17.656 0v4.693c0 7.31 5.927 13.241 13.241 13.241 7.315 0 13.241-5.931 13.241-13.241v-9.18c28.903-8.274 48.552-26.517 48.552-48.2 0-21.683-19.649-39.926-48.552-48.2zM256 273.655c3.017 0 5.963.12 8.828.344v52.9a140.068 140.068 0 0 0-8.828-.279c-3.017 0-5.963-.12-8.828-.344v-52.277c2.865-.224 5.811-.344 8.828-.344zm-57.379 26.483c0-7.333 8.455-15.175 22.069-20.405v40.811c-13.615-5.232-22.069-13.073-22.069-20.406zM256 406.069c-3.017 0-5.963-.12-8.828-.344v-52.9c2.898.183 5.842.279 8.828.279 3.017 0 5.963.12 8.828.344v52.277c-2.865.223-5.811.344-8.828.344zm35.31-6.078V359.18c13.614 5.231 22.069 13.071 22.069 20.405 0 7.333-8.454 15.176-22.069 20.406z"
    />
    <path
      style={{
        fill: "#ffebbe",
      }}
      d="M256 132.367c-34.127 0-61.793-27.666-61.793-61.793V8.837c0-6.337 6.479-10.611 12.305-8.114l46.01 19.719a8.834 8.834 0 0 0 6.955 0L305.487.723c5.826-2.497 12.306 1.775 12.306 8.114v61.737c0 34.128-27.666 61.793-61.793 61.793z"
    />
    <path
      style={{
        fill: "#f5dcaa",
      }}
      d="M256 141.198a8.881 8.881 0 0 1-3.932-.922c-2.182-1.086-21.578-11.706-31.116-49.906a8.82 8.82 0 0 1 6.426-10.698c4.694-1.19 9.526 1.689 10.702 6.422 5.035 20.163 13.086 30.508 17.927 35.181 4.793-4.638 12.866-14.983 17.909-35.181 1.185-4.724 6.008-7.612 10.702-6.422a8.82 8.82 0 0 1 6.427 10.698c-9.539 38.198-28.936 48.819-31.116 49.906a8.862 8.862 0 0 1-3.929.922z"
    />
    <path
      style={{
        fill: "#f5dcaa",
      }}
      d="M282.478 176.5a8.796 8.796 0 0 1-6.241-2.586l-20.242-20.242-20.242 20.242c-3.448 3.448-9.035 3.448-12.483 0s-3.448-9.035 0-12.483l26.483-26.483a8.825 8.825 0 0 1 12.483 0l26.483 26.483a8.825 8.825 0 0 1 0 12.483 8.796 8.796 0 0 1-6.241 2.586z"
    />
    <path
      style={{
        fill: "#b9785f",
      }}
      d="M286.896 150.069h-61.793c-7.313 0-13.241-5.929-13.241-13.241 0-7.313 5.929-13.241 13.241-13.241h61.793c7.313 0 13.241 5.929 13.241 13.241.001 7.312-5.928 13.241-13.241 13.241z"
    />
  </svg>
)

export default IconMoneySVG
