import React, { Component } from "react";
import { Col, Row, Card } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import { roundWithPrecision } from "chartist";
import uuid from 'react-native-uuid';
import { th } from "date-fns/locale";
import { compose } from "redux";
import { useEffect, useState } from "react";

//import esLocale from '@fullcalendar/core/locales/es';

import 'moment/locale/it';


const Swal = require('sweetalert2')
const met = "0"
var moment = require('moment'); // require
moment().format();






//Contiene gli id da sovrascrivere
var cono = []



function getDates(startDate, stopDate, flag) {
   var dateArray = [];
   var currentDate = moment(startDate);
   var stopDate = moment(stopDate);
   while (currentDate < stopDate) {
      if (flag == "true") {
         dateArray.push(moment(currentDate).format('DD-MM-YYYY'))

      } else {

         dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      }

      currentDate = moment(currentDate).add(1, 'days');
   }
   return dateArray;
}




class EventCalendar extends Component {

   
 


   state = {
      flag: "false",
      calendarEvents: [
         { title: "", start: "" }
      ],
      events: [
         { title: "", id: "" },

      ],
   };




   /**
    * adding dragable properties to external events through javascript
    */


    componentDidMount() {
      // Wait for the status to change

    }
  
    /*
    componentDidUpdate(prevProps, prevState) {
      // Wait for the status to change

   }

   /*
   componentDidUpdate(prevProps, prevState) {
     // Wait for the status to change
     if (prevState.flag === 'false' && this.state.flag === 'true') {
       console.log('The status has changed!');
     }
   }
 
   componentWillUnmount() {
     console.log('The component will be unmounted');
   }
 */







  


   remove_event_on_calendar = (CodID) => {
      this.props.setcalEvents(
         this.props.calEvents.filter(item => (item.id != CodID))

      )
   }

   remove_price_deleted = (CodID) => {

      this.props.setPrice(
         this.props.price.filter(item => item.id != CodID)
      )

   }

   /**
    * when we click on event we are displaying event details
    */

   eventClick = (eventClick) => {
      console.log("event click", eventClick)
      Alert.fire({
         title: "Il prezzo scelto per questa giornata è " + eventClick.event.title + " Euro",
         showCancelButton: true,
         confirmButtonColor: "#d33",
         cancelButtonColor: "#3085d6",
         confirmButtonText: "Rimuovi prezzo",
         cancelButtonText: "Close",
      }).then((result) => {
         if (result.value) {
            console.log("risultato cancella", result)
            eventClick.event.remove(); // It will remove event from the calendar

            this.remove_event_on_calendar(eventClick.event._def.publicId)
            this.remove_price_deleted(eventClick.event._def.publicId)
            Alert.fire("Cancellato!", "Il prezzo è stato cancellato.", "OK");
            console.log("prova magica post cancellazione", this.props.price)
            console.log("eventi dopo la cancellazione", this.props.calEvents)
         }
      });
   };


   /* In questa funzione oltre ad attivare la sovrapposizione degli eventi, mi vado a prendere gli id degli eventi
   che vengono sovrascritti da altri, li metto in una variabile chiamata cono che poi verrà richiamata nel HandleSelect 
   per servirsi di questi id al fine di rimuovere prezzi ed eventi*/
   handleOverlap = arg => {



      if (cono.indexOf(arg._def.publicId) === -1) {
         cono.push(arg._def.publicId);
      }

      console.log("cono", cono)



      return true


   }

   handleSelectClick = arg => {



      console.log(this.props.price)

      //Step per trovarmi il numero di giorni selezionato
      // Parse the dates from string to Date object
      var date1 = new Date(arg.endStr);
      var date2 = new Date(arg.startStr);
      // Subtract the dates to get the difference in milliseconds
      var difference = date1 - date2;
      // Convert the difference from milliseconds to days
      var differenceInDays = difference / (1000 * 60 * 60 * 24);

      //Mi prendo le date che compongono il periodo mettendo false per prendere il formato originale
      var datearray = getDates(arg.startStr, arg.endStr, false)





      console.log("price aggiornato", this.props.price)
      console.log("argomento select", arg)

      const { value: price } = Swal.fire({
         title: "Inserisci il prezzo per il periodo da " + arg.startStr + " a " + arg.endStr,
         input: 'number',
         inputPlaceholder: "Es. 20.00 Euro",
         inputLabel: 'Inserisci il prezzo per il periodo selezionato',
         showCancelButton: true,
         showDenyButton: true,
         denyButtonText: "Rimuovi prezzo",
         cancelButtonText: "Annulla",

         inputValidator: (value) => {
            if (!value) {

               return 'Inserisci un prezzo!'

            }
         }
      }).then((result) => {
         console.log("risultato swal", result)

         if (result.value) {
            console.log("argomento della select", arg)
            //  this.props.setPrice(result.value)
            let CodID
            for (let i = 0; i < differenceInDays; i++) {
               CodID = uuid.v4()
               this.props.setcalEvents(
                  this.props.calEvents.concat({
                     title: result.value + " Euro",
                     start: datearray[i],
                     end: datearray[i],
                     allDay: arg.allDay,
                     id: CodID
                  })
               )

               this.props.price.push({ prezzo: result.value, date: datearray[i], id: CodID })

               // this.remove_event_on_calendar(localStorage.getItem("id"))


            }
            /*Rimuovo eventi e prezzi sovrascritti servendomi della variabile cono che ho riempito nell handleoverlap */
            for (let i = 0; i < cono.length; i++) {
               this.remove_event_on_calendar(cono[i])
               this.remove_price_deleted(cono[i])
            }
            cono.splice(0, cono.length)

            /*
            this.setState({
               calendarEvents: this.state.calendarEvents.concat({
                  title:result.value+" Euro",
                  start: arg.startStr,
                  end : arg.endStr,
                  allDay: arg.allDay
               })
            })
            */



            console.log("prova data prima", arg.startStr)
            console.log("eccole", getDates(arg.startStr, arg.endStr, true))



            /*
            var result = {};
            this.props.calEvents.forEach((key, i) => result[key] = this.props.price[i]);
            console.log("prova magica al quadrato",result)
            */

            console.log("prova magica", this.props.price)
            console.log("eventi all'inserimento", this.props.calEvents)



         } else if (result.isDenied) {
            for (let i = 0; i < cono.length; i++) {
               this.remove_event_on_calendar(cono[i])
               this.remove_price_deleted(cono[i])
            }
            cono.splice(0, cono.length)
         }
      });
   }

   handleEventAdd = arg => {
      console.log("ecco cosa succede a spostare un evento", arg)
   }

   /*
    handleDateClick = arg => {
 
      
       console.log("qui dentro")
       
       console.log("date click info",arg)
       
       const { value: price } =  Swal.fire({
       title: "Inserisci il prezzo per il giorno "+arg.dateStr ,
       input: 'number',
       inputPlaceholder : "Es. 20.00 Euro",
       inputLabel: 'Inserisci il prezzo per questo giorno',
       showCancelButton: true,
       
       inputValidator: (value) => {
          if (!value) {
             return 'You need to write something!'
          }
       }
       }).then((result) => {
          if (result.value) {
             //this.props.setPrice(result.value)
             this.setState({
                calendarEvents: this.state.calendarEvents.concat({
                   title:result.value+" Euro",
                   start: arg.date,
                   allDay: arg.allDay
                })
             })
            
             
             
          }
       });
       if (price) {
       Swal.fire(`Your IP address is ${price}`)
       }
 
       
 
 
      
          };
          */

   render() {
      return (
         <div className="animated fadeIn demo-app">
            <Row>


               <Col lg={12}>
                  <Card>
                     <Card.Body>
                        <div className="demo-app-calendar" id="mycalendartest">
                           <FullCalendar

                              defaultView="dayGridMonth"
                              header={{
                                 left: "prev,next today",
                                 center: "title",
                                 right:
                                    "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                              }}
                              rerenderDelay={10}
                              eventDurationEditable={false}
                              editable={false}
                              droppable={false}
                              Draggable={false}
                              plugins={[
                                 dayGridPlugin,
                                 timeGridPlugin,
                                 interactionPlugin,
                              ]}
                              ref={this.calendarComponentRef}
                              weekends={this.state.calendarWeekends}
                              events={this.props.calEvents}
                              eventDrop={this.drop}
                              // drop={this.drop}
                              eventReceive={this.eventReceive}
                              eventClick={this.eventClick}
                              selectable={true}
                              dateClick={this.handleDateClick}
                              select={this.handleSelectClick}
                              eventAdd={this.handleEventAdd}
                              longPressDelay={1}
                              selectOverlap={this.handleOverlap}
                              eventOverlap={true}
                              locale={moment.locale('it')}






                           />
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      );
   }
}

export default EventCalendar;
