import { set } from "date-fns";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const FacilitiesList = ({ titleName }) => {
	return (
		<>
			<Link to={"#"}>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="#78D69D" />
				</svg>
				{titleName}
			</Link>
		</>
	)
}

function ListaServiziPagamentoModifica(props) {

	const [rows, setRows] = useState([]);
	//const [servizioChecked, setServizioChecked] = useState([]);

	useEffect(() => {
		const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];


		let righe = []
		let colonne = []
		let servizi = []
		let count = 0

		console.log("servizi", Object.entries(props.servizi))
		console.log("allServizi", props.allServizi)


		let serviziPagamento = []

		for (let i = 0; i < props.allServizi.length; i++) {
			if (props.allServizi[i]['isPagamento']) {
				serviziPagamento.push(props.allServizi[i]['servizio'])
			}
		}

		console.log("serviziPagamento", serviziPagamento)
		console.log("Math.ceil(props.allServizi.length / 3)", Math.ceil(props.allServizi.length / 3))

		for (let i = 0; i < Math.ceil(serviziPagamento.length / 3); i++) {
			if (count === serviziPagamento.length) break
			righe.push(
				<div className="row">
					{colonne}
				</div>
			)
			for (let j = 0; j < 3; j++) {
				if (count === serviziPagamento.length) break
				let nomeServizio = ''
				let valoreNomeServizio = ''
				console.log("TEST: ", Object.entries(props.servizi))
				console.log("serviziPagamento[count]: ", serviziPagamento[count])
				for (let z = 0; z < Object.entries(props.servizi).length; z++) {
					if (Object.entries(props.servizi)[z][0] === serviziPagamento[count]) {
						nomeServizio = Object.entries(props.servizi)[z][0]
						valoreNomeServizio = Object.entries(props.servizi)[z][1]
					}
				}



				servizi.push(valoreNomeServizio)
				colonne.push(
					<div className="col-xl-4 col-4">
						<div className="form-check mb-2">
							<input
								type="checkbox"
								className="form-check-input"
								id={"check-" + nomeServizio}
								value={nomeServizio}
								name="serviziPagamento"
								onClick={props.onChangePrenotazione}
								defaultChecked={valoreNomeServizio}
							//checked={setServizioChecked(!servizioChecked[count])}
							/>
							<label className="form-check-label" htmlFor={nomeServizio}>
								{nomeServizio}
							</label>
						</div>
					</div>
				)
				count += 1
			}
			servizi = []
			colonne = []
			//setServizioChecked(servizi)

		}
		setRows(righe)
	}, []);

	return (
		<div>
			{rows}
		</div>
	)
}
export default ListaServiziPagamentoModifica;