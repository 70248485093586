import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Row, Card, Col, Button, Nav } from "react-bootstrap";
import ApartmentMap from "../ApartmentMap";


const StepFourModifica = (props) => {

   return (



      <Col xl="12">
           <Card>
                <Card.Body>
                
                    <section>

                    <ApartmentMap coordinates={props.coordinates} setCoordinates={props.setCoordinates}></ApartmentMap>

                   </section>
   
                </Card.Body>
                
              </Card>
       
      </Col>
   );

}

export default StepFourModifica;