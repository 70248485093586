import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";


import InsertFoto from "./foto-components/InsertFoto";

import UpdateFoto from "./foto-components/UpdateFoto";


import VisualizzaFoto from "./foto-components/VisualizzaFoto";

import 'antd/dist/antd.min.css'

import '../../../../css/spinner-manu.css'

import config from "../../../../config";
//import config from '../../../config';
import apiConfig from '../../../../API/apiConfig'
import env from '../../../../envConfig'

import { Spin } from 'antd';
const FotoAppartamento = (props) => {
    const [goSteps, setGoSteps] = useState(0);
    const [photo, setPhoto] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        console.log(props.vettoreFoto)
        if (props.vettoreFoto.length === 0) {
            buildFileList([])
            setPhoto([])
        } else {
            let paramsFoto = []
            let vettoreLinkFoto = props.vettoreFoto
            for (let i = 0; i < vettoreLinkFoto.length; i++) {

                paramsFoto.push({
                    foto: vettoreLinkFoto[i],
                    id: vettoreLinkFoto[i].split(config.separatoreFoto)[1],
                    tag: '',
                    title: ''
                })
            }


            buildFileList(paramsFoto)
            setPhoto(paramsFoto)
            setRefresh(true)
        }


        setLoading(false)

    }, []);

    const buildFileList = (photo) => {
        let fileList = []
        for (let i = 0; i < photo.length; i++) {
            fileList.push({
                uid: photo[i].id,
                name: photo[i].id,
                foto: photo[i].foto
            })
        }
        //console.log(fileList)
        setFileList(fileList)
    }


    const insertFotoInPhotoState = (vettoreLinkFoto) => {
        console.log("vettoreLinkFoto", vettoreLinkFoto)
        props.setVettoreFoto(vettoreLinkFoto)
        let paramsFoto = []

        for (let i = 0; i < vettoreLinkFoto.length; i++) {

            paramsFoto.push({
                foto: vettoreLinkFoto[i],
                id: vettoreLinkFoto[i].split(config.separatoreFoto)[1],
                tag: '',
                title: ''
            })
        }


        buildFileList(paramsFoto)
        setPhoto(paramsFoto)
        setRefresh(true)
    }

    const setPhotoFromChild = (val) => {
        setRefresh(false)

        insertFotoInPhotoState(val)

    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <Card>
                        <Card.Header>
                            <Card.Title>Gestione delle foto per un nuovo appartamento</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {loading ?
                                <div>
                                    <Spin style={{}} />

                                </div>

                                :
                                <>

                                    {photo.length == 0 ?

                                        <div>
                                            <h2>

                                                Aggiungi nuove foto per l'appartamento

                                            </h2>

                                            <InsertFoto setPhoto={setPhotoFromChild} />

                                        </div>

                                        :

                                        <div>

                                            <h2>
                                                Modifica le foto inserite
                                            </h2>
                                            {refresh === true ?
                                                <div>
                                                    <UpdateFoto
                                                        fileList={fileList}
                                                        setPhoto={setPhotoFromChild}

                                                    />
                                                    <Card.Body>

                                                        < VisualizzaFoto
                                                            photo={photo}
                                                            editModal={false}
                                                        />

                                                    </Card.Body>
                                                </div>
                                                :
                                                <></>
                                            }

                                        </div>

                                    }


                                </>
                            }

                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default FotoAppartamento;
