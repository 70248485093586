import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Row, Card, Col, Button, Nav } from "react-bootstrap";

import EventCalendar from "../inserimento-appartamento/EventCalendar";





const StepTwoModifica = (props) => {
 




    return (
      <Row>
        <Col xl="12">
  
          <Card>
            <Card.Header>
              <Card.Title>Prezzi Appartamento </Card.Title>
            </Card.Header>
            <Card.Body>
              <section>
                <EventCalendar price={props.price} setPrice={props.setPrice} calEvents={props.calEvents} setcalEvents={props.setcalEvents} />
              </section>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );

  }
  

export default StepTwoModifica