import axios from "axios"
import config from "../config";
import apiConfig from "./apiConfig";
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import env from '../envConfig'

Amplify.configure({
    aws_project_region: env().aws_project_region,
    aws_cognito_identity_pool_id: env().aws_cognito_identity_pool_id,
    aws_cognito_region: env().aws_cognito_region,
    aws_user_pools_id: env().aws_user_pools_id,
    aws_user_pools_web_client_id: env().aws_user_pools_web_client_id,
    oauth: {},
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
})

const createToken = async () => {
    var data = await Auth.currentSession()
    const payloadHeader = {
        headers: {
            "Authorization": "Bearer " + data.idToken.jwtToken,
        },
    };
    return payloadHeader;
}

const callLambda = async function (params, callback) {
    //console.log(params)

    let queryParams, body, promiseAll, vettorePromiseAll = null
    let method = params.method
    let endpoint = params.endpoint

    if (params.queryParams !== undefined) {
        queryParams = params.queryParams
    } else {
        queryParams = ""
    }

    if (params.body !== undefined) {
        body = params.body
    }

    let authentication = await createToken();
    console.log("auth", authentication)
    if (params.promiseAll !== undefined) {
        promiseAll = params.promiseAll
        vettorePromiseAll = params.vettorePromiseAll
    }

    if (method === "GET") {
        axios.get(endpoint + queryParams, authentication)
            .then(response => {
                //console.log(response)

                return callback(response)

            }).catch(err => {
                console.log(err);
            })
    }

    else if (method == "POST") {
        if (!promiseAll) {
            axios.post(endpoint + queryParams, body, authentication)
                .then(response => {
                    //console.log(response)
                    return callback(response)

                }).catch(err => {
                    console.log(err);
                })
        }
        else {
            let allAxios = []
            for (let i = 0; i < vettorePromiseAll.length; i++) {
                if (!Array.isArray(endpoint)) {
                    allAxios.push(axios.post(endpoint, vettorePromiseAll[i], authentication))
                } else {
                    allAxios.push(axios.post(endpoint[i], vettorePromiseAll[i], authentication))
                }
            }

            Promise.all(allAxios).then(function (response) {
                //console.log("dentro allAxios");
                //console.log(response);
                callback(response)
            }).catch(err => {
                console.log(err);
            })
        }

    } else if (method == "PUT") {
        if (!promiseAll) {
            axios.put(endpoint + queryParams, body, authentication)
                .then(response => {
                    //console.log(response)
                    return callback(response)

                }).catch(err => {
                    console.log(err);
                })
        } else {
            let allAxios = []
            for (let i = 0; i < vettorePromiseAll.length; i++) {
                if (!Array.isArray(endpoint)) {
                    allAxios.push(axios.poputst(endpoint, vettorePromiseAll[i], authentication))
                } else {
                    allAxios.push(axios.put(endpoint[i], vettorePromiseAll[i], authentication))
                }
            }

            Promise.all(allAxios).then(function (response) {
                //console.log("dentro allAxios");
                //console.log(response);
                callback(response)
            }).catch(err => {
                console.log(err);
            })
        }
    } else if (method == "DELETE") {
        if (!promiseAll) {
            axios.delete(endpoint + queryParams, { data: body }, authentication)
                .then(response => {
                    //console.log(response)
                    return callback(response)

                }).catch(err => {
                    console.log(err);
                })
        }
        else {
            let allAxios = []
            for (let i = 0; i < vettorePromiseAll.length; i++) {
                if (!Array.isArray(endpoint)) {
                    allAxios.push(axios.delete(endpoint, { data: vettorePromiseAll[i] }, authentication))
                } else {
                    allAxios.push(axios.delete(endpoint[i], { data: vettorePromiseAll[i] }, authentication))
                }
            }

            Promise.all(allAxios).then(function (response) {
                //console.log("dentro allAxios");
                //console.log(response);
                callback(response)
            }).catch(err => {
                console.log(err);
            })
        }
    }
}

/*

const callLambda = async function (params, callback) {
    //console.log(params)

    let queryParams, body, promiseAll, vettorePromiseAll = null
    let method = params.method
    let endpoint = params.endpoint

    if (params.queryParams !== undefined) {
        queryParams = params.queryParams
    } else {
        queryParams = ""
    }

    if (params.body !== undefined) {
        body = params.body
    }

    let authentication = await createToken();
    console.log("auth", authentication)
    if (params.promiseAll !== undefined) {
        promiseAll = params.promiseAll
        vettorePromiseAll = params.vettorePromiseAll
    }

    if (method === "GET") {
        axios.get(endpoint + queryParams)
            .then(response => {
                //console.log(response)

                return callback(response)

            }).catch(err => {
                console.log(err);
            })
    }

    else if (method == "POST") {
        if (!promiseAll) {
            axios.post(endpoint + queryParams, body)
                .then(response => {
                    //console.log(response)
                    return callback(response)

                }).catch(err => {
                    console.log(err);
                })
        }
        else {
            let allAxios = []
            for (let i = 0; i < vettorePromiseAll.length; i++) {
                if (!Array.isArray(endpoint)) {
                    allAxios.push(axios.post(endpoint, vettorePromiseAll[i]))
                } else {
                    allAxios.push(axios.post(endpoint[i], vettorePromiseAll[i]))
                }
            }

            Promise.all(allAxios).then(function (response) {
                //console.log("dentro allAxios");
                //console.log(response);
                callback(response)
            }).catch(err => {
                console.log(err);
            })
        }

    } else if (method == "PUT") {
        if (!promiseAll) {
            axios.put(endpoint + queryParams, body)
                .then(response => {
                    //console.log(response)
                    return callback(response)

                }).catch(err => {
                    console.log(err);
                })
        } else {
            let allAxios = []
            for (let i = 0; i < vettorePromiseAll.length; i++) {
                if (!Array.isArray(endpoint)) {
                    allAxios.push(axios.poputst(endpoint, vettorePromiseAll[i]))
                } else {
                    allAxios.push(axios.put(endpoint[i], vettorePromiseAll[i]))
                }
            }

            Promise.all(allAxios).then(function (response) {
                //console.log("dentro allAxios");
                //console.log(response);
                callback(response)
            }).catch(err => {
                console.log(err);
            })
        }
    } else if (method == "DELETE") {
        if (!promiseAll) {
            axios.delete(endpoint + queryParams, { data: body })
                .then(response => {
                    //console.log(response)
                    return callback(response)

                }).catch(err => {
                    console.log(err);
                })
        }
        else {
            let allAxios = []
            for (let i = 0; i < vettorePromiseAll.length; i++) {
                if (!Array.isArray(endpoint)) {
                    allAxios.push(axios.delete(endpoint, { data: vettorePromiseAll[i] }))
                } else {
                    allAxios.push(axios.delete(endpoint[i], { data: vettorePromiseAll[i] }))
                }
            }

            Promise.all(allAxios).then(function (response) {
                //console.log("dentro allAxios");
                //console.log(response);
                callback(response)
            }).catch(err => {
                console.log(err);
            })
        }
    }
}

*/

const callLambdaS3 = async function (params, callback) {
    //console.log(params)

    let queryParams, body, promiseAll, vettorePromiseAll = null
    let method = params.method
    let endpoint = params.endpoint

    if (params.queryParams !== undefined) {
        queryParams = params.queryParams
    } else {
        queryParams = ""
    }

    if (params.body !== undefined) {
        body = params.body
    }

    if (params.promiseAll !== undefined) {
        promiseAll = params.promiseAll
        vettorePromiseAll = params.vettorePromiseAll
    }

    if (method == "PUT") {
        if (!promiseAll) {
            axios.put(endpoint + queryParams, body)
                .then(response => {
                    //console.log(response)
                    return callback(response)

                }).catch(err => {
                    console.log(err);
                })
        } else {
            let allAxios = []
            for (let i = 0; i < vettorePromiseAll.length; i++) {
                if (!Array.isArray(endpoint)) {
                    allAxios.push(axios.poputst(endpoint, vettorePromiseAll[i]))
                } else {
                    allAxios.push(axios.put(endpoint[i], vettorePromiseAll[i]))
                }
            }

            Promise.all(allAxios).then(function (response) {
                //console.log("dentro allAxios");
                //console.log(response);
                callback(response)
            }).catch(err => {
                console.log(err);
            })
        }

    }

}

export {
    callLambda,
    callLambdaS3
}
