import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import ApartmentWizard from "./pages/Appartamenti/ApartmentWizard";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";


import FotoHome from "./pages/FotoHome/FotoHome"
import InserisciPrenotazione from "./pages/Prenotazioni/InserisciPrenotazione";
import VisualizzaPrenotazione from "./pages/Prenotazioni/ListaPrenotazioni";
import PrenotazioneDetails from "./pages/Prenotazioni/PrenotazioneDetails";
import AppartamentoDettagli from "./pages/Appartamenti/AppartamentoDettagli";
import VisualizzaAppartamenti from "./pages/Appartamenti/VisualizzaAppartamenti";
import ApartmentWizardServizi from "./pages/SezioneVendita/ApartmentWizardServizi";
import Sconti from "./pages/Sconti/Sconti";
import SimulatoreSconti from "./pages/Sconti/SimulatoreSconti";
/* QUI VENGONO INSERITE LE ROTTE */

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [

    //ROTTE MIEEEEE:

    { url: "foto-home", component: FotoHome },
    { url: "nuova-prenotazione", component: InserisciPrenotazione },
    { url: "lista-prenotazioni", component: VisualizzaPrenotazione },
    { url: "vendita", component: ApartmentWizardServizi },
    { url: "prenotazione-details/:idRoom/:idPrenotazione", component: PrenotazioneDetails },
    { url: "appartamento-details/:NomeAppartamento/:idAppartamento", component: AppartamentoDettagli },
    { url: "inserisci-sconti", component: Sconti },
    { url: "simulatore-sconti", component: SimulatoreSconti },


    /// Dashboard
    { url: "", component: FotoHome },

    { url: "nuovo-appartamento", component: ApartmentWizard },


    { url: "lista-appartamenti", component: VisualizzaAppartamenti },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
      <ScrollToTop />
    </>
  );
};

export default Markup;
