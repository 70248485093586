import React from "react";

import EventCalendar from "./components/EventCalendar";

import PageTitle from "../../layouts/PageTitle";

const Sconti = () => {
    return (
        <div className="h-80">
            <PageTitle activeMenu="Sconti" motherMenu="Gestisci i tuoi sconti" />

            <EventCalendar />
        </div>
    );
};

export default Sconti;
