import React from "react";
import ListaServizi from "./ListaServizi";
import ListaServiziPagamento from "./ListaServiziPagamento";
import moment from "moment";
import IconMoneySVG from "./components/IconMoneySVG";
import IconMasterCardSVG from "./components/IconMasterCardSVG";
import 'moment/locale/it';
moment.locale('it')

function PagamentoModifica(props) {
	return (
		<div className="card-body pt-0">
			<div className="row" style={{ width: '100%' }}>
				<p className="fs-12"></p>
				<p className="fs-12">Prezzo e acconto</p>
				<div className="col-sm-6 col-12">
					<div className="d-flex">
						<input
							type="number" className="form-control"
							autoComplete="off"
							name="prezzo"
							defaultValue={props.prenotazione.prezzo}
							onChange={props.onChangePrenotazione}
							placeholder={"Inserire un prezzo.."}
							style={{ marginBottom: "15px" }}
						/>
					</div>
				</div>
				<div className="col-sm-6 col-12">
					<div className="d-flex">
						<input
							type="number" className="form-control"
							autoComplete="off"
							name="acconto"
							defaultValue={props.prenotazione.acconto}
							onChange={props.onChangePrenotazione}
							placeholder={"Inserire un acconto.."}
							style={{ marginBottom: "15px" }}
						/>
					</div>
				</div>
				<p className="fs-12">Deposito cauzionale</p>
				<div className="col-sm-12 col-12">
					<div className="d-flex">
						<input
							type="number" className="form-control"
							autoComplete="off"
							name="cauzione"
							defaultValue={props.prenotazione.cauzione}
							onChange={props.onChangePrenotazione}
							placeholder={"Inserire un deposito cauzionale"}
							style={{ marginBottom: "15px" }}
						/>
					</div>
				</div>
			</div>
		</div >
	)
}
export default PagamentoModifica;