import { format } from 'date-fns';
import moment from 'moment';
import { ColumnFilter } from './ColumnFilter';

export const COLUMNS = [
	{
		Header: 'Giorno',
		Footer: 'giorno',
		accessor: 'giorno',
		Filter: ColumnFilter,
		Cell: ({ value }) => { return <div style={{ width: "max-content" }}> {value} </div> },

		//disableFilters: true,
	},
	{
		Header: 'Prezzo',
		Footer: 'prezzo',
		accessor: 'prezzo',
		Filter: ColumnFilter,
		Cell: ({ value }) => { return <div style={{ width: "max-content" }}> {value} </div> },

		//disableFilters: true,
	},
	{
		Header: 'Sconto',
		Footer: 'sconto',
		accessor: 'sconto',
		Filter: ColumnFilter,
		Cell: ({ value }) => { return <div style={{ width: "max-content" }}> {value} </div> },

		//disableFilters: true,
	},
]