import React, { useEffect, useState } from "react";
import moment from "moment";
import env from "../../../../envConfig";
import { callLambda } from "../../../../API/api";
import apiConfig from "../../../../API/apiConfig";
import Loader from "../../../components/Loader";
import VisualizzaPrenotazioniTable from "./lista-table-scontistica/FilteringTable/VisualizzaPrenotazioniTable";
import { checkErroreSimulatoreSconti } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const SimulatoreScontiComponent = () => {

    const [rooms, setRooms] = useState([]);
    const [scontistica, setScontistica] = useState([]);
    const [periodi, setPeriodi] = useState([]);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [appartamento, setAppartamento] = useState('');
    const [refresh, setRefresh] = useState(true);
    const [refreshTable, setRefreshTable] = useState(false);
    const [periodo, setPeriodo] = useState([]);
    const [prezzoUndefined, setPrezzoUndefined] = useState(false);
    const [scontoUndefined, setScontoUndefined] = useState(false);
    const [prezzoFinale, setPrezzoFinale] = useState(0);
    const [scontoFinale, setScontoFinale] = useState(0);
    const [listaFinalePrezziSconti, setListaFinalePrezziSconti] = useState([]);

    useEffect(() => {

        let filtro = 'nome,id,prezzi'
        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.getRooms,
            method: "GET",
            queryParams: "?filtro=" + filtro
        }

        let rooms = []
        callLambda(paramsAxios, res => {
            console.log("[" + paramsAxios['endpoint'] + "]", res)
            if (res.data.esito === "OK") {
                res.data.risultato.map((room) => {
                    rooms.push(room);
                });

                setRooms(rooms);
                setRefresh(false)

            } else {

            }
        });

    }, []);

    const changeAppartamento = (event) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        console.log("fieldName", fieldName)
        console.log("fieldValue", fieldValue)
        setAppartamento(fieldValue)
    }

    const onChangeDate = (event) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        console.log("fieldName", fieldName)
        if (fieldName === "start") {
            setStart(fieldValue)
        } else {
            setEnd(fieldValue)
        }
    }

    const enumerateDaysBetweenDates = function (startDate, endDate) {
        var dates = [];
        var currDate = startDate.startOf('day');
        var lastDate = endDate.startOf('day');
        dates.push(moment(currDate).valueOf());
        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            let dateToPush = moment(currDate).format('YYYY-MM-DD')
            dates.push(moment(dateToPush).valueOf());
        }
        return dates;
    };


    const notifyBottomLeft = (message) => {
        toast.error("❌ " + message + "!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    };

    const conferma = () => {

        let trovatoErrore = checkErroreSimulatoreSconti(appartamento, start, end)

        if (trovatoErrore['valore'] === true) {
            notifyBottomLeft(trovatoErrore['message'])
        }
        //passati i controlli parte l'operazione di chiamata alla axios ed aggiorno il componente
        else {
            let filtro = 'periodi,scontistica'
            let paramsAxios = {
                endpoint: env().API_ENDPOINT_URL + apiConfig.getSconti,
                method: "GET",
                queryParams: "?anno=" + moment().year() + "&filtro=" + filtro
            }
            setRefreshTable(true)
            callLambda(paramsAxios, res => {
                console.log("[" + paramsAxios['endpoint'] + "]", res)
                if (res.data.esito === "OK") {

                    console.log("res", res)
                    let scontistica = []
                    let periodi = []

                    if (res.data.risultato.periodi !== undefined) {
                        periodi = res.data.risultato.periodi;
                    }

                    if (res.data.risultato.scontistica !== undefined) {
                        scontistica = res.data.risultato.scontistica;
                    }


                    setScontistica(scontistica)
                    setPeriodi(periodi)

                    console.log("start", start)
                    console.log("scontistica", scontistica)
                    console.log("periodi", periodi)
                    console.log("end", end)
                    console.log("appartamento", appartamento)

                    let json = {}
                    //ritorna la risposta
                    let periodo = enumerateDaysBetweenDates(moment(start), moment(end))
                    console.log("periodo", periodo)
                    rooms.map((room) => {
                        if (room['id'] == appartamento) {
                            console.log("qui")

                            periodo.map((prezzo) => {
                                console.log("room[prezzo]", room['prezzi'][prezzo])

                                if (room['prezzi'][prezzo] !== undefined) {
                                    json[moment(prezzo).format('YYYY-MM-DD')] = room['prezzi'][prezzo]
                                } else {
                                    json[moment(prezzo).format('YYYY-MM-DD')] = undefined
                                }
                            })
                        }
                    })
                    //

                    let durataPeriodo = periodo.length;
                    let indexSconto = undefined
                    console.log("durataPeriodo", durataPeriodo)
                    if (periodi.length !== 0) {
                        if (durataPeriodo >= 4 && durataPeriodo < 7) {
                            indexSconto = 0
                        } else if (durataPeriodo >= 7 && durataPeriodo < 14) {
                            indexSconto = 1
                        } else if (durataPeriodo >= 14 && durataPeriodo < 28) {
                            indexSconto = 2
                        } else if (durataPeriodo >= 28) {
                            indexSconto = 3
                        }
                    } else {
                        console.log("non ci sono periodi! ALERT!")
                    }

                    console.log("indexSconto", indexSconto)
                    let jsonScontato = {}

                    for (let i = 0; i < periodo.length; i++) {
                        periodo[i] = moment(periodo[i]).format('YYYY-MM-DD')
                        if (scontistica[periodo[i]] !== undefined) {
                            for (let j = 0; j < periodi.length; j++) {
                                if (periodi[j]['id'] == scontistica[periodo[i]]) {
                                    if (indexSconto !== undefined) {
                                        jsonScontato[periodo[i]] = periodi[j].offerte[indexSconto]['sconto']
                                    } else {
                                        jsonScontato[periodo[i]] = 0
                                    }
                                }
                            }
                        } else {
                            jsonScontato[periodo[i]] = 0
                        }
                    }

                    setPeriodo(periodo)


                    console.log("jsonScontato", jsonScontato)
                    console.log("json", json)
                    console.log("Object.entries(json)", Object.entries(json))
                    let listaFinale = []
                    let prezzoFinale = 0
                    let prezzoFinaleScontato = 0
                    let giorno = ''
                    let prezzo = ''
                    let sconto = ''
                    let foundPrezzoUndefined = false
                    let foundPrezzoScontatoUndefined = false
                    for (let i = 0; i < Object.entries(json).length; i++) {
                        giorno = Object.entries(json)[i][0]
                        prezzo = Object.entries(json)[i][1]
                        sconto = jsonScontato[Object.entries(json)[i][0]]
                        listaFinale.push({
                            'giorno': giorno,
                            'prezzo': prezzo,
                            'sconto': sconto
                        })
                        if (prezzo !== undefined) {
                            prezzoFinale += Number(prezzo)
                            prezzoFinaleScontato += Number(prezzo) - (Number(prezzo) * (Number(sconto) / 100))
                        } else {
                            foundPrezzoUndefined = true
                        }
                    }


                    console.log("listaFinale", listaFinale)
                    setPrezzoUndefined(foundPrezzoUndefined)
                    setPrezzoFinale(prezzoFinale)
                    setScontoFinale(prezzoFinaleScontato)
                    setListaFinalePrezziSconti(listaFinale)
                    setRefreshTable(false)
                }

                else {

                }
            });

        }

    }

    return (
        <div className="row" id="capture">
            <div className="col-xl-12">
                <div className="card h-auto" >
                    <div className="card-body pt-0">

                        {refresh ?
                            <Loader />
                            :
                            <div className="row" style={{ width: '100%' }}>
                                <div className="col-sm-12 col-12" style={{ marginTop: "30px", marginBottom: "15px" }}>
                                    <div className="d-flex">
                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            style={{ marginTop: 10 }}
                                        >
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.6667 1.8V0.9C10.6667 0.4032 11.0649 0 11.5556 0C12.0462 0 12.4444 0.4032 12.4444 0.9V1.8C14.408 1.8 16 3.4119 16 5.4V14.4C16 16.3881 14.408 18 12.4444 18C9.90222 18 6.09689 18 3.55556 18C1.592 18 0 16.3881 0 14.4V5.4C0 3.4119 1.592 1.8 3.55556 1.8V0.9C3.55556 0.4032 3.95378 0 4.44444 0C4.93511 0 5.33333 0.4032 5.33333 0.9V1.8H10.6667ZM14.2222 9H1.77778V14.4C1.77778 15.3936 2.57333 16.2 3.55556 16.2H12.4444C13.4258 16.2 14.2222 15.3936 14.2222 14.4V9ZM12.4444 3.6V4.5C12.4444 4.9968 12.0462 5.4 11.5556 5.4C11.0649 5.4 10.6667 4.9968 10.6667 4.5V3.6H5.33333V4.5C5.33333 4.9968 4.93511 5.4 4.44444 5.4C3.95378 5.4 3.55556 4.9968 3.55556 4.5V3.6C2.57333 3.6 1.77778 4.4055 1.77778 5.4V7.2H14.2222V5.4C14.2222 4.4055 13.4258 3.6 12.4444 3.6Z" fill="#F66F4D" />
                                        </svg>
                                        <h5
                                            style={{ marginTop: "10px", marginRight: "10px", marginLeft: "7px" }}
                                        >Inizio</h5>
                                        <input
                                            type="date" className="form-control" autoComplete="off"
                                            name="start"
                                            onChange={onChangeDate}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-12" style={{ marginBottom: "15px" }}>
                                    <div className="d-flex">
                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            style={{ marginTop: 10 }}
                                        >
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.6667 1.8V0.9C10.6667 0.4032 11.0649 0 11.5556 0C12.0462 0 12.4444 0.4032 12.4444 0.9V1.8C14.408 1.8 16 3.4119 16 5.4V14.4C16 16.3881 14.408 18 12.4444 18C9.90222 18 6.09689 18 3.55556 18C1.592 18 0 16.3881 0 14.4V5.4C0 3.4119 1.592 1.8 3.55556 1.8V0.9C3.55556 0.4032 3.95378 0 4.44444 0C4.93511 0 5.33333 0.4032 5.33333 0.9V1.8H10.6667ZM14.2222 9H1.77778V14.4C1.77778 15.3936 2.57333 16.2 3.55556 16.2H12.4444C13.4258 16.2 14.2222 15.3936 14.2222 14.4V9ZM12.4444 3.6V4.5C12.4444 4.9968 12.0462 5.4 11.5556 5.4C11.0649 5.4 10.6667 4.9968 10.6667 4.5V3.6H5.33333V4.5C5.33333 4.9968 4.93511 5.4 4.44444 5.4C3.95378 5.4 3.55556 4.9968 3.55556 4.5V3.6C2.57333 3.6 1.77778 4.4055 1.77778 5.4V7.2H14.2222V5.4C14.2222 4.4055 13.4258 3.6 12.4444 3.6Z" fill="#F66F4D" />
                                        </svg>
                                        <h5
                                            style={{ marginTop: "10px", marginRight: "10px", marginLeft: "11px" }}
                                        >Fine</h5>
                                        <input
                                            type="date"
                                            className="form-control"
                                            autoComplete="off"
                                            name="end"
                                            onChange={onChangeDate}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-12" style={{ marginBottom: "15px" }}>
                                    <div className="d-flex">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            style={{ marginTop: 12 }}
                                        >
                                            <path d="M17.2917 9.37501H2.70833C2.25 9.37501 1.875 9.00001 1.875 8.54168V2.54168C1.875 1.25001 2.91667 0.208344 4.20833 0.208344H15.7917C17.0833 0.208344 18.125 1.25001 18.125 2.54168V8.54168C18.125 9.00001 17.75 9.37501 17.2917 9.37501ZM3.5 7.75001H16.4583V2.54168C16.4583 2.16668 16.1667 1.83334 15.75 1.83334H4.20833C3.83333 1.83334 3.5 2.12501 3.5 2.54168V7.75001Z" fill="#F66F4D" />
                                            <path d="M18.958 17.375H1.04134C0.583008 17.375 0.208008 17 0.208008 16.5417V8.54168C0.208008 8.08334 0.583008 7.70834 1.04134 7.70834H18.9997C19.458 7.70834 19.833 8.08334 19.833 8.54168V16.5834C19.7913 17 19.4163 17.375 18.958 17.375ZM1.83301 15.75H18.1663V9.37501H1.83301V15.75Z" fill="#F66F4D" />
                                            <path d="M9.7915 9.37502H5.24984C4.7915 9.37502 4.4165 9.00002 4.4165 8.54169C4.4165 6.45835 5.7915 4.79169 7.49984 4.79169C9.20817 4.79169 10.5832 6.45835 10.5832 8.54169C10.5832 9.00002 10.2498 9.37502 9.7915 9.37502ZM6.20817 7.75002H8.87484C8.6665 7.00002 8.12484 6.45835 7.5415 6.45835C6.95817 6.45835 6.4165 6.95835 6.20817 7.75002Z" fill="#F66F4D" />
                                            <path d="M14.75 9.37502H10.2083C9.75 9.37502 9.375 9.00002 9.375 8.54169C9.375 6.45835 10.75 4.79169 12.4583 4.79169C14.1667 4.79169 15.5417 6.45835 15.5417 8.54169C15.5417 9.00002 15.1667 9.37502 14.75 9.37502ZM11.1667 7.75002H13.8333C13.625 7.00002 13.0833 6.45835 12.5 6.45835C11.875 6.41669 11.375 6.95835 11.1667 7.75002Z" fill="#F66F4D" />
                                            <path d="M1.04134 19.7917C0.583008 19.7917 0.208008 19.4167 0.208008 18.9583V16.5417C0.208008 16.0833 0.583008 15.7083 1.04134 15.7083C1.49967 15.7083 1.87467 16.0833 1.87467 16.5417V18.9583C1.83301 19.4167 1.45801 19.7917 1.04134 19.7917Z" fill="#F66F4D" />
                                            <path d="M18.9583 19.7917C18.5 19.7917 18.125 19.4167 18.125 18.9583V16.5417C18.125 16.0833 18.5 15.7083 18.9583 15.7083C19.4167 15.7083 19.7917 16.0833 19.7917 16.5417V18.9583C19.7917 19.4167 19.4167 19.7917 18.9583 19.7917Z" fill="#F66F4D" />
                                        </svg>
                                        <h5
                                            style={{ marginTop: "12px", marginRight: "10px", marginLeft: "11px" }}
                                        >Appartamento</h5>
                                        <select
                                            className="form-control"
                                            id="appartamento-scontistica"
                                            name={"appartamento-scontistica"}
                                            onChange={changeAppartamento}
                                            required={true}
                                        >
                                            <option disabled selected value>Seleziona un appartamento</option>

                                            {rooms.map((room) =>
                                                <option value={room['id']}>{room['nome']}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="row" style={{ width: '25%' }}>
                                    <button type="button" className="btn btn-success"
                                        onClick={conferma}
                                    >Conferma</button>
                                </div>

                            </div>
                        }

                    </div>

                </div>

                {listaFinalePrezziSconti.length !== 0 &&
                    <div className="card h-auto" >
                        <div className="card-body pt-0">
                            {refreshTable ?
                                <Loader />
                                :
                                <VisualizzaPrenotazioniTable listaFinalePrezziSconti={listaFinalePrezziSconti} />

                            }
                        </div>
                    </div>
                }

                {listaFinalePrezziSconti.length !== 0 &&
                    <div className="card h-auto" >
                        <div className="card-body pt-0">
                            <div className="col-sm-12 col-12" style={{ marginTop: "30px", marginBottom: "15px" }}>
                                <div className="d-flex">
                                    {prezzoUndefined === false ?
                                        <h5 style={{ marginTop: "10px", marginRight: "10px", marginLeft: "7px" }} >Prezzo totale: €{prezzoFinale}</h5>
                                        :
                                        <h5 style={{ marginTop: "10px", marginRight: "10px", marginLeft: "7px" }} >Il prezzo totale non può essere calcolato poiché alcuni giorni sono assenti nell'appartamento</h5>
                                    }
                                </div>
                            </div>
                            <div className="col-sm-12 col-12" style={{ marginTop: "30px", marginBottom: "15px" }}>
                                {prezzoUndefined === false ?
                                    <h5 style={{ marginTop: "10px", marginRight: "10px", marginLeft: "7px" }} >Prezzo totale scontato: €{scontoFinale}</h5>
                                    :
                                    <h5 style={{ marginTop: "10px", marginRight: "10px", marginLeft: "7px" }} >  Lo sconto totale non può essere calcolato poiché alcuni giorni sono assenti nell'appartamento</h5>
                                }
                            </div>
                        </div>
                    </div>
                }
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
            </div>
        </div >
    );
};

export default SimulatoreScontiComponent;
