import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import ListaServizi from "./ListaServizi";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";

function HeaderCard(props) {
	return (
		<div className="row">
			<div className="col-xl-12">
				<div className="card h-auto">
					<div className="card-body profile-bx">
						<div className="row">
							<div className="col-12 col-md-6 col-lg-7 col-xl-8">
								<div className="row">
									<h3 className="fs-21">{props.prenotazione.title}</h3>
									<div className="fs-16 mb-0">{props.prenotazione.mail}</div>
									<div className="fs-16 mb-0">{props.prenotazione.telefono}</div>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-5 col-xl-4">
								<div className="col-6 col-md-6 col-lg-6 col-xl-6"
									style={{ float: "left", marginTop: "15px" }}
								>
									<div className="d-flex">

										{props.prenotazione['stato'] === "NON_APPROVATA" ?
											<Button
												className="me-2"
												variant="success btn-rounded btn-sm"
												disabled="disabled"
											>
												Approva prenotazione
											</Button>
											:
											props.statoPrenotazione === "NON_APPROVATA" && props.prenotazione['stato'] === "MODIFICA" ?
												<Button
													className="me-2"
													variant="success btn-rounded btn-sm"
													onClick={props.approvaPrenotazione}
												>
													Approva prenotazione
												</Button>
												:
												(props.statoPrenotazione === "CONFERMATA" || props.statoPrenotazione === "ATTESA_PAGAMENTO") && props.prenotazione['stato'] === "MODIFICA" ?
													<Button
														className="me-2"
														variant="success btn-rounded btn-sm"
														onClick={props.approvaPrenotazione}
													>
														Modifica prenotazione
													</Button>
													:
													props.statoPrenotazione === "CANCELLATA" && props.prenotazione['stato'] === "MODIFICA" ?
														<Button
															className="me-2"
															variant="success btn-rounded btn-sm"
															onClick={props.approvaPrenotazione}
														>
															Ripristina prenotazione
														</Button>
														:
														<></>

										}
									</div>
								</div>
								<div className="col-6 col-md-6 col-lg-6 col-xl-6"
									style={{ float: "left", marginTop: "15px" }}
								>
									<div className="d-flex">

										{props.prenotazione['stato'] === "TERMINATA" ?
											<></>
											:
											props.prenotazione['stato'] !== "MODIFICA" && props.prenotazione['stato'] !== "CANCELLATA" && props.prenotazione['stato'] !== "TERMINATA" ?
												<Button className="me-2"
													variant="secondary btn-rounded btn-sm"
													onClick={() => props.changeStatoPrenotazione("MODIFICA")}
												>
													Modifica prenotazione
												</Button>
												: props.prenotazione['stato'] === "MODIFICA" &&
												<Button Button className="me-2"
													variant="danger btn-rounded btn-sm"
													onClick={() => props.changeStatoPrenotazione(props.statoPrenotazione)}
												>
													Annulla tutte le modifiche
												</Button>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}
export default HeaderCard;