import React, { useEffect, useState, useRef, useCallback } from "react";
import Note from "./details-prenotazione/Note";
import NoteModifica from "./details-prenotazione/NoteModifica";
import apiConfig from "../../../API/apiConfig";
import env from "../../../envConfig";
import { callLambda } from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';

import Dettagli from "./details-prenotazione/Dettagli";
import DettagliModifica from "./details-prenotazione/DettagliModifica";
import moment from "moment";
import swal from 'sweetalert'
import Swal from 'sweetalert2'

import html2canvas from 'html2canvas';
import 'moment/locale/it';
import HeaderDettagli from "./details-prenotazione/HeaderDettagli";
import HeaderCard from "./details-prenotazione/HeaderCard";
import FooterCard from "./details-prenotazione/FooterCard";
import Pagamento from "./details-prenotazione/Pagamento";
import PagamentoModifica from "./details-prenotazione/PagamentoModifica";
import { dateFromNormalFormatToTimestamp, dateFromTimestampToNormalFormat } from "./utils/utils";
moment.locale('it')

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => value + 1); // update state to force render
	// An function that increment 👆🏻 the previous state like here 
	// is better than directly setting `value + 1`
}

const PrenotazioneDetails = () => {
	const [refresh, setRefresh] = useState(true);
	const [room, setRoom] = useState('');
	const [prenotazione, setPrenotazione] = useState('');
	const [prenotazioneOld, setPrenotazioneOld] = useState('');
	const [statoPrenotazione, setStatoPrenotazione] = useState('');
	const forceUpdate = useForceUpdate();
	const pdfRef = useRef(null);

	useEffect(() => {

		let path = window.location.pathname.split("/");

		let queryParams = path[2] + "/" + path[3];

		let paramsAxios = {
			endpoint: env().API_ENDPOINT_URL + apiConfig.getPrenotazioneByID,
			method: "GET",
			queryParams: queryParams
		}

		callLambda(paramsAxios, res => {
			console.log("[" + paramsAxios['endpoint'] + "]", res)
			setRoom(res.data.risultato)
			let myPrenotazione = res.data.risultato.prenotazione
			myPrenotazione = dateFromTimestampToNormalFormat(myPrenotazione)
			myPrenotazione['pagamento'] = "Bonifico"
			setPrenotazione(myPrenotazione)
			setStatoPrenotazione(myPrenotazione['stato'])
			sessionStorage.setItem('prenotazione-old', JSON.stringify(myPrenotazione))
			setRefresh(false)
		});

	}, []);

	const notifyBottomLeft = (message) => {
		toast.error("❌ " + message + "!", {
			position: "bottom-left",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
		});
	};


	const notSamePrenotazione = () => {
		let trovatoErrore = false
		if (room.personeMax < (Number(prenotazione.numeroAdulti) + Number(prenotazione.numeroBambini))) {
			trovatoErrore = true
			notifyBottomLeft("Per l'appartamento selezionato non possono esserci più di "
				+ room.personeMax + " persone")
		}
		if (Number(prenotazione.prezzo) < Number(prenotazione.acconto)) {
			trovatoErrore = true
			notifyBottomLeft("L'acconto non può essere superiore al prezzo totale!")
		}
		if (Number(prenotazione.prezzo) === 0) {
			trovatoErrore = true
			notifyBottomLeft("Il prezzo non può essere pari a 0!")
		}

		if (prenotazione.pagamento === '') {
			trovatoErrore = true
			notifyBottomLeft("Nessun metodo di pagamento è stato selezionato!")
		}
		if (prenotazione.noteCliente === '') {
			trovatoErrore = true
			notifyBottomLeft("Non hai inserito nessuna nota per il cliente!")
		}
		if (Number(prenotazione.numeroAdulti) === 0) {
			trovatoErrore = true
			notifyBottomLeft("Hai inserito 0 adulti per l'appartamento selezionato")
		}
		if (moment(prenotazione.start).isSameOrAfter(moment(prenotazione.end))) {
			trovatoErrore = true
			notifyBottomLeft("Hai inserito una data di partenza precedente alla data di arrivo")
		}

		if (!trovatoErrore) {

			swal({
				title: "Sei sicuro di voler confermare?",
				text:
					"Una volta confermata, verrà inviata una mail di riepilogo al cliente!",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then((willDelete) => {
				if (willDelete) {
					let prenotazioneBody = prenotazione
					const serviziPagamento = []
					const serviziRoom = Object.entries(room.servizi)

					console.log("serviziRoom", serviziRoom)
					prenotazioneBody['servizi']['serviziMail'] = []


					for (let i = 0; i < room.servizi.length; i++) {
						if (room.servizi[i]['isPagamento']) {
							serviziPagamento.push(room.servizi[i]['servizio'])
						}
					}
					console.log("serviziPagamento", serviziPagamento)

					for (let i = 0; i < serviziPagamento.length; i++) {
						console.log("document.getElementById(check-" + serviziPagamento[i] + ").checked", document.getElementById("check-" + serviziPagamento[i]).checked)

						if (serviziPagamento[i] !== 'serviziMail') {
							prenotazioneBody['servizi'][serviziPagamento[i]] = document.getElementById("check-" + serviziPagamento[i]).checked
							if (document.getElementById("check-" + serviziPagamento[i]).checked) {
								prenotazioneBody['servizi']['serviziMail'].push(serviziPagamento[i])
							}
						}
					}

					for (let i = 0; i < serviziRoom.length; i++) {
						if (serviziRoom[i][1]['isPagamento'] === false) {
							prenotazioneBody['servizi'][serviziRoom[i][1]['servizio']] = false
							prenotazioneBody['servizi']['serviziMail'].push(serviziRoom[i][1]['servizio'])

						}
					}

					prenotazioneBody = dateFromNormalFormatToTimestamp(prenotazioneBody)
					console.log("prenotazione dopo il ritorno", prenotazioneBody)


					if (Number(prenotazione.acconto) === 0) {
						prenotazioneBody['stato'] = "ATTESA_PAGAMENTO"
						prenotazioneBody['bgColor'] = "#A1A1A1"
					} else {
						prenotazioneBody['stato'] = "CONFERMATA"
						prenotazioneBody['bgColor'] = "green"
					}
					prenotazioneBody['noteCliente'] = prenotazioneBody['noteCliente'].replace(/\n/g, "<br>");

					let body = {
						idRoom: prenotazioneBody.resourceId,
						prenotazione: prenotazioneBody,
						idPrenotazione: prenotazioneBody.id,
					}

					let paramsAxios = {
						endpoint: env().API_ENDPOINT_URL + apiConfig.modificaPrenotazione,
						method: 'PUT',
						body: body
					}
					setRefresh(true)

					callLambda(paramsAxios, res => {
						setRefresh(false)
						if (res.data.esito === "OK") {
							swal("Confermata!", "La prenotazione è stata confermata correttamente!", "success")

							prenotazioneBody = dateFromTimestampToNormalFormat(prenotazioneBody)
							console.log("prenotazioneBody", prenotazioneBody)
							setPrenotazione(prenotazioneBody)
							setStatoPrenotazione(prenotazioneBody['stato'])
							sessionStorage.setItem('prenotazione-old', JSON.stringify(prenotazioneBody))
							forceUpdate()

						} else {
							if (res.data.esito === "KO") {
								swal("Attenzione!", res.data.descrizioneErrore, "error")
								let myPrenotazioneOld = JSON.parse(sessionStorage.getItem('prenotazione-old'))
								myPrenotazioneOld = dateFromTimestampToNormalFormat(myPrenotazioneOld)
								setPrenotazione(myPrenotazioneOld)
								setStatoPrenotazione(myPrenotazioneOld['stato'])
								setRefresh(false)
								forceUpdate()
							} else {
								//significa che il DB è andato bene ma la mail no, rallback!!
								swal("Attenzione!", res.data.descrizioneErrore, "error").then((value) => {
									let myPrenotazioneOld = JSON.parse(sessionStorage.getItem('prenotazione-old'))
									myPrenotazioneOld = dateFromNormalFormatToTimestamp(myPrenotazioneOld)

									let body = {
										idRoom: myPrenotazioneOld.resourceId,
										prenotazione: myPrenotazioneOld,
										idPrenotazione: myPrenotazioneOld.id,
									}

									let paramsAxios = {
										endpoint: env().API_ENDPOINT_URL + apiConfig.modificaPrenotazione,
										method: 'PUT',
										body: body
									}

									callLambda(paramsAxios, res => {
										if (res.data.esito === "OK") {
											swal("Attenzione!", "Tutte le modifiche sulla prenotazione sono state annullate!", "error")
											console.log("prenotazioneOld", myPrenotazioneOld)
											myPrenotazioneOld = dateFromTimestampToNormalFormat(myPrenotazioneOld)
											setPrenotazione(myPrenotazioneOld)
											setStatoPrenotazione(myPrenotazioneOld['stato'])
											setRefresh(false)
											forceUpdate()
										}
									});
								})


							}
						}
					});
				} else {
				}
			})


		}
	}



	const approvaPrenotazione = () => {

		let prenotazioneOld = JSON.parse(sessionStorage.getItem('prenotazione-old'))
		let prenotazioneCurrent = prenotazione

		prenotazioneOld['stato'] = prenotazioneCurrent['stato'] = 'MODIFICA'

		if (JSON.stringify(prenotazioneOld) == JSON.stringify(prenotazioneCurrent)) {
			if (JSON.parse(sessionStorage.getItem('prenotazione-old'))['stato'] !== "CANCELLATA") {
				notifyBottomLeft("Non sono state effettuate modifiche alla prenotazione corrente")
			} else {
				notSamePrenotazione()
			}
		} else {
			notSamePrenotazione()
		}
	}


	const onChangePrenotazione = (event) => {
		const valore = event.target.value;
		const chiave = event.target.getAttribute('name')
		console.log("valore", valore)
		console.log("chiave", chiave)
		const prenotazioneEdit = prenotazione;
		prenotazioneEdit[chiave] = valore
		console.log("prenotazioneEdit", prenotazioneEdit)

		setPrenotazione(prenotazioneEdit)
		forceUpdate()
	}


	const deletePrenotazione = () => {

		swal({
			title: "Sei sicuro di voler eliminare la prenotazione?",
			text:
				"Una volta elimanata, sarà comunque ripristinabile!",
			icon: "info",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {

				Swal.fire({
					title: '<strong>Nota cancellazione<strong>',
					icon: 'warning',
					html: `<textarea
							name="noteCancellazione"
							id="noteCancellazione"
							rows={2} 
							className="form-control bg-transparent" 
							placeholder="Inserisci una nota per la cancellazione.."
						/>`,
					showCloseButton: true,
					showCancelButton: true,
					focusConfirm: false,
					cancelButtonText: 'Annulla',
					confirmButtonText: 'Conferma',
				}).then(res => {
					console.log("res", res)
					if (res.isConfirmed) {
						let noteCancellazione = document.getElementById("noteCancellazione").value
						console.log("notaCancellazione", noteCancellazione)
						if (noteCancellazione === "") {
							console.log("dentro")
							Swal.fire({
								icon: 'error',
								title: 'Attenzione',
								text: 'La nota di cancellazione è obbligatoria!',
							})
						} else {

							let myPrenotazione = prenotazione
							myPrenotazione = dateFromNormalFormatToTimestamp(myPrenotazione)

							myPrenotazione['stato'] = "CANCELLATA"
							myPrenotazione['noteCancellazione'] = noteCancellazione

							let body = {
								idRoom: myPrenotazione.resourceId,
								prenotazione: myPrenotazione,
								idPrenotazione: myPrenotazione.id,
							}

							let paramsAxios = {
								endpoint: env().API_ENDPOINT_URL + apiConfig.modificaPrenotazione,
								method: 'PUT',
								body: body
							}

							console.log("finale", body)

							setRefresh(true)
							callLambda(paramsAxios, res => {
								console.log("RES", res)
								if (res.data.esito === "OK") {
									swal("OK! La tua prenotazione è stata correttamente eliminata!", {
										icon: "success",
									});
									setPrenotazione(myPrenotazione)
									setStatoPrenotazione(myPrenotazione['stato'])
									setRefresh(false)
									forceUpdate()
								}
							});
						}
					}
				})
			}
		});
	}

	const changeStatoPrenotazione = (stato) => {

		const myPrenotazione = prenotazione

		myPrenotazione['stato'] = stato
		if (stato === statoPrenotazione) {
			setPrenotazione(JSON.parse(sessionStorage.getItem('prenotazione-old')))
			forceUpdate()
		} else {
			setPrenotazione(myPrenotazione)
			forceUpdate()
		}

	};

	const inviaMail = () => {

		console.log("prenotazione", prenotazione)
		console.log("room", room)




		let body = {
			appartamento: room.nome,
			prenotazione: prenotazione
		}

		let paramsAxios = {
			endpoint: env().API_ENDPOINT_URL + apiConfig.inviaMailRiepilogo,
			method: 'PUT',
			body: body
		}


		swal({
			title: "Sei sicuro di voler confermare?",
			text:
				"Una volta confermata, verrà inviata una mail di riepilogo al cliente!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				setRefresh(true)

				callLambda(paramsAxios, res => {
					console.log("Res", res)
					if (res.data.esito === "OK") {
						swal("La mail è stata inviata con successo!", {
							icon: "success",
						});
						//setPrenotazione(myPrenotazione)
						//setStatoPrenotazione(myPrenotazione['stato'])
						setRefresh(false)
						//forceUpdate()
					} else {

					}
				});
			} else {
			}
		})





	}





	return (
		<>
			{refresh ?
				<div className="loader-container" style={{ textAlign: "center" }}>
					<ClipLoader
						color={'red'}
						size={150} />
				</div>
				:
				<div>
					<HeaderCard
						prenotazione={prenotazione}
						changeStatoPrenotazione={changeStatoPrenotazione}
						approvaPrenotazione={approvaPrenotazione}
						statoPrenotazione={statoPrenotazione}

					/>
					<div className="row" id="capture">
						<div className="col-xl-7" ref={pdfRef}>
							<div className="card h-auto" >
								<HeaderDettagli
									prenotazione={prenotazione}
									statoPrenotazione={statoPrenotazione}
								/>

								{prenotazione['stato'] !== "MODIFICA" ?
									<Dettagli
										room={room}
										prenotazione={prenotazione}
									/>
									:
									<DettagliModifica
										room={room}
										prenotazione={prenotazione}
										onChangePrenotazione={onChangePrenotazione}
									/>
								}
							</div>
						</div>
						<div className="col-xl-5">
							<div className="col-sm-12">
								<div className="card h-auto">
									<div className="card-header border-0 pb-0">
										<h4 className="fs-24 font-w500">Prezzo e pagamento</h4>
									</div>
									{prenotazione['stato'] !== "MODIFICA" ?
										<Pagamento
											prenotazione={prenotazione}
										/>
										:
										<PagamentoModifica
											prenotazione={prenotazione}
											onChangePrenotazione={onChangePrenotazione} />
									}
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card h-auto">
									<div className="card-header border-0 pb-0">
										<h4 className="fs-24 font-w500">Le tue note</h4>
									</div>
									{prenotazione['stato'] !== "MODIFICA" ?
										<Note
											noteCliente={prenotazione.noteCliente}
											noteBestHouse={prenotazione.noteBestHouse}
										/>
										:
										<NoteModifica
											noteCliente={prenotazione.noteCliente}
											noteBestHouse={prenotazione.noteBestHouse}
											onChangePrenotazione={onChangePrenotazione} />
									}
								</div>
							</div>
						</div>
					</div>
					<FooterCard
						prenotazione={prenotazione}
						deletePrenotazione={deletePrenotazione}
						changeStatoPrenotazione={changeStatoPrenotazione}
						statoPrenotazione={statoPrenotazione}
						inviaMail={inviaMail}
					/>
					<ToastContainer
						position="bottom-left"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnFocusLoss={false}
						draggable={false}
						pauseOnHover={false}
					/>
				</div>
			}
		</>
	)
}
export default PrenotazioneDetails;