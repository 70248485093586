/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState, Fragment } from "react";
import { Dropdown } from 'react-bootstrap';
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";


import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";



/* QUI SI MODIFICANO LE ROTTE PER LA SIDEBAR (A SINISTRA ) */

class MM extends Component {
	componentDidMount() {
		console.log("AMBIENTE", process.env.REACT_APP_ENV)
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
	componentWillUnmount() {
	}
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const SideBar = () => {

	var d = new Date();

	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
		navigationHader,
		Hader, openMenuToggle, background, toggle, toggleHandle
	} = useContext(ThemeContext);

	//console.log(sidebarposition);
	//console.log(sidebarLayout);

	const handleSidebar = () => {

		const sidebar = document.getElementById('main-wrapper').getAttribute('class')
		const viewMode = document.querySelector("body").getAttribute("data-sidebar-style")

		if (sidebar === 'show' && viewMode === 'full' || sidebar === 'show menu-toggle' && viewMode === "overlay") {
			document.getElementById("myHamburgerControlNavbar").click()
		}

	}

	useEffect(() => {
		var btn = document.querySelector(".nav-control");
		var aaa = document.querySelector("#main-wrapper");
		function toggleFunc() {
			return aaa.classList.toggle("menu-toggle");
		}
		btn.addEventListener("click", toggleFunc);

		//sidebar icon Heart blast
		var handleheartBlast = document.querySelector('.heart');
		function heartBlast() {
			return handleheartBlast.classList.toggle("heart-blast");
		}
		handleheartBlast.addEventListener('click', heartBlast);

	}, []);
	//For scroll
	const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
			const isShow = currPos.y > prevPos.y
			if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
	/// Path
	let path = window.location.pathname;
	path = path.split("/");
	path = path[path.length - 1];
	/// Active menu

	let appartamenti = [
		"lista-appartamenti",
		"nuovo-appartamento"
	],
		prenotazioni = [
			"lista-prenotazioni",
			"nuova-prenotazione"
		],
		fotoHome = ["foto-home"],
		vendita = ["vendita"],
		sconti = ["inserisci-sconti", "simulatore-sconti"]


	let deshBoard = [
		"dashboard-dark",
		"guest-list",
		"guest-details",
		"concierge",
		"room",
		"task",
	],
		app = [
			"app-profile",
			"post-details",
			"app-calender",
			"email-compose",
			"email-inbox",
			"email-read",
			"ecom-product-grid",
			"ecom-product-list",
			"ecom-product-order",
			"ecom-checkout",
			"ecom-invoice",
			"ecom-customers",
			"post-details",
			"ecom-product-detail",
		],
		email = ["email-compose", "email-inbox", "email-read"],
		shop = [
			"ecom-product-grid",
			"ecom-product-list",
			"ecom-product-list",
			"ecom-product-order",
			"ecom-checkout",
			"ecom-invoice",
			"ecom-customers",
			"ecom-product-detail",
		],
		charts = [
			"chart-rechart",
			"chart-flot",
			"chart-chartjs",
			"chart-chartist",
			"chart-sparkline",
			"chart-apexchart",
		],
		bootstrap = [
			"ui-accordion",
			"ui-badge",
			"ui-alert",
			"ui-button",
			"ui-modal",
			"ui-button-group",
			"ui-list-group",
			"ui-card",
			"ui-carousel",
			"ui-dropdown",
			"ui-popover",
			"ui-progressbar",
			"ui-tab",
			"ui-typography",
			"ui-pagination",
			"ui-grid",
		],
		plugins = [
			"uc-select2",
			"uc-nestable",
			"uc-sweetalert",
			"uc-toastr",
			"uc-noui-slider",
			"map-jqvmap",
			"uc-lightgallery",
		],
		redux = [
			"redux-form",
			"redux-wizard",
			"todo",
		],
		widget = ["widget-basic"],
		forms = [
			"form-element",
			"form-wizard",
			"form-ckeditor",
			"form-pickers",
			"form-validation-jquery",
		],
		table = ["table-bootstrap-basic", "table-datatable-basic"],
		pages = [
			"page-register",
			"page-login",
			"page-lock-screen",
			"page-error-400",
			"page-error-403",
			"page-error-404",
			"page-error-500",
			"page-error-503",
		],
		error = [
			"page-error-400",
			"page-error-403",
			"page-error-404",
			"page-error-500",
			"page-error-503",
		];
	return (




		<div
			className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
				sidebarLayout.value === "horizontal" &&
				headerposition.value === "static"
				? hideOnScroll > 120
					? "fixed"
					: ""
				: ""
				}`}
		>
			<PerfectScrollbar className="deznav-scroll">
				<Dropdown className="dropdown header-bx">
					<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer header-profile2 position-relative">

					</Dropdown.Toggle>
				</Dropdown>
				<MM className="metismenu" id="menu">

					<li className={`${fotoHome.includes(path) ? "mm-active" : ""}`}
					>
						<Link className={`${path === "foto-home" ? "mm-active" : ""}`}
							to="/foto-home"
							id={"foto-home"}
							onClick={() => {
								//document.getElementById("myHamburgerControlNavbar").click()
								handleSidebar()
							}}
						>
							<i className="flaticon-381-photo-camera"></i>
							<span className="nav-text">Foto per Home</span>
						</Link>
					</li>

					<li className={`${appartamenti.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#"

						>
							<i className="flaticon-381-home"></i>
							<span className="nav-text">Appartamenti</span>
						</Link>
						<ul >

							<li>
								<Link className={`${path === "nuovo-appartamento" ? "mm-active" : ""}`}
									to="/nuovo-appartamento"
									id='nuovo-appartamento'
									onClick={() => {
										handleSidebar()
									}}
								> Inserisci un nuovo appartamento</Link>
							</li>
							<li>
								<Link className={`${path === "lista-appartamenti" ? "mm-active" : ""}`}
									to="/lista-appartamenti"
									onClick={() => {
										handleSidebar()
									}}
									id='lista-appartamenti'

								>Lista degli appartamenti aggiunti</Link>
							</li>
						</ul>
					</li>


					<li className={`${prenotazioni.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="flaticon-013-checkmark"></i>
							<span className="nav-text">Prenotazioni</span>
						</Link>
						<ul >

							<li><Link className={`${path === "nuova-prenotazione" ? "mm-active" : ""}`}
								to="/nuova-prenotazione"
								id={"nuova-prenotazione"}
								onClick={() => {
									handleSidebar()
								}}
							> Inserisci una nuova prenotazione</Link></li>
							<li><Link className={`${path === "lista-prenotazioni" ? "mm-active" : ""}`}
								to="/lista-prenotazioni"
								id={"lista-prenotazioni"}
								onClick={() => {
									handleSidebar()
								}}
							>Visualizza tutte le tue prenotazioni</Link></li>
						</ul>
					</li>

					{/*
					<li className={`${vendita.includes(path) ? "mm-active" : ""}`}>
						<Link className={`${path === "vendita" ? "mm-active" : ""}`}
							id={"link-vendita"}
							to="/vendita"
							onClick={() => {
								handleSidebar()
							}}
						>
							<i className="flaticon-086-star"></i>
							<span className="nav-text">Sezione vendita</span>
						</Link>
						</li>
						*/}

					<li className={`${sconti.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="flaticon-381-gift"></i>
							<span className="nav-text">Sconti</span>
						</Link>
						<ul >
							<li><Link className={`${path === "inserisci-sconti" ? "mm-active" : ""}`}
								to="/inserisci-sconti"
								id={"inserisci-sconti"}
								onClick={() => {
									handleSidebar()
								}}
							> Inserisci nuovi sconti</Link></li>
							<li><Link className={`${path === "simulatore-sconti" ? "mm-active" : ""}`}
								to="/simulatore-sconti"
								id={"simulatore-sconti"}
								onClick={() => {
									handleSidebar()
								}}
							>Simulatore di sconti</Link></li>
						</ul>
					</li>
				</MM>

				<div className="copyright">
					<h6>BestHouse Dashboard <span className="fs-14 font-w400">© {d.getFullYear()} All Rights Reserved</span></h6>
					<p className="fs-12 mb-4">Made with <span className="heart"></span> by Maffle SRL</p>
				</div>
			</PerfectScrollbar>
		</div>
	);
};

export default SideBar;
