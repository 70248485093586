import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	Dropdown,
	DropdownButton,
	ButtonGroup,
	SplitButton,
	ProgressBar
} from "react-bootstrap";
import Loader from '../../../../components/Loader';
import env from '../../../../../envConfig';
import { callLambda } from '../../../../../API/api';
import moment from 'moment';
import apiConfig from '../../../../../API/apiConfig';
import { dateFromTimestampToNormalFormat } from '../../utils/utils';
import { filtraPrenotazioniByAppartamento, filtraPrenotazioniByStato, filtraPrenotazioniByCheckin } from './filtraggio-tabella/filterData';

export const VisualizzaPrenotazioniTable = () => {

	useEffect(() => {

		let paramsAxios = {
			endpoint: env().API_ENDPOINT_URL + apiConfig.getPrenotazioni,
			method: "GET",
			queryParams: "?dataInizio=0&dataFine=999999999999999"
		}

		callLambda(paramsAxios, res => {
			console.log("[" + paramsAxios['endpoint'] + "]", res)
			let prenotazioni = []
			if (res.data.esito === "OK") {
				let roomsPrenotazioni = res.data.risultato
				roomsPrenotazioni.map((room) => {
					room.prenotazioni.map((prenotazione) => {
						prenotazione['appartamento'] = room['nome']
						prenotazioni.push(prenotazione)

					})
				})

				prenotazioni.map((prenotazione) => {

					prenotazione['arrivo'] = moment(prenotazione['start']).format('YYYY-MM-DD') + ", ore " + prenotazione['checkin']
					prenotazione['partenza'] = moment(prenotazione['end']).add('1', 'days').format('YYYY-MM-DD') + ", ore " + prenotazione['checkout']

					let stato = ''
					if (prenotazione['stato'] === "NON_APPROVATA") {
						stato = <i className="fa text-warning me-1" style={{ width: "max-content" }}>Non approvata</i>
					} else if (prenotazione['stato'] === "CONFERMATA") {
						stato = <i className="fa text-success me-1">Confermata</i>
					} else if (prenotazione['stato'] === "ATTESA_PAGAMENTO") {
						stato = <i className="fa text-dark me-1">Attesa di pagamento</i>
					} else if (prenotazione['stato'] === "CANCELLATA") {
						stato = <i className="fa text-danger me-1">Cancellata</i>
					} else if (prenotazione['stato'] === "TERMINATA") {
						stato = <i className="fa text-info me-1">Terminata</i>
					}
					prenotazione['statoFiltro'] = prenotazione['stato']
					prenotazione['stato'] = <Link
						to={"/prenotazione-details/" + prenotazione['resourceId'] + "/" + prenotazione['id']}>
						{stato}
					</Link>
				})

				let prenotazioniOrdinate = prenotazioni.sort(function (a, b) {
					return a['start'] - b['start'];
				});
				console.log("prenotazioni dopo ordinamento", prenotazioniOrdinate)
				prenotazioniOrdinate = filtraPrenotazioniByCheckin(prenotazioniOrdinate)
				setMOCK_DATA(prenotazioniOrdinate)
				setRefresh(false)


			}
		});


	}, []);

	const [MOCK_DATA, setMOCK_DATA] = useState([]);
	const [orderFilterAppartamento, setOrderFilterAppartamento] = useState(true);
	const [orderFilterStato, setOrderFilterStato] = useState(true);
	const [refresh, setRefresh] = useState(true);
	const columns = useMemo(() => COLUMNS, [])
	const data = useMemo(() => MOCK_DATA)
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0, hiddenColumns: ['statoFiltro', 'start', 'end'] }
	}, useFilters, useGlobalFilter, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance

	const orderPrenotazione = (value) => {

		let entries = MOCK_DATA
		let prenotazioniOrdinate = []
		if (value === 'DataCrescente') {
			// ordina l'array in base alle date in ordine decrescente
			entries.sort(function (a, b) {
				return a['start'] - b['start'];
			});
			entries = filtraPrenotazioniByCheckin(entries)
		} else if (value === 'Appartamento') {
			entries = filtraPrenotazioniByAppartamento(entries, orderFilterAppartamento)
			setOrderFilterAppartamento(!orderFilterAppartamento)
		} else if (value === 'Stato') {
			entries = filtraPrenotazioniByStato(entries, orderFilterStato)
			setOrderFilterStato(!orderFilterStato)
		}

		for (let i = 0; i < entries.length; i++) {
			prenotazioniOrdinate.push(entries[i])
		}
		console.log("entries dopo ordinamento", entries)

		setMOCK_DATA(entries)

	}

	const { globalFilter, pageIndex } = state


	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Lista delle prenotazioni</h4>
			</div>
			<div className="card-body">
				{refresh ?
					<Loader />

					:
					<div>
						<Row style={{ alignItems: "end" }}>
							<Col xl={6} lg={2} xs={8} sm={3}>
								<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
							</Col>
							<Col>
								<Dropdown >
									<Dropdown.Toggle
										variant="outline-primary"
										size="sm"
										className="mt-1 mb-2"
									>
										Ordina per
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item onClick={() => orderPrenotazione('DataCrescente')} href="#">Prenotazioni meno recenti</Dropdown.Item>
										<Dropdown.Item onClick={() => orderPrenotazione('Appartamento')} href="#">Appartamento</Dropdown.Item>
										<Dropdown.Item onClick={() => orderPrenotazione('Stato')} href="#">Stato prenotazione</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
						<div className="table-responsive">

							<table {...getTableProps()} className="table dataTable display">
								<thead>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map(column => (
												<th {...column.getHeaderProps()}>
													{column.render('Header')}
													{
														//column.canFilter ? column.render('Filter') : null
													}

												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="" >

									{page.map((row, index) => {
										prepareRow(row)
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
												})}
											</tr>
										)
									})}
								</tbody>
							</table>
							<div className="d-flex justify-content-between">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<span className="table-index">
									Go to page : {' '}
									<input type="number"
										className="ml-2"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(pageNumber)
										}}
									/>
								</span>
							</div>
							<div className="text-center mb-3">
								<div className="filter-pagination  mt-3">
									<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

									<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
										Precedente
									</button>
									<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
										Successivo
									</button>
									<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
								</div>
							</div>
						</div>

					</div>
				}
			</div>
		</div>
	)

}
export default VisualizzaPrenotazioniTable;