import React, { useEffect, useState } from "react";

import { Tab, Nav } from "react-bootstrap";

function Note(props) {

	const [noteBestHouse, setNoteBestHouse] = useState('');
	const [noteCliente, setNoteCliente] = useState('');

	useEffect(() => {
		var regex = /<br\s*[\/]?>/gi;

		let noteBestHouse = props.noteBestHouse.replace(regex, "\n")
		let noteCliente = props.noteCliente.replace(regex, "\n")
		setNoteBestHouse(noteBestHouse)
		setNoteCliente(noteCliente)
	}, [])

	return (
		<div className="card-body pt-0">
			<div className="profile-tab">
				<div className="custom-tab-1">
					<Tab.Container defaultActiveKey='Posts'>
						<Nav as='ul' className="nav nav-tabs">
							<Nav.Item as='li' className="nav-item">
								<Nav.Link to="#my-posts" eventKey='Posts'>Note Cliente</Nav.Link>
							</Nav.Item>
							<Nav.Item as='li' i className="nav-item">
								<Nav.Link to="#about-me" eventKey='About'>Note BestHouse</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>
							<Tab.Pane id="my-posts" eventKey='Posts'>
								<div className="profile-about-me">
									<div className="pt-4 border-bottom-1 pb-3">
										<h4 className="text-primary">Descrizione</h4>
										<p className="mb-2">
											{props.noteCliente === '' ? "Nessuna nota per il cliente inserita" :
												noteCliente
											}
										</p>
									</div>
								</div>
							</Tab.Pane>
							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-about-me">
									<div className="pt-4 border-bottom-1 pb-3">
										<h4 className="text-primary">Descrizione</h4>
										<p className="mb-2">
											{props.noteBestHouse === '' ? "Nessuna nota personale inserita" :
												noteBestHouse
											}
										</p>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>
		</div>
	)
}
export default Note;