import { isPropsEqual } from "@fullcalendar/react";
import {React, Fragment} from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import {Tab} from  "react-bootstrap";

import { Row, Card, Col, Button, Nav } from "react-bootstrap";




const StepSixModifica = (props) => {


   const handleChange = (event) => {
        props.setIban(event.target.value)
      

   }
   
   const handleDescription = (event) => {

     console.log("descrizione iban",event.target.value)
     props.setibanInfo(event.target.value)
   }


   return (
       
       <Card>
      <section>
          	
              <div className="col-lg-4 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">IBAN</label>
                  <span className="text-danger">*</span>
                  <input
                     type="text"
                     name="Nome"
                     className="form-control"
                     placeholder="Inserisci IBAN"
                     required
                     value={props.iban}
                     onChange={handleChange}

                    
                  />
               
               </div>
            </div>
					
								<div className="my-post-content pt-3">
									<div className="post-input">
                                        <label className="text-label">Descrizione IBAN</label>
										<textarea
											name="noteBestHouse"
											id="textarea"
											rows={7} className="form-control bg-transparent" placeholder="Inserisci Causale, Beneficiario, BIC, etc.. andando a capo per ogni informazione che hai inserito"
                                            required
                                            value={props.ibanInfo}
											onChange={handleDescription}
										/>
									</div>
								</div>
							
      </section>
      </Card>
        
   );
};

export default StepSixModifica;
