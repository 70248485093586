import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200pt"
    height="200pt"
    viewBox="0 0 2000 2000"
    {...props}
    style={{ height: '2.5cm' }}
  >
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#000",
        fillOpacity: 1,
      }}
      d="M914.742 1019.965c0-17.38 10.184-31.477 22.742-31.477 12.559 0 22.739 14.098 22.739 31.477 0 17.379-10.18 31.469-22.739 31.469-12.558 0-22.742-14.09-22.742-31.47M1022.21 1042.465s20.142-6.985 35.31 0c15.16 6.984 17.78 12.062 20.91 11.113 3.14-.953 12.37-7.144 2.129-18.574-10.23-11.43-27.75-16.984-27.75-16.984s14.382-9.684 19.87-12.383c5.493-2.696 12.493-7.938 7.88-15.239-4.61-7.296-23.957 0-23.957 0s-25.102 9.364-39.622 31.43c-14.511 22.067 3.668 21.59 5.23 20.637M1061.7 1095.656c0 30.266-31.438 54.797-70.212 54.797-38.773 0-70.207-24.531-70.207-54.797v-.11c.008-4.234 3.66-6.757 6.492-4.593 11.149 8.516 35.485 14.426 63.715 14.426 28.23 0 52.57-5.91 63.723-14.426 2.828-2.164 6.48.36 6.488 4.594v.11"
    />
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#000",
        fillOpacity: 1,
      }}
      d="M1216.64 1130.582c0 25.047-20.37 45.422-45.421 45.422H828.777c-25.047 0-45.422-20.375-45.422-45.422v-133.55c.032.019.043.019.075.019v-26.313c0-11.277 4.804-22.297 13.289-30.148L923.75 835.68c1.262-1.2 2.66-2.301 4.055-3.32.03-.02.058-.051.09-.06 1.425 1.028 2.851 2.15 4.144 3.38l7.09 5.851 131.32 106.34a95.602 95.602 0 0 0 60.172 21.309h86.02ZM1046.5 824.07c11.14 0 21.781 4.14 29.809 11.61L1198.75 936.78h-66.21l-.028-.02a74.32 74.32 0 0 1-44.973-16.53l-118.742-96.16Zm201.66 135.621c-1.21-7.992-3.8-15.742-7.61-22.91-3.831-7.39-8.948-14.133-15.171-19.972l-127.027-104.86c-14.07-13.09-32.48-20.277-51.852-20.277h-92.941c-8.875 0-17.496 1.476-25.664 4.398a74.452 74.452 0 0 0-26.247 15.88l-14.64 12.12L774.69 916.88c-12.28 11.383-20.285 26.562-22.793 42.812a69.345 69.345 0 0 0-.87 11.047v159.844c0 42.93 34.82 77.754 77.75 77.754h342.44c42.93 0 77.75-34.824 77.75-77.754V969.18c-.038-3.2-.308-6.371-.808-9.489"
    />
  </svg>
)

export default SvgComponent
