import React from "react";

import EventCalendar from "./components/EventCalendar";
import SimulatoreScontiComponent from "./components/SimulatoreScontiComponent";
import PageTitle from "../../layouts/PageTitle";

const SimulatoreSconti = () => {
    return (
        <div className="h-80">
            <PageTitle activeMenu="Sconti" motherMenu="Simulatore di sconti" />

            <SimulatoreScontiComponent />
        </div>
    );
};

export default SimulatoreSconti;
