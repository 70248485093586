import React, { Fragment, useEffect, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import { useHistory } from "react-router-dom";

import StepOne from "./inserimento-appartamento/step-appartamento/StepOne";
import StepTwo from "./inserimento-appartamento/step-appartamento/StepTwo"
import StepThree from "./inserimento-appartamento/step-appartamento/StepThree";
import StepFour from "./inserimento-appartamento/step-appartamento/StepFour";
import ApartmentMap from "./inserimento-appartamento/step-appartamento/StepFive";
import PageTitle from "../../layouts/PageTitle";
import { callLambda } from "../../../API/api";
import uuid from 'react-native-uuid';
import { input } from "aws-amplify";
import env from "../../../envConfig";
import apiConfig from "../../../API/apiConfig";
import { servizi } from "./inserimento-appartamento/components/servizi";
import randomColor from "randomcolor";
import moment from "moment";
import StepFive from "./inserimento-appartamento/step-appartamento/StepFive";
import Loader from "../../components/Loader";
import StepSix from "./inserimento-appartamento/step-appartamento/StepSix";
const Swal = require('sweetalert2')

function generateNumericId() {
	// Get the current timestamp
	var timestamp = Date.now();

	// Generate a random number between 1 and 999
	var randomNumber = Math.floor(Math.random() * 999) + 1;

	// Return the unique numeric ID
	return timestamp + randomNumber;
}

const ApartmentWizard = () => {
	const history = useHistory()
	//Stato per gestire gli step del form
	const [goSteps, setGoSteps] = useState(0);
	//Stati per gestire i valori del primo step
	const [inputs, setInputs] = useState({});
	const [checked, setChecked] = useState(false);
	const [InPromotion,setPromotion] = useState(false)
	const [isVisible, setVisible] = useState(false)
	//Stati per gestire i valori del secondo step
	const [price, setPrice] = useState([]);
	const [calEvents, setcalEvents] = useState([])
	//Stati per gestire i valori del terzo step
	const [refresh, setRefresh] = useState([])
	const [vettorefoto, setVettoreFoto] = useState([])
	const [checkedState, setCheckedState] = useState(
		new Array(servizi.length).fill(false)
	);
	const [coordinates, setCoordinates] = useState({})
	const [errors, setErrors] = useState({});
	const [politicheCancellazione,setPoliticheCancellazione] = useState()
	const [id, setID] = useState()
	const [iban,setIban]= useState()
	const [ibanInfo,setibanInfo]=useState()
  


	function handleClick() {
		history.push("/foto-home");
	}



	const InsApp = () => {

		 if ((errors.iban || errors.ibanInfo)) {
			{ Swal.fire("ATTENZIONE!", errors.iban ? errors.iban : errors.ibanInfo) }
			
		} else if ((!errors.iban && !errors.ibanInfo)) {

		//Setting servizi da mandare al server
		const data2 = require("../Appartamenti/inserimento-appartamento/components/servizi")

		let services = []

	
		for (let i = 0; i < data2.servizi.length; i++) {
			if (checkedState[i] == true) {
				services.push({ "servizio": data2.servizi[i].name, "isPagamento": data2.servizi[i].isPagamento, "id": data2.servizi[i].id })
		
			}
		}






		
		// Output the ID
	
		var IntMaxPeople = parseInt(inputs.MaxPeople)
		for (let i = 0; i < price.length; i++) {
			price[i].date = moment(price[i].date).valueOf()
		}

		

		let appoggio 
		appoggio = ibanInfo.replace(/\n/g, "<br>");

		const IbanCode = {
			"Iban" : iban,
			"Info" : appoggio
		}

		
		let paramsAxios = {
			
			endpoint: env().API_ENDPOINT_URL + apiConfig.insertAppartamento,
			method: "POST",
			body: {
				id: id,
				prenotazioneDiretta: checked,
				descrizione: inputs.Description,
				foto: vettorefoto,
				nome: inputs.Nome,
				politicheCancellazione :"Si ricorda che hai prenotato una tariffa non rimborsabile",
				iban: IbanCode,
				personeMax: IntMaxPeople,
				prenotazioni: [],
				prezzi: price,
				servizi: services,
				locazione: coordinates,
				inPromotion: InPromotion,
				isVisible: isVisible
			}
		}


		callLambda(paramsAxios, res => {


			if (res.status == "200" && res.data.descrizione == "Appartamento inserito correttamente") {
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: 'Il tuo appartamento è stato inserito correttamente',
					showConfirmButton: false,
					timer: 1500
				})
				handleClick()
			}
			else {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
				})
				handleClick()
			}
		}
		



		)
		




	}
	}


	const handleNext = (step) => {

		if (step == 0) {
			{ (errors.MaxPeople || errors.Nome) && Swal.fire("ATTENZIONE!", errors.MaxPeople ? errors.MaxPeople : errors.Nome) }
			(!errors.MaxPeople && !errors.Nome) && setGoSteps(step + 1)
		}
		else if (step == 3) {
			vettorefoto.length == 0 && Swal.fire("ATTENZIONE, devi prima caricare almeno una foto")
			vettorefoto.length > 0 && setGoSteps(step + 1)
		} 
		 else {
			setGoSteps(step + 1)
		}
	}

	useEffect(()=>{
		setID(generateNumericId())
	},[])


	useEffect(() => {
		setRefresh(false)
		let hasErrors = false;
		const newErrors = {};
		if (!inputs.MaxPeople) {
			hasErrors = true;
			newErrors.MaxPeople = 'Devi selezionare prima un valore per il numero massimo di persone';
		}
		if (!inputs.Nome) {
			hasErrors = true;
			newErrors.Nome = 'Campo obbligatorio';
		}

		if (!iban){
			hasErrors = true;
			newErrors.iban = "Devi inserire un Iban";
		}
		if(!ibanInfo){
			hasErrors = true;
			newErrors.ibanInfo = "Devi inserire le info (Causale etc.)";
		}
		setErrors(newErrors);
	
	}, [inputs.Nome, inputs.MaxPeople,iban,ibanInfo]);



	return (
		<>
			{
				refresh ?
					<Loader />
					:
					<div className="row">
						<div className="col-xl-12 col-xxl-12">
							<div className="card">
								<div className="card-header">
									<h4 className="card-title">Passi per l'inserimento dell'appartamento</h4>
								</div>
								<div className="card-body">
									<div className="form-wizard ">
										<Stepper className="nav-wizard"
											activeStep={goSteps}
											label={false}>
											<Step className="nav-link" onClick={() => setGoSteps(0)} />
											<Step className="nav-link" onClick={() => setGoSteps(1)} />
											<Step className="nav-link" onClick={() => setGoSteps(2)} />
											<Step className="nav-link" onClick={() => setGoSteps(3)} />
											<Step className="nav-link" onClick={() => setGoSteps(4)} />
											<Step className="nav-link" onClick={() => setGoSteps(5)} />
										</Stepper>
										{goSteps === 0 && (
											<StepOne isVisible={isVisible} setVisible={setVisible} inputs={inputs} setInputs={setInputs} checked={checked} setChecked={setChecked} errors={errors} InPromotion={InPromotion} setPromotion={setPromotion} />

										)}
										{goSteps === 1 && (
											<StepTwo price={price} calEvents={calEvents} setcalEvents={setcalEvents} setPrice={setPrice} />

										)}
										{goSteps === 2 && (
											<StepThree  politicheCancellazione={politicheCancellazione} setPoliticheCancellazione={setPoliticheCancellazione}
											checkedState={checkedState} setCheckedState={setCheckedState} />

										)}
										{goSteps === 3 && (
											<StepFour id={id} vettoreFoto={vettorefoto} setVettoreFoto={setVettoreFoto} />

										)}
										{goSteps === 4 && (
											<StepFive coordinates={coordinates} setCoordinates={setCoordinates} />

										)}
										{goSteps === 5 && (
											<StepSix  iban={iban} setIban={setIban} ibanInfo={ibanInfo} setibanInfo={setibanInfo} errors={errors}/>

										)}

										<div className="text-end toolbar toolbar-bottom p-2">
											{goSteps > 0 &&
												<button className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(goSteps - 1)}>Precedente</button>
											}
											{goSteps >= 0 && goSteps < 5 &&
												<button className="btn btn-primary sw-btn-next ms-1" onClick={() => { handleNext(goSteps) }}>Successivo</button>
											}
											{goSteps == 5 &&
												<button className="btn btn-primary sw-btn-next ms-1" onClick={() => { InsApp(); }}>Conferma</button>
											}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

			}
		</>

	);
};

export default ApartmentWizard;
