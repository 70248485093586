import React from "react";
import ListaServizi from "./ListaServizi";
import ListaServiziPagamento from "./ListaServiziPagamento";
import moment from "moment";
import IconMoneySVG from "./components/IconMoneySVG";
import IconMasterCardSVG from "./components/IconMasterCardSVG";
import 'moment/locale/it';
moment.locale('it')

function Pagamento(props) {
	return (
		<div className="card-body pt-0">
			<div className="row" style={{ width: '100%' }}>
				<p className="fs-12"></p>
				<p className="fs-12">Prezzo e caparra</p>
				<div className="col-sm-6 col-12">
					<div className="d-flex">
						<IconMoneySVG />
						<h5 className="fs-15 mb-4 ms-3">Prezzo: {props.prenotazione.prezzo === '' ?
							" non inserito" :
							"€" + props.prenotazione.prezzo
						}</h5>
					</div>
				</div>
				<div className="col-sm-6 col-12">
					<div className="d-flex">
						<IconMoneySVG />
						<h5 className="fs-15 mb-4 ms-3">Caparra: {props.prenotazione.acconto === '' ?
							" non inserito" :
							"€" + props.prenotazione.acconto
						}</h5>
					</div>
				</div>
				<p className="fs-12">Pagamento</p>
				<div className="col-sm-12 col-12"
					style={{ marginBottom: "-20px" }}

				>
					<IconMasterCardSVG />
					<h5 className="fs-15 mb-4 ms-3"
						style={{ marginTop: "-21px", paddingLeft: "31px" }}
					>Metodo di pagamento: {props.prenotazione.pagamento === '' ?
						" non inserito" :
						"" + props.prenotazione.pagamento
						}</h5>
				</div>
				<p className="fs-12">Deposito cauzionale</p>
				<div className="col-sm-6 col-12">
					<div className="d-flex">
						<IconMoneySVG />
						<h5 className="fs-15 mb-4 ms-3">Cauzione: {props.prenotazione.cauzione === undefined ?
							" non inserito" :
							"€" + props.prenotazione.cauzione
						}</h5>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Pagamento;
