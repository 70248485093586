import { set } from "date-fns";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const FacilitiesList = ({ titleName }) => {
	return (
		<>
			<div>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="#78D69D" />
				</svg>
				{titleName}
			</div>
		</>
	)
}

function ListaServizi(props) {

	const [rows, setRows] = useState([]);

	useEffect(() => {

		const servizi = props.servizi
		let righe = []
		let colonne = []
		let count = 0

		for (let i = 0; i < Math.ceil(servizi.length / 3); i++) {
			if (count === servizi.length) break
			righe.push(
				<div className="row">
					{colonne}
				</div>
			)
			for (let j = 0; j < 3; j++) {
				if (count === servizi.length) break
				if (servizi[count]['isPagamento'] === false) {
					colonne.push(
						<div className="col-sm-4 col-6">
							<ul>
								<li>
									<FacilitiesList titleName={servizi[count]['servizio']} />
								</li>
							</ul>
						</div>
					)
				}
				count += 1
			}
			colonne = []
		}
		setRows(righe)
	}, []);

	return (
		<div>
			{rows}
		</div>
	)
}
export default ListaServizi;