
import React, { Fragment } from "react";
import {Link} from 'react-router-dom';
import { Row, Card, Col, Button, Nav } from "react-bootstrap";


const StepOneModifica = (props) => {

   const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
       props.setInputs(values => ({...values, [name]: value}))
       
   }
   const handleClick = () => {
      props.setChecked(!(props.checked))
   }

   const handlePromotion = () => {
      props.setPromotion(!(props.inPromotion))
   }

   const handleVisible = () => {
      props.setVisible(!(props.isVisible))
   }

    return (
        <Fragment>
         
          
            <Col xl="6">
              <Card>
                <Card.Body>
                <section>

         

         <div class = "container-fluid">
            <div class="row">
            <div class="col-12 col-md-4">
               <div className="form-group mb-3">
                  <label className="text-label">Nome Appartamento</label>
                  <span className="text-danger">*</span>
                  <input
                     type="text"
                     name="Nome"
                     className="form-control"
                     placeholder="Inserisci nome appartamento"
                     required
                     value={props.inputs.Nome}
                     onChange={handleChange}
                  />
                  <b>
                     {props.errors.Nome && <div className="error">{props.errors.Nome}</div>}
                  </b>
               </div>
            </div>

            <div class="col">
               <label
                  className="form-check-label"
                  htmlFor="checkbox1"
               >Prenotazione Diretta</label>
               
               <div className="form-check custom-checkbox">
                  <input
                     type="checkbox"
                     className="form-check-input"
                     id="checkbox1"
                     checked={props.checked}
                     onClick={handleClick}
                     name="PrenDiretta"

                  />


               </div>            
            </div>

            <div class="col">
               <label
                  className="form-check-label"
                  htmlFor="checkbox2"
               >Visibile </label>
               
               <div className="form-check custom-checkbox">
                  <input
                     type="checkbox"
                     className="form-check-input"
                     id="checkbox2"
                     checked={props.isVisible}
                     onClick={handleVisible}
                     name="isVisible"

                  />


               </div>            
            </div>
            </div>

            
         </div>


     

           
            <div className="container-fluid">
               <div class="row">
            <div className="col-12 col-md-4">
               <div className="form-group mb-3">
                  <label for="MaxPeople">Numero massimo di persone</label>
                  <span className="text-danger">*</span>

                  <select
                     className="form-control"
                     id="sel1"
                     onChange={handleChange}
                     name="MaxPeople"
                     placeholder="Seleziona il numero di persone"
                     defaultValue={props.inputs.MaxPeople}
                  >
                     {props.errors.MaxPeople && console.log("errormax", props.errors.MaxPeople)}
                     {props.errors.MaxPeople && <div className="errorMaax">{props.errors.MaxPeople}</div>}
                     <option disabled selected value>Seleziona il numero massimo di persone</option>

                     <option>1</option>
                     <option>2</option>
                     <option>3</option>
                     <option>4</option>
                     <option>5</option>
                     <option>6</option>
                     <option>7</option>
                     <option>8</option>
                     <option>9</option>
                     <option>10</option>
                     <option>11</option>
                     <option>12</option>
                  </select>

               </div>
            </div>

            <div className="col">
            <label
                  className="form-check-label"
                  htmlFor="checkbox1"
               >In Promozione</label>
               
               <div className="form-check custom-checkbox">
                  <input
                     type="checkbox"
                     className="form-check-input"
                     id="checkbox1"
                     checked={props.inPromotion}
                     onClick={handlePromotion}
                     name="PrenDiretta"

                  />


               </div>
               </div>
               </div>

            </div>

            <div className="container-fluid">
            <div className="col-lg-12 mb-3">
               <div className="form-group mb-3">
                  <label className="text-label">Descrizione </label>
                  <input
                     type="text"
                     name="Description"
                     className="form-control"
                     onChange={handleChange}
                     placeholder="Inserisci una descrizione dell'appartamento"
                     value={props.inputs.Description}
                  />
               </div>
            </div>
            </div>

      {/* Tab per regole di appartamento nel caso francesco le volesse
            <div className="col-lg-12 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Regole </label>
                  <input
                     type="text"
                     name="Rules"
                     className="form-control"
                     placeholder="Inserisci le regole per quest'appartamento"
                     value={props.inputs.Rules}
                     onChange={handleChange}

                  />
               </div>
            </div>
*/}
    
     
                </section>
                </Card.Body>
              </Card>
            </Col>


            
           
          
        </Fragment>
      );
}
export default StepOneModifica