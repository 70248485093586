import React from "react";
import EventCalendar from "../EventCalendar";

const StepTwo = (props) => {
   return (
      <section>
         <EventCalendar price={props.price} setPrice={props.setPrice} calEvents={props.calEvents} setcalEvents={props.setcalEvents} />
      </section>
   );
};

export default StepTwo;
