import moment from "moment";

const listModalForPrenotazioni = (prenotazione) => {
  let lista = [
    {
      titolo: "Data di arrivo",
      nome: "start",
      tipo: "date",
      valore: '',
      placeholder: "Seleziona una data",
      min: moment(new Date()).format('YYYY-MM-DD')
    },
    {
      titolo: "Data di partenza",
      nome: "end",
      tipo: "date",
      valore: '',
      placeholder: "Seleziona una data",
      min: moment(new Date()).add('1', 'days').format('YYYY-MM-DD')
    },
    {
      titolo: "Nome",
      nome: "nome",
      tipo: "text",
      valore: '',
      placeholder: "Inserire un nome"
    },
    {
      titolo: "Cognome",
      nome: "cognome",
      tipo: "text",
      valore: '',
      placeholder: "Inserire un cognome"
    },

    {
      titolo: "Telefono",
      nome: "telefono",
      tipo: "tel",
      valore: '',
      placeholder: "Inserire il numero di telefono"
    },
    {
      titolo: "E-Mail",
      nome: "mail",
      tipo: "email",
      valore: '',
      placeholder: "Inserire una mail valida"
    },
    {
      titolo: "Numero di adulti",
      nome: "numeroAdulti",
      tipo: "number",
      valore: '',
      placeholder: "Inserire il numero di adulti",
      min: 0
    },
    {
      titolo: "Numero di bambini",
      nome: "numeroBambini",
      tipo: "number",
      valore: '',
      placeholder: "Inserire il numero di bambini",
      min: 0
    },
    {
      titolo: "Check-in",
      nome: "checkin",
      tipo: "time",
      valore: '',
      placeholder: "Inserire l'orario di check-in"
    },
    {
      titolo: "Check-out",
      nome: "checkout",
      tipo: "time",
      valore: '',
      placeholder: "Inserire l'orario di check-out"
    },
    {
      titolo: "Prezzo",
      nome: "prezzo",
      tipo: "number",
      valore: '',
      placeholder: "Inserire il prezzo della prenotazione"
    }
  ]

  return lista
}

export { listModalForPrenotazioni };