import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const checkErrorePrenotazione = (prenotazione, personeMax) => {

    let regexNomeCognome = /^[a-zA-Z\s]+$/;
    let regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let trovatoErrore = { valore: false }

    if (prenotazione === "") {
        trovatoErrore = {
            valore: true,
            message: "Non hai selezionato nessun appartamento"
        }
    }
    if (!regexNomeCognome.test(prenotazione.nome)) {
        trovatoErrore = {
            valore: true,
            message: "Il nome non può essere vuoto e non deve contenere carattere speciali o numeri"
        }

    }
    if (!regexNomeCognome.test(prenotazione.cognome)) {
        trovatoErrore = {
            valore: true,
            message: "Il cognome non può essere vuoto e non deve contenere carattere speciali o numeri"
        }
    }
    if (!regexEmail.test(prenotazione.mail)) {
        trovatoErrore = {
            valore: true,
            message: "La mail deve essere nel formato user@dominio"
        }

    }

    if (personeMax < (Number(prenotazione.numeroAdulti) + Number(prenotazione.numeroBambini))) {
        trovatoErrore = {
            valore: true,
            message: "Per l'appartamento selezionato non possono esserci più di "
                + personeMax + " persone"
        }
    }

    if (Number(prenotazione.prezzo) === 0) {
        trovatoErrore = {
            valore: true,
            message: "Il prezzo non può essere pari a 0!"
        }
    }
    if (Number(prenotazione.numeroAdulti) === 0) {
        trovatoErrore = {
            valore: true,
            message: "Hai inserito 0 adulti per l'appartamento selezionato"
        }

    }
    if (moment(prenotazione.start).isSameOrAfter(moment(prenotazione.end))) {
        trovatoErrore = {
            valore: true,
            message: "Hai inserito una data di partenza precedente alla data di arrivo"
        }
    }
    return trovatoErrore
}

const colorWeekend = (schedulerData) => {
    schedulerData.headers.map((header) => {
        console.log(moment(header.time).format('dddd, D MMMM YYYY'))
        header['nonWorkingTime'] = false
        if (moment(header.time).format('dddd, D MMMM YYYY').includes('sabato') ||
            moment(header.time).format('dddd, D MMMM YYYY').includes('domenica')
        ) {
            header['nonWorkingTime'] = true
        }
    })
    return schedulerData
}

const buildPrenotazioneBody = (prenotazione, prenotazioneID, rooms, stato) => {

    console.log("prenotazione", prenotazione)
    console.log("prenotazione.start", prenotazione.start)
    console.log("prenotazione.start dopo moment", moment(prenotazione.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().unix() * 1000)

    let start = moment(prenotazione.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().unix() * 1000
    let end = moment(prenotazione.end).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).add(-1, 'days').utc().unix() * 1000;

    console.log("start", start)
    console.log("end", end)

    let prenotazioneBody = {
        id: prenotazioneID,
        title: prenotazione.nome + " " + prenotazione.cognome,
        start: start,
        end: end,
        nome: prenotazione.nome,
        cognome: prenotazione.cognome,
        mail: prenotazione.mail,
        bgColor: prenotazione.bgColor,
        checkin: prenotazione.checkin,
        checkout: prenotazione.checkout,
        resourceId: prenotazione.resourceId,
        numeroAdulti: prenotazione.numeroAdulti,
        numeroBambini: prenotazione.numeroBambini,
        telefono: prenotazione.telefono,
        acconto: prenotazione.acconto,
        pagamento: prenotazione.pagamento,
        noteBestHouse: prenotazione.noteBestHouse,
        noteCliente: prenotazione.noteCliente,
        stato: prenotazione.stato,
        servizi: prenotazione.servizi,
        prezzo: prenotazione.prezzo,
        movable: false,
        resizable: false,
        showPopover: true,
        timestamp: moment(new Date()).utc().unix() * 1000
    }

    console.log("prenotazioneBody", prenotazioneBody)


    if (prenotazione.stato === "NON_APPROVATA") {
        if (Object.keys(prenotazioneBody.servizi).length === 0) {
            rooms.map((room) => {
                if (prenotazioneBody['resourceId'] === room['id']) {
                    room.servizi.map((x) => {
                        if (x['isPagamento'] === true) {
                            prenotazioneBody['servizi'][x['servizio']] = false
                        }
                    })
                }
            })
        }
    }

    if (stato === "CANCELLATA") {
        prenotazioneBody['stato'] = "CANCELLATA"
    }

    return prenotazioneBody

}

const buildNewPrenotazione = (start, end, roomId) => {

    let newIDPrenotazione = uuidv4()

    let newPrenotazione = {
        id: newIDPrenotazione,
        start: start === '' ? '' : moment(start).format('YYYY-MM-DD'),
        end: end === '' ? '' : moment(end).format('YYYY-MM-DD'),
        resourceId: roomId,
        nome: '',
        cognome: '',
        mail: '',
        numeroAdulti: 0,
        numeroBambini: 0,
        telefono: 0,
        prezzo: 0,
        checkin: moment(new Date()).set({ hour: 16, minute: 0, second: 0, millisecond: 0 }).format('HH:mm'),
        checkout: moment(new Date()).set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).format('HH:mm'),
        bgColor: '#ffa755',
        servizi: {},
        acconto: 0,
        pagamento: '',
        noteBestHouse: '',
        noteCliente: '',
        stato: "NON_APPROVATA"
    }

    return newPrenotazione
}

export {
    checkErrorePrenotazione,
    buildPrenotazioneBody,
    buildNewPrenotazione,
    colorWeekend
}
