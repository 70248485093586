import React from "react";
import { Link } from "react-router-dom";
import FotoAppartamento from "../FotoAppartamento";

const StepFour = (props) => {
   return (
      <section>

         <FotoAppartamento
            vettoreFoto={props.vettoreFoto}
            setVettoreFoto={props.setVettoreFoto}
            id={props.id}

         />

      </section>
   );
};

export default StepFour;
