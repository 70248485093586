import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { Component } from "react";
import swal from "sweetalert";
import env from '../../../../../envConfig'
import apiConfig from '../../../../../API/apiConfig'
import {
    callLambda, callLambdaS3
} from "../../../../../API/api"

export default class UpdateFoto extends Component {
    state = {
        fileList: [],
        fileListOld: [],
        fileListNew: [],
        uploading: false,
    };

    componentDidMount() {
        //console.log("ciao sono qui", this.props.fileList)
        this.setState({
            fileList: this.props.fileList,
            fileListOld: this.props.fileList
        })
    }


    handleUpload = async () => {
        const { fileList } = this.state;
        const { fileListOld } = this.state;

        let fileListOldSetType = new Set(fileListOld)

        const difference = fileList.filter(x => fileListOldSetType.has(x)); //file vecchi mai toccati
        const newPresent = fileList.filter(x => !fileListOldSetType.has(x)); //file nuovi aggiunti ai vecchi

        console.log("differenceOLD", difference)
        console.log("NewPresent", newPresent)
        console.log("fileListOld", fileListOld)

        //significa che almeno uno dei vecchi file non c'è piu!! 
        // -> sta nel vettore diffence direttamente

        let paramsPath = []

        for (let i = 0; i < newPresent.length; i++) {
            paramsPath.push({
                "folder": env().dirFotoBanner,
                "name": newPresent[i].name
            })
        }

        //console.log(paramsPath)

        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.getS3URL,
            method: "POST",
            promiseAll: true,
            vettorePromiseAll: paramsPath
        }

        //getS3URL(paramsPath, s3URLList => {


        callLambda(paramsAxios, res => {
            //console.log(res)
            let s3URLList = []
            res.map((elem) => s3URLList.push(elem.data.risultato))

            //console.log(s3URLList)

            this.setState({
                uploading: true,
            });

            //mi preparo i file da uploadare, stavolta saranno quelli di presentNew
            let fileListPresent = []

            for (let i = 0; i < newPresent.length; i++) {
                fileListPresent.push(newPresent[i])
            }
            //console.log("prima di s3")

            let paramsAxios = {
                endpoint: s3URLList,
                method: "PUT",
                promiseAll: true,
                vettorePromiseAll: fileListPresent
            }

            //carico su S3 i nuovi file
            callLambdaS3(paramsAxios, res => {
                //uploadS3(s3URLList, fileListPresent, res => {
                //console.log("res", res)

                //mi preparo i file vecchi da ritornare
                let returnArrayLinkFoto = []
                for (let i = 0; i < difference.length; i++) {
                    returnArrayLinkFoto.push(difference[i].foto)
                }

                //mi preparo i file nuovi da ritornare
                let linkFoto = ""
                let file = ""
                for (let i = 0; i < res.length; i++) {
                    file = newPresent[i] //questo è sicuro perché ci saranno N risposte per N file provati ad uploadare
                    linkFoto = 'https://' + env().bucketName + '.s3.' + env().aws_project_region + '.amazonaws.com/' +
                        env().dirFotoBanner + env().separatoreFoto + file.name;
                    if (res[i].status === 200) {
                        //per ogni risposta 200 ricevuta, costruisco il dizionario della foto da salvare nel db
                        //console.log("caricato!")

                        returnArrayLinkFoto.push(linkFoto)
                    }
                }

                if (returnArrayLinkFoto.length === fileList.length) {
                    //tutto ok fratm
                    //console.log("tutto ok fratm!")
                    this.props.setPhoto(returnArrayLinkFoto)
                } else {
                    //gestire qui con un error
                    swal("Attenzione!", "Qualcosa è andato storto durante il caricamento. Riprova!", "error")
                }

                this.setState({
                    uploading: false,
                });

            })
        });




    };

    render() {
        const { uploading, fileList, fileListOld, fileListNew } = this.state;
        const props = {
            onRemove: file => {
                //console.log("sono in onRemove")

                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    //console.log("newFileList", newFileList)
                    newFileList.splice(index, 1);
                    //console.log("newFileList dopo split", newFileList)

                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                //console.log("file", file)
                if (file.type.includes('image')) {
                    let name = file.name
                    let trovato = false;
                    for (let fileN of fileList) {
                        if (fileN.name === name) {
                            trovato = true
                        }
                    }
                    if (trovato) {
                        swal("Attenzione!", "Hai inserito un file con un nome già presente. Modifica il nome del file!", "error")
                    } else {
                        this.setState(state => ({
                            fileList: [...state.fileList, file],
                            fileListNew: [...state.fileListNew, file]
                        }));
                        return false;
                    }
                } else {
                    swal("Attenzione!", "Hai inserito un file non valido!", "error")
                }
            },
            fileList,
        };

        return (
            <div>
                <Upload {...props}>
                    <Button>
                        <UploadOutlined /> Seleziona una nuova foto - update
                    </Button>
                </Upload>
                <Button
                    type="primary"
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0 || fileList.length === (fileListOld.length - fileListNew.length)}
                    loading={uploading}
                    style={{ marginTop: 16 }}
                >
                    {uploading ? 'Caricamento in corso' : 'Carica foto'}
                </Button>
            </div>
        );
    }
}