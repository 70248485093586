import React from "react";
import ClipLoader from 'react-spinners/ClipLoader';

const Loader = () => {
    return (
        <div className="loader-container" style={{ textAlign: "center" }}>
            <ClipLoader
                color={'#34BAE2'}
                size={150} />
        </div>
    )
}

export default Loader;