import * as React from "react"

const IconMasterCardSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 496"
    xmlSpace="preserve"
    {...props}
    style={{width: "7%", height: "50%"}}
  >
    <path
      style={{
        fill: "#12db55",
      }}
      d="M496 379.6c0 9.6-7.2 16.8-16.8 16.8H16.8c-9.6 0-16.8-7.2-16.8-16.8v-264c0-9.6 7.2-16.8 16.8-16.8h462.4c9.6 0 16.8 7.2 16.8 16.8v264z"
    />
    <path
      style={{
        fill: "#12db55",
      }}
      d="M496 379.6c0 9.6-7.2 16.8-16.8 16.8H16.8c-9.6 0-16.8-7.2-16.8-16.8v-264c0-9.6 7.2-16.8 16.8-16.8h462.4c9.6 0 16.8 7.2 16.8 16.8v264z"
    />
    <path
      style={{
        fill: "#0ac945",
      }}
      d="M0 115.6c0-9.6 7.2-16.8 16.8-16.8h462.4c9.6 0 16.8 7.2 16.8 16.8v264.8c0 9.6-7.2 16.8-16.8 16.8"
    />
    <path
      style={{
        fill: "#334449",
      }}
      d="M0 118.8h496v96.8H0z"
    />
    <path d="M170.4 215.6H496v-96.8H5.6z" />
    <path
      style={{
        fill: "#0cc69a",
      }}
      d="M496 379.6c0 9.6-7.2 16.8-16.8 16.8H16.8c-9.6 0-16.8-7.2-16.8-16.8"
    />
    <path
      style={{
        fill: "#0baf84",
      }}
      d="M479.2 396.4c9.6 0 16.8-7.2 16.8-16.8h-44.8l28 16.8z"
    />
    <path
      style={{
        fill: "#d4f9ed",
      }}
      d="M177.6 264.4c0 3.2-2.4 5.6-5.6 5.6H52.8c-3.2 0-5.6-2.4-5.6-5.6 0-3.2 2.4-5.6 5.6-5.6H172c3.2 0 5.6 2.4 5.6 5.6zM177.6 293.2c0 3.2-2.4 5.6-5.6 5.6H52.8c-3.2 0-5.6-2.4-5.6-5.6.8-3.2 3.2-5.6 5.6-5.6H172c3.2 0 5.6 2.4 5.6 5.6zM154.4 322c0 3.2-2.4 5.6-5.6 5.6h-96c-2.4 0-4.8-2.4-4.8-5.6 0-3.2 2.4-5.6 5.6-5.6h96c2.4.8 4.8 3.2 4.8 5.6z"
    />
    <circle
      style={{
        fill: "#ffbc00",
      }}
      cx={360}
      cy={300.4}
      r={60}
    />
    <path
      style={{
        fill: "#fa0",
      }}
      d="M360 240.4c-30.4 0-55.2 22.4-60 51.2l96.8 56.8c14.4-11.2 23.2-28 23.2-48 .8-33.6-26.4-60-60-60z"
    />
    <path
      style={{
        fill: "#f7b208",
      }}
      d="M360 240.4c33.6 0 60 27.2 60 60s-27.2 60-60 60"
    />
    <circle
      style={{
        fill: "#f20a41",
      }}
      cx={408}
      cy={300.4}
      r={60}
    />
    <circle
      style={{
        fill: "#f20a41",
      }}
      cx={408}
      cy={300.4}
      r={60}
    />
    <path
      style={{
        fill: "#e00040",
      }}
      d="M408 361.2c-33.6 0-60-27.2-60-60s27.2-60 60-60"
    />
    <path
      style={{
        fill: "#f97803",
      }}
      d="M384 245.2c-21.6 9.6-36 30.4-36 55.2s15.2 46.4 36 55.2c21.6-9.6 36-30.4 36-55.2s-14.4-46.4-36-55.2z"
    />
    <path
      style={{
        fill: "#f76806",
      }}
      d="M384 355.6c21.6-9.6 36-30.4 36-55.2s-15.2-46.4-36-55.2"
    />
  </svg>
)

export default IconMasterCardSVG
