import React, { useEffect, useState, useLayoutEffect } from "react";

import { Card } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";
import CalendarPrenotazioni from "./inserisci-prenotazione/CalendarPrenotazioni";
import moment from "moment";
import apiConfig from "../../../API/apiConfig";
import env from "../../../envConfig";
import { callLambda } from "../../../API/api";
import { dateFromTimestampToNormalFormat, getDay } from "./utils/utils";
import Loader from "../../components/Loader";
const InserisciPrenotazione = () => {

   const [refresh, setRefresh] = useState(true);
   const [size, setSize] = useState([0, 0]);
   const [rooms, setRooms] = useState([]);
   const [prenotazioni, setPrenotazioni] = useState([]);
   const [isMobile, setIsMobile] = useState(
      document.querySelector("body").getAttribute("data-sidebar-style") === "mini" ||
      document.querySelector("body").getAttribute("data-sidebar-style") === "overlay"
   );





   useEffect(() => {

      let sundayStartWeek = moment(getDay("firstDay", null, "Month")).startOf('day').utc().unix() * 1000
      //l'aggiunta di 1 minuto mi permette di prendere le prenotazioni che continuano durante il weekend!!
      let saturdayEndWeek = moment(getDay("lastDay", null, "Month")).endOf('day').add('1', 'minutes').utc().unix() * 1000

      console.log("sundayStartWeek", sundayStartWeek)
      console.log("saturdayEndWeek", saturdayEndWeek)

      let filtro = 'nome,id,personeMax,servizi'
      let paramsAxios = {
         endpoint: env().API_ENDPOINT_URL + apiConfig.getRooms,
         method: "GET",
         queryParams: "?filtro=" + filtro
      }

      let rooms = []
      callLambda(paramsAxios, res => {
         console.log("[" + paramsAxios['endpoint'] + "]", res)
         if (res.data.esito === "OK") {
            res.data.risultato.map((room) => {
               rooms.push(room);
            });

            rooms.map((x) => {
               x['name'] = x['nome'];
               delete x['nome']
            })

            let paramsAxios = {
               endpoint: env().API_ENDPOINT_URL + apiConfig.getPrenotazioni,
               method: "GET",
               queryParams: "?dataInizio=" + sundayStartWeek + "&dataFine=" + saturdayEndWeek
            }

            callLambda(paramsAxios, res => {
               console.log("[" + paramsAxios['endpoint'] + "]", res)
               let prenotazioni = []
               if (res.data.esito === "OK") {
                  let roomsPrenotazioni = res.data.risultato
                  roomsPrenotazioni.map((room) => {
                     room.prenotazioni.map((prenotazione) => {
                        if (prenotazione['stato'] !== 'CANCELLATA') {
                           prenotazioni.push(prenotazione)
                        }
                     })
                  })

                  prenotazioni.map((prenotazione) => {
                     prenotazione = dateFromTimestampToNormalFormat(prenotazione)
                  })

                  console.log("rooms = ", rooms)
                  console.log("prenotazioni = ", prenotazioni)

                  setRooms(rooms);
                  setPrenotazioni(prenotazioni)
                  setRefresh(false)

               }
            });

         } else {

         }
      });

   }, []);

   useLayoutEffect(() => {
      function updateSize() {
         setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
   }, []);

   return (
      <div className="h-80">
         <PageTitle activeMenu="Inserisci una nuova prenotazione" motherMenu="Prenotazioni" />

         <div>
            <div className="row">
               <div className="col-xl-12 col-xxl-12" id="testCol">
                  <Card>
                     <Card.Header>
                        <Card.Title>Gestione delle prenotazioni</Card.Title>
                     </Card.Header>
                     <Card.Body id='cardManu'>
                        {refresh ?
                           <Loader />
                           :
                           <CalendarPrenotazioni
                              width={document.getElementById('testCol').offsetWidth}
                              rooms={rooms}
                              prenotazioni={prenotazioni}
                              isMobile={isMobile}
                           />

                        }
                     </Card.Body>
                  </Card>
               </div>
            </div>
         </div>
      </div>
   );
};

export default InserisciPrenotazione;
