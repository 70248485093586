import React, { Component } from "react";
import { Row } from "react-bootstrap";
import PeriodoSconti from "./PeriodoSconti";
import CalendarioGiorni from "./CalendarioGiorni";
import moment from "moment";
import env from '../../../../envConfig'
import apiConfig from '../../../../API/apiConfig'
import { callLambda } from '../../../../API/api'
import Loader from "../../../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
class EventCalendar extends Component {

   constructor(props) {
      super(props);
      this.forceUpdatePeriodi = this.forceUpdatePeriodi.bind(this);

   }
   state = {
      periodi: [],
      scontistica: [],
      refresh: true,
      refreshPeriodi: false,
      refreshCalendario: false,
   };


   notifyBottomLeft = (message) => {
      toast.error("❌ " + message + "!", {
         position: "bottom-left",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: false,
         draggable: false,
         progress: undefined,
      });
   };

   componentDidMount() {

      let filtro = 'periodi,scontistica'
      let paramsAxios = {
         endpoint: env().API_ENDPOINT_URL + apiConfig.getSconti,
         method: "GET",
         queryParams: "?anno=" + moment().year() + "&filtro=" + filtro
      }

      callLambda(paramsAxios, res => {
         console.log("[" + paramsAxios['endpoint'] + "]", res)
         if (res.data.esito === "OK") {

            console.log("res", res)
            let scontisticaCalendar = []
            let scontisticaArray = []
            let scontistica = []
            let periodi = []
            let nomePeriodo = ''
            if (res.data.risultato.periodi !== undefined) {
               periodi = res.data.risultato.periodi;

            }
            if (res.data.risultato.scontistica !== undefined) {
               scontistica = res.data.risultato.scontistica;
               scontisticaArray = Object.entries(scontistica)
               for (let j = 0; j < periodi.length; j++) {
                  for (let i = 0; i < scontisticaArray.length; i++) {
                     if (scontisticaArray[i][1] === periodi[j]['id']) {
                        nomePeriodo = periodi[j]['title']
                        scontisticaCalendar.push({
                           id: "id-" + i,
                           title: nomePeriodo,
                           start: moment(scontisticaArray[i][0]).format("YYYY-MM-DD")
                        })
                     }
                  }
               }
            }


            this.setState({ periodi: periodi, scontistica: scontisticaCalendar, refresh: false })
         }

         else {

         }
      });
   }

   forceUpdatePeriodi = (arg) => {
      console.log("arg", arg)
      if (arg.scontistica !== undefined) {
         this.setState({ scontistica: arg.scontistica })
      }
      this.setState({ periodi: arg['periodi'], refreshPeriodi: true }, () => {
         this.setState({ refreshPeriodi: false })
      })
   }

   forceUpdateScontiCalendario = (arg) => {

      let scontisticaCalendar = []
      let scontistica = arg;
      let scontisticaArray = Object.entries(scontistica)
      for (let i = 0; i < scontisticaArray.length; i++) {
         scontisticaCalendar.push({
            id: "id-" + i,
            title: scontisticaArray[i][1],
            start: moment(scontisticaArray[i][0]).format("YYYY-MM-DD")
         })
      }
      this.setState({ scontistica: scontisticaCalendar, refreshCalendario: true }, () => {
         this.setState({ refreshCalendario: false })
      })
   }

   render() {

      return (
         <div className="animated fadeIn demo-app">
            <Row>
               {this.state.refresh ?
                  <Loader />

                  :
                  <>

                     {this.state.refreshPeriodi ?
                        <div>
                           aspetto
                        </div> :
                        <>
                           <PeriodoSconti
                              periodi={this.state.periodi}
                              forceUpdatePeriodi={this.forceUpdatePeriodi}
                           />
                           <CalendarioGiorni
                              scontistica={this.state.scontistica}
                              periodi={this.state.periodi}
                           />
                        </>
                     }



                  </>
               }


            </Row >

  
         </div >
      );
   }
}

export default EventCalendar;
