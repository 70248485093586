const filtraPrenotazioniByStato = (entries) => {
    //finito di ordinare gli entries, rimuovo tutti quelli con stato: ATTESA_PAGAMENTO e li metto subito dopo NON_APPROVATA
    for (var i = entries.length - 1; i >= 0; i--) {
        if (entries[i]['statoFiltro'] === "ATTESA_PAGAMENTO") {
            console.log("entris[i] : ", entries[i])
            var rimosso = entries.splice(i, 1);
            entries.push(rimosso[0]);
        }
    }

    //finito di ordinare gli entries, rimuovo tutti quelli con stato: CANCELLATA e li metto subito dopo ATTESA_PAGAMENTO
    for (var i = entries.length - 1; i >= 0; i--) {
        if (entries[i]['statoFiltro'] === "CANCELLATA") {
            console.log("entris[i] : ", entries[i])
            var rimosso = entries.splice(i, 1);
            entries.push(rimosso[0]);
        }
    }

    //finito di ordinare gli entries, rimuovo tutti quelli con stato: CONFERMATA e li metto subito dopo CANCELLATA
    for (var i = entries.length - 1; i >= 0; i--) {
        if (entries[i]['statoFiltro'] === "CONFERMATA") {
            console.log("entris[i] : ", entries[i])
            var rimosso = entries.splice(i, 1);
            entries.push(rimosso[0]);
        }
    }

    //finito di ordinare gli entries, rimuovo tutti quelli con stato: CANCELLATA || TERMINATA e li metto in fondo
    for (var i = entries.length - 1; i >= 0; i--) {
        if (entries[i]['statoFiltro'] === "TERMINATA") {
            console.log("entris[i] : ", entries[i])
            var rimosso = entries.splice(i, 1);
            entries.push(rimosso[0]);
        }
    }

    return entries;
}

module.exports = filtraPrenotazioniByStato;