import React from "react";
import { Link } from "react-router-dom";
import FotoAppartamento from "../FotoAppartamento";

const StepFour = (props) => {
   return (
      <section>
         <div className="row emial-setup">
            <FotoAppartamento vettoreFoto={props.vettoreFoto} setVettoreFoto={props.setVettoreFoto} />
         </div>
      </section>
   );
};

export default StepFour;
