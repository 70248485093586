import React, { Fragment, useEffect, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import { useHistory } from "react-router-dom";

import StepOne from "./inserimento-appartamento/step-appartamento/StepOne";
import StepTwo from "./inserimento-appartamento/step-appartamento/StepTwo"
import StepThree from "./inserimento-appartamento/step-appartamento/StepThree";
import StepFour from "./inserimento-appartamento/step-appartamento/StepFour";
import ApartmentMap from "./inserimento-appartamento/step-appartamento/StepFive";
import PageTitle from "../../layouts/PageTitle";
import { callLambda } from "../../../API/api";
import uuid from 'react-native-uuid';
import { input } from "aws-amplify";
import env from "../../../envConfig";
import apiConfig from "../../../API/apiConfig";
import { servizi } from "../Appartamenti/inserimento-appartamento/components/servizi";
import randomColor from "randomcolor";
import moment from "moment";
const Swal = require('sweetalert2')

function generateNumericId() {
	// Get the current timestamp
	var timestamp = Date.now();

	// Generate a random number between 1 and 999
	var randomNumber = Math.floor(Math.random() * 999) + 1;

	// Return the unique numeric ID
	return timestamp + randomNumber;
}

const ApartmentWizardServizi = () => {
	const history = useHistory()
	const [refresh, setRefresh] = useState([])

	const [goSteps, setGoSteps] = useState(0);
	const [inputs, setInputs] = useState({});
	const [checked, setChecked] = useState(false);
	const [price, setPrice] = useState([]);
	const [calEvents, setcalEvents] = useState([])
	const [services, setServices] = useState([])
	const [vettorefoto, setVettoreFoto] = useState([])
	const [checkedState, setCheckedState] = useState(
		new Array(servizi.length).fill(false)
	);
	const [coordinates, setCoordinates] = useState({})
	const [errors, setErrors] = useState({});
	const [id,setID] = useState()

	function handleClick() {
		history.push("/foto-home");
	}

	const InsApp = () => {

		
		// Output the ID
		console.log(id);
		var IntMaxPeople = parseInt(inputs.MaxPeople)

		console.log("prova inserimento appartamento")
		let paramsAxios = {
			endpoint: env().API_ENDPOINT_URL + apiConfig.insertVendita,
			method: "POST",
			body: {
				id: id,
				cancellazione: checked,
				descrizione: inputs.Description,
				foto: vettorefoto,
				nome: inputs.Nome,
				personeMax: IntMaxPeople,
				locazione: coordinates
			}
		}

		//getS3URL(paramsPath, s3URLList => {


		callLambda(paramsAxios, res => {
			console.log("res", res)
			if (res.status == "200" && res.data.descrizione == "Foto inserite correttamente!") {
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: 'Il tuo appartamento è stato inserito correttamente',
					showConfirmButton: false,
					timer: 1500
				})
				handleClick()
			}
			else {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
				})
			}
		}
		)

	}


	const handleNext = (step) => {
		var IntMaxPeople = parseInt(inputs.MaxPeople)

		let body = {
			cancellazione: checked,
			descrizione: inputs.Description,
			foto: vettorefoto,
			nome: inputs.Nome,
			personeMax: IntMaxPeople,
			locazione: coordinates
		}

		console.log(body)

		if (step == 0) {
			{ (errors.MaxPeople || errors.Nome) && Swal.fire("ATTENZIONE!", errors.MaxPeople ? errors.MaxPeople : errors.Nome) }
			(!errors.MaxPeople && !errors.Nome) && setGoSteps(step + 1)
		}
		else if (step == 1) {
			vettorefoto.length == 0 && Swal.fire("ATTENZIONE, devi prima caricare almeno una foto")
			vettorefoto.length > 0 && setGoSteps(step + 1)
		} else {
			setGoSteps(step + 1)
		}
	}

	useEffect(()=>{
		setID(generateNumericId())
	},[])

	useEffect(() => {
		setRefresh(false)

		let hasErrors = false;
		const newErrors = {};
		if (!inputs.MaxPeople) {
			hasErrors = true;
			newErrors.MaxPeople = 'Devi selezionare prima un valore per il numero massimo di persone';
		}
		if (!inputs.Nome) {
			hasErrors = true;
			newErrors.Nome = 'Campo obbligatorio';
		}
		setErrors(newErrors);
		console.log("errori", errors)
		console.log("gostep", goSteps)
	}, [inputs.Nome, inputs.MaxPeople]);
	return (

		<>
			{
				refresh ?
					<div></div>
					:
					<div className="row">
						<div className="col-xl-12 col-xxl-12">
							<div className="card">
								<div className="card-header">
									<h4 className="card-title">Passi per l'inserimento dell'appartamento</h4>
								</div>
								<div className="card-body">

									<div className="form-wizard ">
										<Stepper id="miotest" className="nav-wizard" activeStep={goSteps} label={false}>
											<Step className="nav-link" onClick={() => setGoSteps(0)} />
											<Step className="nav-link" onClick={() => setGoSteps(1)} />
											<Step className="nav-link" onClick={() => setGoSteps(2)} />
										</Stepper>
										{goSteps === 0 &&
											<StepOne inputs={inputs} setInputs={setInputs} checked={checked} setChecked={setChecked} errors={errors} />
										}
										{goSteps === 1 &&
											<StepFour id={id} vettoreFoto={vettorefoto} setVettoreFoto={setVettoreFoto} />
										}
										{goSteps === 2 &&
											<ApartmentMap coordinates={coordinates} setCoordinates={setCoordinates} />
										}

										<div className="text-end toolbar toolbar-bottom p-2">
											{goSteps > 0 &&
												<button className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(goSteps - 1)}>Precedente</button>
											}
											{goSteps >= 0 && goSteps <= 1 &&
												<button className="btn btn-primary sw-btn-next ms-1" onClick={() => { handleNext(goSteps) }}>Successivo</button>
											}
											{goSteps == 2 &&
												<button className="btn btn-primary sw-btn-next ms-1" onClick={() => { InsApp(); }}>Conferma</button>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
			}
		</>
	);
};

export default ApartmentWizardServizi;
