import React from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";

function HeaderDettagli(props) {
	return (
		<div className="card-header border-0 pb-0">
			<h4 className="fs-24 font-w500">Dettagli</h4>
			{props.statoPrenotazione === 'NON_APPROVATA' ?
				<Button
					className="me-2"
					variant="warning btn-rounded"
					disabled="disabled"
				>
					NON CONFERMATA
				</Button>
				:
				props.statoPrenotazione === "ATTESA_PAGAMENTO" ?
					<Button
						className="me-2"
						variant="dark btn-rounded"
						disabled="disabled"
					>
						ATTESA DI PAGAMENTO
					</Button>
					:
					props.statoPrenotazione === "CONFERMATA" ?
						<Button
							className="me-2"
							variant="success btn-rounded"
							disabled="disabled"
						>
							CONFERMATA
						</Button>
						:
						props.statoPrenotazione === "CANCELLATA" ?
							<Button
								className="me-2"
								variant="danger btn-rounded"
								disabled="disabled"
							>
								CANCELLATA
							</Button>
							:
							<Button variant="info btn-rounded" disabled="disabled">
								TERMINATA CON SUCCESSO
							</Button>

			}

		</div>
	)
}
export default HeaderDettagli;