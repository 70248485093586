import React, { Component } from "react";
import { Col, Row, Card, Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import ModalPeriodoSconti from "./Modal/ModalPeriodoSconti";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import env from '../../../../envConfig'
import apiConfig from '../../../../API/apiConfig'
import { callLambda } from '../../../../API/api'
import { checkErrorePeriodoSconto } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
class PeriodoSconti extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        editModal: false,
        checkEditModal: false,
        editFormData: {
            id: "",
            offerte: [],
            title: "",
            nome: ""
        },
        modificaEliminaButton: false,
        events: [],
        listEventsForms: [
            {
                titolo: "Anno scontistica",
                nome: "anno",
                tipo: "select",
                placeholder: "Inserire un anno",
                valore: '',
            },
            {
                titolo: "Nome periodo",
                nome: "title",
                tipo: "text",
                placeholder: "Inserire un nome",
                valore: '',
            },
            {
                titolo: "Inserire uno sconto per 4-6 giorni",
                nome: "durata_4_6",
                tipo: "number",
                placeholder: "Inserire sconto"
            },
            {
                titolo: "Inserire uno sconto per una settimana (+7)",
                nome: "durata_6_14",
                tipo: "number",
                placeholder: "Inserire sconto"
            },
            {
                titolo: "Inserire uno sconto per le due settimane (+14)",
                nome: "durata_14_28",
                tipo: "number",
                placeholder: "Inserire sconto"
            },
            {
                titolo: "Inserire uno sconto per un mese (+28)",
                nome: "durata_28",
                tipo: "number",
                placeholder: "Inserire sconto"
            }
        ]
    };


    componentDidMount = () => {
        this.setState({ events: this.props.periodi })
        console.log("this.props.periodi", this.props.periodi)
    }

    handleSelectClick = arg => {
        console.log(arg)
    }


    onClickPeriodo = (newEvent) => {

        const formValues = newEvent
        console.log("formValues", formValues)

        let listaFormsModal = this.state.listEventsForms

        //questo for lo faccio per inizializzare i valori proveniente da newEvent!!
        for (let i = 0; i < listaFormsModal.length; i++) {
            for (var key in newEvent) {
                if (listaFormsModal[i].nome === key) {

                    listaFormsModal[i].valore = newEvent[key]

                }
            }
        }

        for (let i = 0; i < listaFormsModal.length; i++) {
            if (listaFormsModal[i]['nome'] === "durata_4_6") {
                listaFormsModal[i].valore = newEvent['offerte'][0]['sconto']
            } else if (listaFormsModal[i]['nome'] === "durata_6_14") {
                listaFormsModal[i].valore = newEvent['offerte'][1]['sconto']
            } else if (listaFormsModal[i]['nome'] === "durata_14_28") {
                listaFormsModal[i].valore = newEvent['offerte'][2]['sconto']
            } else if (listaFormsModal[i]['nome'] === "durata_28") {
                listaFormsModal[i].valore = newEvent['offerte'][3]['sconto']
            }
        }

        formValues['durata_4_6'] = newEvent['offerte'][0]['sconto']
        formValues['durata_6_14'] = newEvent['offerte'][1]['sconto']
        formValues['durata_14_28'] = newEvent['offerte'][2]['sconto']
        formValues['durata_28'] = newEvent['offerte'][3]['sconto']


        console.log("listaFormsModal", listaFormsModal)

        this.setState({
            editModal: false, editFormData: formValues, listEventsForms: listaFormsModal, modificaEliminaButton: true
        }, () => {
            this.setState({ editModal: true })
        })

    }

    onNewPeriodo = () => {

        let newIDPeriodo = uuidv4()

        let newPeriodo = {
            id: newIDPeriodo,
            offerte: [],
            anno: "",
            title: "",
            nome: ""
        }

        this.setState({ editModal: true })

        this.handleEditClick(newPeriodo);
    }

    handleEditFormDelete = (event) => {
        console.log("editFormData", this.state.editFormData)
        let filtro = 'periodi'
        let periodoBody = {
            periodo: {
                id: this.state.editFormData['id']
            }
        }
        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.eliminaPeriodoScontistica,
            method: "DELETE",
            queryParams: "?anno=" + this.state.editFormData['anno'] + "&filtro=" + filtro,
            body: periodoBody
        }

        console.log("periodoBody", periodoBody)


        callLambda(paramsAxios, res => {
            console.log("[" + paramsAxios['endpoint'] + "]", res)
            if (res.data.esito === "OK") {

                console.log("res", res)
                let updatePeriodi = ''
                //this.setState({ events: res.data.risultato.periodi })
                if (res.data.risultato.length === 0) {
                    updatePeriodi = []

                } else {
                    updatePeriodi = res.data.risultato.periodi

                }

                let filtro = 'periodi,scontistica'
                let paramsAxios = {
                    endpoint: env().API_ENDPOINT_URL + apiConfig.getSconti,
                    method: "GET",
                    queryParams: "?anno=" + moment(this.state.editFormData['start']).year() + "&filtro=" + filtro
                }

                callLambda(paramsAxios, res => {
                    console.log("[" + paramsAxios['endpoint'] + "]", res)
                    if (res.data.esito === "OK") {

                        console.log("res", res)
                        let scontisticaCalendar = []
                        let scontisticaArray = []
                        let scontistica = []
                        let periodi = []
                        let nomePeriodo = ''
                        if (res.data.risultato.periodi !== undefined) {
                            periodi = res.data.risultato.periodi;

                        }
                        if (res.data.risultato.scontistica !== undefined) {
                            scontistica = res.data.risultato.scontistica;
                            scontisticaArray = Object.entries(scontistica)
                            for (let j = 0; j < periodi.length; j++) {
                                for (let i = 0; i < scontisticaArray.length; i++) {
                                    if (scontisticaArray[i][1] === periodi[j]['id']) {
                                        nomePeriodo = periodi[j]['title']
                                        scontisticaCalendar.push({
                                            id: "id-" + i,
                                            title: nomePeriodo,
                                            start: moment(scontisticaArray[i][0]).format("YYYY-MM-DD")
                                        })
                                    }
                                }

                            }
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Cancellato!',
                            text: 'Il periodo di scontistica è stato correttamente eliminato!',
                        })
                        this.props.forceUpdatePeriodi({ periodi: periodi, scontistica: scontisticaCalendar });
                    }

                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ops..',
                            text: res.data.descrizioneErrore
                        })
                    }
                });


            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ops..',
                    text: res.data.descrizioneErrore,
                })
            }
        });
    }

    handleEditFormSubmit = () => {

        console.log("editFormData", this.state.editFormData)


        let trovatoErrore = checkErrorePeriodoSconto(this.state.editFormData)

        if (trovatoErrore['valore'] === true) {
            this.notifyBottomLeft(trovatoErrore['message'])
        }
        //passati i controlli parte l'operazione di chiamata alla axios ed aggiorno il componente
        else {


            let offerte = []
            offerte.push({
                durata: 6,
                sconto: Number(this.state.editFormData['durata_4_6'])
            });
            offerte.push({
                durata: 7,
                sconto: Number(this.state.editFormData['durata_6_14'])
            });
            offerte.push({
                durata: 14,
                sconto: Number(this.state.editFormData['durata_14_28'])
            });
            offerte.push({
                durata: 28,
                sconto: Number(this.state.editFormData['durata_28'])
            });

            let periodoBody = {
                "periodo": {
                    id: this.state.editFormData['id'],
                    anno: this.state.editFormData['anno'],
                    title: this.state.editFormData['title'],
                    offerte: offerte
                }
            }

            let filtro = 'periodi'
            let paramsAxios = {
                endpoint: env().API_ENDPOINT_URL + apiConfig.inserisciPeriodoScontistica,
                method: "POST",
                queryParams: "?anno=" + moment(this.state.editFormData['start']).year() + "&filtro=" + filtro,
                body: periodoBody
            }

            console.log("periodoBody", periodoBody)


            callLambda(paramsAxios, res => {
                console.log("[" + paramsAxios['endpoint'] + "]", res)
                if (res.data.esito === "OK") {

                    console.log("res", res)
                    //this.setState({ events: res.data.risultato.periodi })
                    //this.props.forceUpdatePeriodi(res.data.risultato.periodi);
                    let filtro = 'periodi,scontistica'
                    let paramsAxios = {
                        endpoint: env().API_ENDPOINT_URL + apiConfig.getSconti,
                        method: "GET",
                        queryParams: "?anno=" + this.state.editFormData['anno'] + "&filtro=" + filtro,
                    }

                    callLambda(paramsAxios, res => {
                        console.log("[" + paramsAxios['endpoint'] + "]", res)
                        if (res.data.esito === "OK") {

                            console.log("res", res)
                            let scontisticaCalendar = []
                            let scontisticaArray = []
                            let scontistica = []
                            let periodi = []
                            let nomePeriodo = ''
                            if (res.data.risultato.periodi !== undefined) {
                                periodi = res.data.risultato.periodi;

                            }
                            if (res.data.risultato.scontistica !== undefined) {
                                scontistica = res.data.risultato.scontistica;
                                scontisticaArray = Object.entries(scontistica)
                                for (let j = 0; j < periodi.length; j++) {
                                    for (let i = 0; i < scontisticaArray.length; i++) {
                                        if (scontisticaArray[i][1] === periodi[j]['id']) {
                                            nomePeriodo = periodi[j]['title']
                                            scontisticaCalendar.push({
                                                id: "id-" + i,
                                                title: nomePeriodo,
                                                start: moment(scontisticaArray[i][0]).format("YYYY-MM-DD")
                                            })
                                        }
                                    }

                                }
                            }


                            this.props.forceUpdatePeriodi({ periodi: periodi, scontistica: scontisticaCalendar });


                            Swal.fire({
                                icon: 'success',
                                title: 'Inserito!',
                                text: 'Il periodo di scontistica è stato correttamente inserito!',
                            })
                        }

                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Ops..',
                                text: res.data.descrizioneErrore,
                            })
                        }
                    });


                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ops..',
                        text: res.data.descrizioneErrore,
                    })
                }
            });

        }
    }

    //update data function
    handleEditFormChange = (event) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...this.state.editFormData };
        newFormData[fieldName] = fieldValue;
        let listaFormsModal = this.state.listEventsForms

        for (let i = 0; i < listaFormsModal.length; i++) {
            if (listaFormsModal[i].nome === fieldName) {
                listaFormsModal[i].valore = fieldValue
            }
        }

        console.log("newFormData dopo tutti i settaggi", newFormData)

        this.setState({
            editFormData: newFormData,
            listEventsForms: listaFormsModal
        })
    };



    handleEditClick(newEvent) {
        console.log("**** ABULIUTATO EDIT MODAL *****")
        const formValues = newEvent
        console.log("formValues", formValues)

        let listaFormsModal = this.state.listEventsForms

        //questo for lo faccio per inizializzare i valori proveniente da newEvent!!
        for (let i = 0; i < listaFormsModal.length; i++) {
            for (var key in newEvent) {
                if (listaFormsModal[i].nome === key) {
                    if (listaFormsModal[i].nome === key && key === "start") {
                        for (let z = 0; z < listaFormsModal.length; z++) {
                            if (listaFormsModal[z].nome === "end") {
                                //questo if mi serve nel caso da mobile la data di end deve essere sempre successiva a quella di start
                                listaFormsModal[z].min = moment(newEvent['start']).add('1', 'days').format('YYYY-MM-DD')
                            }
                        }
                    }
                    listaFormsModal[i].valore = newEvent[key]
                }
            }
        }

        for (let i = 0; i < listaFormsModal.length; i++) {
            if (listaFormsModal[i]['nome'] === "durata_4_6") {
                listaFormsModal[i].valore = ''
            } else if (listaFormsModal[i]['nome'] === "durata_6_14") {
                listaFormsModal[i].valore = ''
            } else if (listaFormsModal[i]['nome'] === "durata_14_28") {
                listaFormsModal[i].valore = ''
            } else if (listaFormsModal[i]['nome'] === "durata_28") {
                listaFormsModal[i].valore = ''
            }
        }


        console.log("listaFormsModal", listaFormsModal)

        this.setState({
            editModal: false, editFormData: formValues, listEventsForms: listaFormsModal
        }, () => {
            this.setState({ editModal: true })
        })
    };

    handleEditModificaSubmit = () => {
        console.log("editFormData", this.state.editFormData)


        let trovatoErrore = checkErrorePeriodoSconto(this.state.editFormData)

        if (trovatoErrore['valore'] === true) {
            this.notifyBottomLeft(trovatoErrore['message'])
        }
        //passati i controlli parte l'operazione di chiamata alla axios ed aggiorno il componente
        else {

            let offerte = []
            offerte.push({
                durata: 6,
                sconto: this.state.editFormData['durata_4_6'] === undefined ?
                    Number(this.state.editFormData['offerte'][0]['sconto'])
                    : Number(this.state.editFormData['durata_4_6'])
            });
            offerte.push({
                durata: 7,
                sconto: this.state.editFormData['durata_6_14'] === undefined ?
                    Number(this.state.editFormData['offerte'][1]['sconto'])
                    : Number(this.state.editFormData['durata_6_14'])
            });
            offerte.push({
                durata: 14,
                sconto: this.state.editFormData['durata_14_28'] === undefined ?
                    Number(this.state.editFormData['offerte'][2]['sconto'])
                    : Number(this.state.editFormData['durata_14_28'])

            });
            offerte.push({
                durata: 28,
                sconto: this.state.editFormData['durata_28'] === undefined ?
                    Number(this.state.editFormData['offerte'][3]['sconto'])
                    : Number(this.state.editFormData['durata_28'])

            });

            let periodoBody = {
                "periodo": {
                    id: this.state.editFormData['id'],
                    anno: this.state.editFormData['anno'],
                    title: this.state.editFormData['title'],
                    offerte: offerte
                }
            }

            let filtro = 'periodi'
            let paramsAxios = {
                endpoint: env().API_ENDPOINT_URL + apiConfig.modificaPeriodoScontistica,
                method: "PUT",
                queryParams: "?anno=" + moment(this.state.editFormData['start']).year() + "&filtro=" + filtro,
                body: periodoBody
            }

            console.log("periodoBody", periodoBody)


            callLambda(paramsAxios, res => {
                console.log("[" + paramsAxios['endpoint'] + "]", res)
                if (res.data.esito === "OK") {

                    console.log("res", res)
                    //this.setState({ events: res.data.risultato.periodi })
                    //this.props.forceUpdatePeriodi(res.data.risultato.periodi);
                    let filtro = 'periodi,scontistica'
                    let paramsAxios = {
                        endpoint: env().API_ENDPOINT_URL + apiConfig.getSconti,
                        method: "GET",
                        queryParams: "?anno=" + this.state.editFormData['anno'] + "&filtro=" + filtro,
                    }

                    callLambda(paramsAxios, res => {
                        console.log("[" + paramsAxios['endpoint'] + "]", res)
                        if (res.data.esito === "OK") {

                            console.log("res", res)
                            let scontisticaCalendar = []
                            let scontisticaArray = []
                            let scontistica = []
                            let periodi = []
                            let nomePeriodo = ''
                            if (res.data.risultato.periodi !== undefined) {
                                periodi = res.data.risultato.periodi;

                            }
                            if (res.data.risultato.scontistica !== undefined) {
                                scontistica = res.data.risultato.scontistica;
                                scontisticaArray = Object.entries(scontistica)
                                for (let j = 0; j < periodi.length; j++) {
                                    for (let i = 0; i < scontisticaArray.length; i++) {
                                        if (scontisticaArray[i][1] === periodi[j]['id']) {
                                            nomePeriodo = periodi[j]['title']
                                            scontisticaCalendar.push({
                                                id: "id-" + i,

                                                title: nomePeriodo,
                                                start: moment(scontisticaArray[i][0]).format("YYYY-MM-DD")
                                            })
                                        }
                                    }

                                }
                            }


                            this.props.forceUpdatePeriodi({ periodi: periodi, scontistica: scontisticaCalendar });
                            Swal.fire({
                                icon: 'success',
                                title: 'Modificato!',
                                text: 'Il periodo di scontistica è stato correttamente modificato!',
                            })
                        }

                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Ops..',
                                text: res.data.descrizioneErrore,
                            })
                        }
                    });


                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ops..',
                        text: res.data.descrizioneErrore,
                    })

                }
            });
        }
    }

    annullaButton = () => {
        this.setState({
            modificaEliminaButton: false
        });
    }


    notifyBottomLeft = (message) => {
        toast.error("❌ " + message + "!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    };

    render() {
        const { editModal } = this.state;

        return (
            <Col lg={3}>
                <Card>
                    <div className="card-header border-0 pb-0">
                        <h4 className="text-black fs-20 mb-0">Periodo sconti</h4>
                        <div className="basic-dropdown">
                            {/*
                            <Dropdown>
                                <Dropdown.Toggle variant="primary">
                                    Anno
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">2023</Dropdown.Item>
                                    <Dropdown.Item href="#">2024</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        */
                            }
                        </div>

                    </div>

                    <Card.Body>

                        <div id="external-events">
                            <Button
                                style={{ width: "100%", marginBottom: "20px" }}
                                className="me-2"
                                variant="primary btn-rounded btn-sm"
                                onClick={this.onNewPeriodo}
                            >
                                <span className="btn-icon-start text-primary ">
                                    <i className="fa fa-plus color-primary" />
                                </span>
                                Inserisci un nuovo periodo
                            </Button>
                            {this.state.events.length === 0 ?

                                <div> Non è presente nessun periodo di scontistica</div>
                                :
                                this.state.events.map((event) => (
                                    <div
                                        className="fc-event mt-0 ms-0 mb-2 btn btn-block btn-primary"
                                        title={event.title}
                                        data={event.id}
                                        key={event.id}
                                        onClick={() => this.onClickPeriodo(event)}
                                    >
                                        {event.title}
                                    </div>
                                ))}
                        </div>
                        {editModal &&
                            <ModalPeriodoSconti
                                setEditFormDataModale={this.handleEditFormChange}
                                setConformModale={this.handleEditFormSubmit}
                                setModificaModale={this.handleEditModificaSubmit}
                                setEliminaModale={this.handleEditFormDelete}
                                listEventsForms={this.state.listEventsForms}
                                editModal={editModal}
                                onChange={this.annullaButton}
                                isClicked={this.state.modificaEliminaButton}
                            />
                        }
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover={false}
                        />

                    </Card.Body>
                </Card>

            </Col>



        );
    }
}

export default PeriodoSconti;
