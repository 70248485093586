import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	Dropdown,
	DropdownButton,
	ButtonGroup,
	SplitButton,
	ProgressBar
} from "react-bootstrap";
import Loader from '../../../../../components/Loader';
import env from '../../../../../../envConfig';
import { callLambda } from '../../../../../../API/api';
import moment from 'moment';
import apiConfig from '../../../../../../API/apiConfig';
import { dateFromTimestampToNormalFormat } from '../../../../Prenotazioni/utils/utils';
import filtraPrenotazioniByStato from './filtraggio-tabella/filterData';
export const VisualizzaPrenotazioniTable = (props) => {

	useEffect(() => {


		let listaFinale = props.listaFinalePrezziSconti

		listaFinale.map((x) => {

			if (x['prezzo'] === undefined) {
				x['prezzo'] = "Non inserito"
			} else {
				x['prezzo'] = "€" + x['prezzo']

			}

			if (x['sconto'] === undefined) {
				x['sconto'] = "Non inserito"
			} else {
				x['sconto'] = x['sconto']+ "%"

			}
			setMOCK_DATA(listaFinale)
		});


	}, []);

	const [MOCK_DATA, setMOCK_DATA] = useState([]);
	const [orderFilterAppartamento, setOrderFilterAppartamento] = useState(true);
	const [orderFilterStato, setOrderFilterStato] = useState(true);
	const [refresh, setRefresh] = useState(true);
	const columns = useMemo(() => COLUMNS, [])
	const data = useMemo(() => MOCK_DATA)
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0, hiddenColumns: ['statoFiltro', 'start', 'end'] }
	}, useFilters, useGlobalFilter, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance

	const orderPrenotazione = (value) => {

		let entries = MOCK_DATA
		console.log("entries", entries)
		let prenotazioniOrdinate = []
		if (value === 'DataCrescente') {
			// ordina l'array in base alle date in ordine decrescente
			entries.sort(function (a, b) {
				return a['start'] - b['start'];
			});
			console.log("entries dopo ordinamento", entries)
			entries = filtraPrenotazioniByStato(entries)

		} else if (value === 'Appartamento') {
			if (orderFilterAppartamento) {
				entries.sort(function (a, b) {
					return a['appartamento'].localeCompare(b['appartamento']);
				});
			} else {
				entries.sort(function (a, b) {
					return b['appartamento'].localeCompare(a['appartamento']);
				});
			}
			console.log("entries dopo ordinamento", entries)
			entries = filtraPrenotazioniByStato(entries)
			setOrderFilterAppartamento(!orderFilterAppartamento)
		} else if (value === 'Stato') {
			if (orderFilterStato) {
				entries.sort(function (a, b) {
					return a['statoFiltro'].localeCompare(b['statoFiltro']);
				});
			} else {
				entries.sort(function (a, b) {
					return b['statoFiltro'].localeCompare(a['statoFiltro']);
				});
			}
			console.log("entries dopo ordinamento", entries)
			setOrderFilterStato(!orderFilterStato)
		}
		for (let i = 0; i < entries.length; i++) {
			prenotazioniOrdinate.push(entries[i])
		}

		setMOCK_DATA(prenotazioniOrdinate)

	}

	const { globalFilter, pageIndex } = state


	return (

		<div className="table-responsive">
			<table {...getTableProps()} className="table dataTable display">
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>
									{column.render('Header')}
									{
										//column.canFilter ? column.render('Filter') : null
									}

								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} className="" >

					{page.map((row, index) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			<div className="d-flex justify-content-between">
				<span>
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{''}
				</span>
				<span className="table-index">
					Go to page : {' '}
					<input type="number"
						className="ml-2"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(pageNumber)
						}}
					/>
				</span>
			</div>
			<div className="text-center mb-3">
				<div className="filter-pagination  mt-3">
					<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

					<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
						Precedente
					</button>
					<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
						Successivo
					</button>
					<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
				</div>
			</div>
		</div>
	)

}
export default VisualizzaPrenotazioniTable;