import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { Component } from "react";
import swal from "sweetalert";
import config from '../../../../../config';
import env from '../../../../../envConfig'
import apiConfig from '../../../../../API/apiConfig'

import {
    callLambda,
    callLambdaS3
} from "../../../../../API/api"

export default class InsertFoto extends Component {
    state = {
        fileList: [],
        uploading: false
    };

    componentDidMount() {

    }


    handleUpload = async () => {
        const { fileList } = this.state;

        //console.log("fileList", fileList)


        let paramsPath = []

        for (let i = 0; i < fileList.length; i++) {
            paramsPath.push({
                "folder": "home/banner/",
                "name": fileList[i].name
            })
        }

        let paramsAxios = {
            endpoint: env().API_ENDPOINT_URL + apiConfig.getS3URL,
            method: "POST",
            promiseAll: true,
            vettorePromiseAll: paramsPath
        }

        //getS3URL(paramsPath, s3URLList => {


        callLambda(paramsAxios, res => {
            //console.log(res)
            let s3URLList = []
            res.map((elem) => s3URLList.push(elem.data.risultato))
            //console.log(s3URLList)
            this.setState({
                uploading: true,
            });

            let paramsAxios = {
                endpoint: s3URLList,
                method: "PUT",
                promiseAll: true,
                vettorePromiseAll: fileList
            }

            //carico su S3 i nuovi file
            callLambdaS3(paramsAxios, res => {
                //console.log("res", res)
                let returnArrayLinkFoto = []
                let linkFoto = ""
                let file = ""
                for (let i = 0; i < res.length; i++) {
                    file = fileList[i] //questo è sicuro perché ci saranno N risposte per N file provati ad uploadare
                    linkFoto = 'https://' + env().bucketName + '.s3.' + env().aws_project_region + '.amazonaws.com/' +
                    env().dirFotoBanner + env().separatoreFoto + file.name;
                    //console.log("linkFoto", linkFoto)
                    if (res[i].status === 200) {
                        //per ogni risposta 200 ricevuta, costruisco il vettore delle foto da ritornare


                        //console.log("caricato!")
                        returnArrayLinkFoto.push(linkFoto)

                    }
                }

                //ritorno il vettore del link delle foto
                if (returnArrayLinkFoto.length === fileList.length) {
                    //tutto ok fratm
                    this.props.setPhoto(returnArrayLinkFoto)
                } else {
                    //gestire qui con un error
                }

                this.setState({
                    uploading: false,
                });
            })
        });
    };

    render() {
        const { uploading, fileList } = this.state;
        const props = {
            onRemove: file => {
                //console.log("sono in onRemove")

                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    //console.log("newFileList", newFileList)
                    newFileList.splice(index, 1);
                    //console.log("newFileList dopo split", newFileList)

                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                //console.log("file", file)
                if (file.type.includes('image')) {
                    let name = file.name
                    let trovato = false;
                    for (let fileN of fileList) {
                        if (fileN.name === name) {
                            trovato = true
                        }
                    }
                    if (trovato) {
                        swal("Attenzione!", "Hai inserito un file con un nome già presente. Modifica il nome del file!", "error")
                    } else {
                        this.setState(state => ({
                            fileList: [...state.fileList, file],
                        }));
                        return false;
                    }
                } else {
                    swal("Attenzione!", "Hai inserito un file non valido!", "error")
                }


            },
            fileList,
        };

        return (
            <div>
                <Upload {...props}>
                    <Button>
                        <UploadOutlined /> Aggiungi una nuova foto
                    </Button>
                </Upload>
                <Button
                    type="primary"
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{ marginTop: 16 }}
                >
                    {uploading ? 'Caricamento in corso' : 'Carica foto'}
                </Button>
            </div>

        );
    }
}