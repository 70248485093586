import { isPropsEqual } from "@fullcalendar/react";
import React from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';







const StepOne = (props) => {


   const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      props.setInputs(values => ({ ...values, [name]: value }))

   }
   const handleClick = () => {
      props.setChecked(!(props.checked))
   }
 
   return (
      <section>
         <div className="row">
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Nome Appartamento</label>
                  <span className="text-danger">*</span>

                  <input
                     type="text"
                     name="Nome"
                     className="form-control"
                     placeholder="Inserisci nome appartamento"
                     required
                     value={props.inputs.Nome}
                     onChange={handleChange}
                  />
                  <b>
                     {props.errors.Nome && <div className="error">{props.errors.Nome}</div>}
                  </b>
               </div>
            </div>

            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label for="MaxPeople">Numero massimo di persone</label>
                  <span className="text-danger">*</span>
                  <select
                     className="form-control"
                     id="sel1"
                     onChange={handleChange}
                     name="MaxPeople"
                     placeholder="Seleziona il numero di persone"
                     required={true}
                     defaultValue={props.inputs.MaxPeople}
                  >
                     {props.errors.MaxPeople && console.log("errormax", props.errors.MaxPeople)}
                     {props.errors.MaxPeople && <div className="errorMaax">{props.errors.MaxPeople}</div>}
                     <option disabled selected value>Seleziona il numero massimo di persone</option>

                     <option>1</option>
                     <option>2</option>
                     <option>3</option>
                     <option>4</option>
                     <option>5</option>
                     <option>6</option>
                     <option>7</option>
                     <option>8</option>
                     <option>9</option>
                     <option>10</option>
                     <option>11</option>
                     <option>12</option>
                  </select>

               </div>
            </div>


            <div className="col-lg-12 mb-3">
               <div className="form-group mb-3">
                  <label className="text-label">Descrizione </label>
                  <input
                     type="text"
                     name="Description"
                     className="form-control"
                     onChange={handleChange}
                     value={props.inputs.Description}
                  />
               </div>
            </div>

            <div className="col-lg-12 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Regole </label>
                  <input
                     type="text"
                     name="Rules"
                     className="form-control"
                     placeholder="Inserisci le regole per quest'appartamento"
                     value={props.inputs.Rules}
                     onChange={handleChange}

                  />
               </div>
            </div>
         </div>
      </section>
   );
};

export default StepOne;
